import React from 'react'
import styles from './styles.module.scss'

const Settings = () => {
  return (
    <svg
      className={styles.svg}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='16' cy='16' r='2' stroke='#95999E' strokeWidth='1.5' />
      <path
        d='M9.88314 10.1807C9.065 11.0858 8.47916 12.1676 8.14841 13.3325L9.43083 14.1064C10.7343 14.893 10.7159 16.7896 9.39744 17.5508L8.2416 18.2181C8.42836 18.8011 8.68033 19.3745 9 19.9282C9.31966 20.4819 9.69026 20.9868 10.1017 21.44L11.2576 20.7727C12.5761 20.0114 14.2277 20.9438 14.2572 22.4659L14.2863 23.9636C15.4604 24.2596 16.6902 24.2931 17.8831 24.0371L17.8586 22.7741C17.8281 21.2001 19.5438 20.2095 20.8917 21.0229L21.9733 21.6757C22.7914 20.7706 23.3772 19.6888 23.708 18.5239L22.4255 17.75C21.1221 16.9633 21.1405 15.0668 22.4589 14.3056L23.6148 13.6382C23.428 13.0553 23.1761 12.4819 22.8564 11.9282C22.5367 11.3745 22.1661 10.8696 21.7546 10.4164L20.5988 11.0837C19.2803 11.8449 17.6287 10.9125 17.5992 9.39042L17.5701 7.89284C16.396 7.59684 15.1662 7.56332 13.9733 7.81929L13.9977 9.08235C14.0283 10.6563 12.3126 11.6469 10.9647 10.8335L9.88314 10.1807Z'
        stroke='#95999E'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Settings
