import { sample } from 'effector'
import {
  $findedCommands,
  $page,
  $search,
  $searchingCommands,
  performSearch,
  searchChanged,
  SearchCommandGate,
  searchFx,
  switchFavorite,
  switchFavoriteFx,
  tryLoadNextPage,
  updatePageNumber,
} from '.'
import { SearchCommandsProps } from './model'

sample({
  clock: searchChanged,
  target: $search,
})

sample({
  clock: performSearch,
  //   source: $page,
  fn: (search): SearchCommandsProps => ({
    search,
    page: 1,
    pageSize: 5,
  }),
  target: searchFx,
})

sample({
  clock: performSearch,
  fn: () => 1,
  target: $page,
})

sample({
  clock: searchFx.doneData,
  source: { prevFinded: $findedCommands },
  fn: ({ prevFinded }, data) => [...prevFinded, ...data],
  target: $findedCommands,
})

sample({
  clock: performSearch,
  fn: () => [],
  target: $findedCommands,
})

sample({
  clock: switchFavorite,
  target: switchFavoriteFx,
})

sample({
  clock: SearchCommandGate.open,
  fn: () => '',
  target: $search,
})

sample({
  clock: SearchCommandGate.open,
  fn: () => 0,
  target: $page,
})

sample({
  clock: SearchCommandGate.open,
  fn: () => [],
  target: $findedCommands,
})

sample({
  clock: tryLoadNextPage,
  source: { search: $search, page: $page },
  fn: ({ page, search }): SearchCommandsProps => ({
    search,
    page: page + 1,
    pageSize: 5,
  }),
  target: searchFx,
})

sample({
  clock: searchFx.finally,
  filter: ({ status }) => status === 'done',
  target: updatePageNumber,
})

sample({
  clock: updatePageNumber,
  source: $page,
  fn: (page) => page + 1,
  target: $page,
})

// sample({
//   clock: SearchCommandGate.open,
//   fn: (): SearchCommandsProps => ({
//     search: '',
//     page: 1,
//     pageSize: 5,
//   }),
//   target: searchFx,
// })
