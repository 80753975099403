
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "SurveyPassingType",
      "UserForDocumentType"
    ],
    "QType": [
      "BaseTFQuestionType",
      "FinalTFQuestionType"
    ],
    "QuestionType": [
      "CheckQuestionType",
      "DateQuestionType",
      "ManyFromListQuestionType",
      "NumberQuestionType",
      "TextQuestionType",
      "YesNoQuestionType"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "SurveyPassingType",
      "UserForDocumentType"
    ],
    "QType": [
      "BaseTFQuestionType",
      "FinalTFQuestionType"
    ],
    "QuestionType": [
      "CheckQuestionType",
      "DateQuestionType",
      "ManyFromListQuestionType",
      "NumberQuestionType",
      "TextQuestionType",
      "YesNoQuestionType"
    ]
  }
};
      export default result;
    