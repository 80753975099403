import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'InputEvent',

  initialState: {
    inputBtn: false,
    inputBtn2: false,
  },
  reducers: {
    InputBtn: (state, { payload }) => {
      state.inputBtn = !payload
    },
    InputBtn2: (state, { payload }) => {
      state.inputBtn2 = !payload
    },
  },
})
export const { InputBtn, InputBtn2 } = slice.actions

export default slice.reducer
