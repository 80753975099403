import React from 'react'

export const PlusIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
      <path
        d='M5.40608 6.09311L5.59311 6.09311L5.59313 9.40645C5.59313 9.40646 5.59313 9.40646 5.59313 9.40647C5.59311 9.45809 5.55126 9.49996 5.49964 9.5L5.40608 6.09311ZM5.40608 6.09311L5.40607 9.40646V9.4065C5.40607 9.45805 5.44786 9.49996 5.49957 9.5L5.40608 6.09311ZM9.90647 4.90606H9.90653C9.9581 4.90606 10 4.94786 10 4.9996C10 5.05115 9.95818 5.09308 9.90647 5.09313C9.90645 5.09313 9.90643 5.09313 9.9064 5.09313L6.59311 5.09312L6.59311 4.90606L9.90647 4.90606ZM5.59311 4.90605L5.59311 5.09311L5.40608 5.09311V4.90605L5.59311 4.90605ZM4.40608 4.90605V5.09311L1.09353 5.0931C1.04187 5.0931 1 5.05122 1 4.99957C1 4.94791 1.04188 4.90604 1.09352 4.90604L4.40608 4.90605ZM5.40608 3.90605L5.40608 0.593489V0.593459C5.40608 0.541847 5.44793 0.499997 5.49954 0.5H5.49961C5.55124 0.499996 5.5931 0.541837 5.59309 0.593495V0.593529L5.59311 3.90605L5.40608 3.90605Z'
        fill='#2F3136'
        stroke='#2F3136'
      />
    </svg>
  )
}
