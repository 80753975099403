import React from 'react'
import { useAppDispatch } from 'app/redux/hooks'
import { setIsShowMobileToolbar, setIsHeaderEditMode } from '../../../../pages/document/redux/documentSlice'
import checkBlueIcon from '../../../../assets/icons/check-blue.svg'
import styles from './style.module.scss'

export const CheckBlueBtn = () => {
  const dispatch = useAppDispatch()

  const handleConfirm = () => {
    dispatch(setIsHeaderEditMode(false))
    dispatch(setIsShowMobileToolbar(false))
  }

  return (
    <button onClick={handleConfirm}>
      <img className={styles.imageIcon} src={checkBlueIcon} alt='checkBlue-icon' />
    </button>
  )
}
