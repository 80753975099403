import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { translationMergeEN, translationMergeRU } from 'lib/locales'

i18n.use(LanguageDetector).init({
  // \u000A - перенос строки
  resources: {
    en: {
      translations: translationMergeEN,
    },
    ru: {
      translations: translationMergeRU,
    },
  },
  lng: 'ru',
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  // react: {
  //   wait: true,
  // },
})

export default i18n
