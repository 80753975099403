import { Modal } from '@material-ui/core'
import { Button } from 'components/Button'
import { BlankScreen } from 'components/UI/BlankScreen/BlankScreen'
import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import styles from './style.module.scss'

interface IErrorModalProps {
  isOpened: boolean
  title: string
  text: string
}

export const ErrorModal = ({ isOpened, title, text }: IErrorModalProps) => {
  const [isOpen, setIsOpen] = useState(isOpened)

  const onClickHandler = (): void => {
    document.location.reload()
  }

  const onCloseHandler = () => {
    setIsOpen((prev) => !prev)
  }

  const onClickSupportHandler = (): void => {
    window.open('https://alf.bitrix24site.ru/lichnayavizitka/')
  }

  return (
    <Modal open={isOpen} onClose={onCloseHandler}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {title} <CloseIcon onClick={onCloseHandler} className={styles.header__icon} />
        </div>
        <BlankScreen imageConnectionLost title={title} text={text}>
          <div className={styles.button_wrapper}>
            <Button onClick={onClickHandler} fullWidth={false} buttonType='button' type='yellow'>
              Перезагрузить страницу
            </Button>
            <Button onClick={onClickSupportHandler} fullWidth={false} buttonType='button' type='yellow'>
              Написать в поддержку
            </Button>
          </div>
        </BlankScreen>
      </div>
    </Modal>
  )
}
