import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import { AlfAssistantFooterVoiceIcon } from '../../../AlfAssistantFooterVoiceIcon/AlfAssistantFooterVoiceIcon'
import styles from './styles.module.scss'
import { TInputVoice } from '../AlfAssistantFooterInputVoice'
import Pencil from './icons/Pencil'
import Close from './icons/Close'

const AlfAssistantFooterInputVoiceInput: FC<TInputVoice> = ({
  handleInputChange,
  handleInputEvent,
  setInputValue,
  setVoiceActive,
  handleSendMessage,
}) => {
  const [activeVoice, setActiveVoice] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { transcript, resetTranscript, isMicrophoneAvailable, browserSupportsSpeechRecognition } =
    useSpeechRecognition()
  const [error, setError] = useState('')

  const startListening = () => {
    if (!browserSupportsSpeechRecognition) {
      return setError('Браузер не поддерживает распознавание речи')
    }
    // запрос на разрешение микрофона и обработка ошибки если доступа нет
    getMedia()
    if (!isMicrophoneAvailable) {
      return setError('Ошибка доступа к микрофону')
    }
    SpeechRecognition.startListening({ continuous: true, language: 'ru-EN' })
  }
  const stopListening = () => SpeechRecognition.stopListening()

  useEffect(() => {
    const preProcessInputEvent = () => {
      const textArea = textAreaRef.current
      if (textArea) {
        const cursorPosition = textArea.selectionStart
        handleInputEvent(cursorPosition)
      }
    }
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
      preProcessInputEvent()
    }
  }, [transcript, textAreaRef])

  async function getMedia() {
    const constraints = { audio: true }
    navigator.mediaDevices
      .getUserMedia(constraints)
      // если возникла ошибка, значит, либо пользователь отказал в доступе,
      // либо запрашиваемое медиа-устройство не обнаружено
      .catch(() => {
        return setError('Ошибка доступа к микрофону')
      })
  }
  useEffect(() => {
    const activateVoice = async (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'Enter') {
        handleSendMessage(transcript)
      }
      if (e.code === 'Space') {
        await startListening()
        setActiveVoice(true)
      }
      if (e.key === 'e' && transcript) {
        editMessage()
      }
    }

    const deactivateVoice = async (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        transcript && handleSendMessage(transcript)
        setActiveVoice(false)
        await stopListening()
        resetTranscript()
      }
      if (e.code === 'Escape') {
        setActiveVoice(false)
        await stopListening()
        resetTranscript()
      }
    }

    window.addEventListener('keydown', activateVoice)
    window.addEventListener('keyup', deactivateVoice)

    return () => {
      window.removeEventListener('keydown', activateVoice)
      window.removeEventListener('keyup', deactivateVoice)
    }
  }, [window, transcript, setActiveVoice])

  const editMessage = async () => {
    await stopListening()
    setVoiceActive(false)
    setInputValue(transcript)
  }

  const toggleListening = useCallback(async () => {
    if (activeVoice) {
      await stopListening()
    } else {
      await startListening()
    }
    setActiveVoice(!activeVoice)
  }, [activeVoice])
  console.log(activeVoice)
  return (
    <div
      className={`${styles.container} ${activeVoice ? styles.containerActive : ''}`}
      onClick={() => {
        toggleListening()
        transcript && handleSendMessage(transcript)
        resetTranscript()
      }}
    >
      <button className={styles.voiceIcon}>
        <AlfAssistantFooterVoiceIcon />
      </button>
      {transcript ? (
        <>
          <textarea
            ref={textAreaRef}
            className={styles.textarea}
            value={transcript}
            rows={1}
            onChange={handleInputChange}
            onKeyUp={() => handleInputEvent(textAreaRef.current?.selectionStart)}
            placeholder=''
          />
          <ul className={styles.iconsList}>
            <li
              className={styles.iconsItem}
              onClick={(e) => {
                e.stopPropagation()
                editMessage()
              }}
            >
              <Pencil />
              <span className={styles.textTooltip}>Редактировать</span>
            </li>
            <li
              className={styles.iconsItem}
              onClick={async (e) => {
                e.stopPropagation()
                await stopListening()
                resetTranscript()
                setActiveVoice(false)
              }}
            >
              <Close />
              <span className={styles.textTooltip}>Закрыть</span>
            </li>
          </ul>
        </>
      ) : (
        <>
          <p className={styles.text}>
            {!activeVoice
              ? 'Слушаем. Нажмите еще раз, чтобы отправить, Esc чтобы отменить'
              : !error
              ? 'Удерживайте пробел или нажмите кнопку, чтобы говорить'
              : error}
          </p>
        </>
      )}
    </div>
  )
}

export default AlfAssistantFooterInputVoiceInput
