import React, { ChangeEvent, KeyboardEventHandler, useEffect, useRef } from 'react'
import AlfAssistantFooterVoiceInputIcon from '../AlfAssistantFooterVoiceInputIcon/AlfAssistantFooterVoiceInputIcon'
import styles from './styles.module.scss'

interface AlfAssistantFooterInputWriteProps {
  handleInputChange: (event: ChangeEvent) => void
  inputValue: string
  onKeyDown?: (event: KeyboardEventHandler) => void
  handleInputEvent: (cursorPosition: number) => void
  setVoiceActive: (e: boolean) => void
  height?: number
  handleSendMessage: (value: string) => void
}

const AlfAssistantFooterInputWrite = ({
  setVoiceActive,
  handleInputChange,
  inputValue,
  handleInputEvent,
  handleSendMessage,
}: AlfAssistantFooterInputWriteProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const focusOnTextArea = () => {
    textAreaRef.current.focus()
  }
  const preProcessInputEvent = () => {
    const textArea = textAreaRef.current
    if (textArea) {
      const cursorPosition = textArea.selectionStart
      handleInputEvent(cursorPosition)
    }
  }
  useEffect(() => {
    const activateVoice = (e: KeyboardEvent) => {
      if (!e.shiftKey && !e.ctrlKey && e.key === 'Enter') {
        handleSendMessage(inputValue)
      }
      if (e.metaKey && e.key === 'Enter') {
        handleSendMessage(inputValue)
      }
    }
    window.addEventListener('keydown', activateVoice)
    return () => {
      window.removeEventListener('keydown', activateVoice)
    }
  }, [window, setVoiceActive, inputValue])

  const handleVoiceInputClick = () => {
    setVoiceActive(true)
  }
  useEffect(() => {
    focusOnTextArea()
  }, [])

  useEffect(() => {
    textAreaRef.current.style.height = 'auto'
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    preProcessInputEvent()
  }, [inputValue])

  return (
    <>
      <div className={styles.voiceInput} onClick={handleVoiceInputClick}>
        <AlfAssistantFooterVoiceInputIcon />
      </div>
      <textarea
        ref={textAreaRef}
        className={styles.textarea}
        value={inputValue}
        rows={1}
        onChange={(e) => handleInputChange(e)}
        onKeyUp={preProcessInputEvent}
        onClick={preProcessInputEvent}
        placeholder='Нажмите / для команд, @ для переменных или введите текст ...'
      />
    </>
  )
}

export default AlfAssistantFooterInputWrite
