import React from 'react'

export const RenameChatHistoryIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.7586 7.73165L16.268 4.24024C16.1519 4.12414 16.0141 4.03204 15.8624 3.9692C15.7107 3.90637 15.5482 3.87402 15.384 3.87402C15.2198 3.87402 15.0572 3.90637 14.9056 3.9692C14.7539 4.03204 14.6161 4.12414 14.5 4.24024L4.86641 13.8746C4.74983 13.9903 4.65741 14.1279 4.59451 14.2796C4.5316 14.4313 4.49948 14.594 4.50001 14.7582V18.2496C4.50001 18.5811 4.6317 18.8991 4.86612 19.1335C5.10054 19.3679 5.41849 19.4996 5.75001 19.4996H9.24141C9.40563 19.5001 9.5683 19.468 9.71999 19.4051C9.87168 19.3422 10.0094 19.2498 10.125 19.1332L19.7586 9.49962C19.8747 9.38354 19.9668 9.24573 20.0296 9.09405C20.0925 8.94238 20.1248 8.77981 20.1248 8.61563C20.1248 8.45146 20.0925 8.28889 20.0296 8.13721C19.9668 7.98554 19.8747 7.84772 19.7586 7.73165ZM9.24141 18.2496H5.75001V14.7582L12.625 7.88321L16.1164 11.3746L9.24141 18.2496ZM17 10.4902L13.5086 6.99962L15.3836 5.12462L18.875 8.61524L17 10.4902Z'
        fill='#95999E'
      />
    </svg>
  )
}
