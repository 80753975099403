import React, { useState } from 'react'
import styles from './styles.module.scss'
import { AlfAssistantInputSection } from '../AlfAssistantCreateAssistant/components/AlfAssistantInputSection/AlfAssistantInputSection'
import { AlfAssistantAddButton } from '../AlfAssistantCreateAssistant/components/AlfAssistantAddButton/AlfAssistantAddButton'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  setIsOpenSourceModal,
  setIsOpenSourcesTable,
  setTextSourceChecked,
  setLinkSourceChecked,
} from 'app/redux/alfAssistantSlice'

export const AlfAssistantAddSourceModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const [isAssistantTitle, setAssistantTitle] = useState('')
  const [isAssistantText, setAssistantText] = useState('')
  const [isAssistantLink, setAssistantLink] = useState('')
  const [isAssistantInputFilled, setIsAssistantInputFilled] = useState(false)
  const { isTextSourceChecked, isLinkSourceChecked } = useAppSelector((state) => state.alfAssistant)

  const areInputsFilled =
    (isAssistantTitle.trim() !== '' && isAssistantText.trim() !== '') ||
    (isAssistantTitle.trim() !== '' && isAssistantLink.trim() !== '')

  const handleSubmit = () => {
    if (!isAssistantInputFilled) {
      console.log('Заполните все поля')
      return
    }
    setIsAssistantInputFilled(true)
    dispatch(setIsOpenSourcesTable(true))
  }

  const handleCloseAddSource = () => {
    dispatch(setIsOpenSourceModal(false))
    dispatch(setTextSourceChecked(false))
    dispatch(setLinkSourceChecked(false))
  }

  return (
    <section className={styles.section}>
      <AlfAssistantInputSection
        title='Имя источника'
        placeholder='Название'
        rows={1}
        onChange={(e) => {
          const inputValue = e.target.value
          setAssistantTitle(inputValue)
          setIsAssistantInputFilled(inputValue.trim() !== '')
        }}
        value={isAssistantTitle}
      />
      {isTextSourceChecked ? (
        <AlfAssistantInputSection
          title='Текст'
          placeholder='Введите текст'
          rows={4}
          onChange={(e) => {
            const inputValue = e.target.value
            setAssistantText(inputValue)
            setIsAssistantInputFilled(inputValue.trim() !== '')
          }}
          value={isAssistantText}
        />
      ) : null}
      {isLinkSourceChecked ? (
        <AlfAssistantInputSection
          title='Ссылка'
          placeholder='Скопируйте и вставьте ссылку'
          rows={1}
          onChange={(e) => {
            const inputValue = e.target.value
            setAssistantLink(inputValue)
            setIsAssistantInputFilled(inputValue.trim() !== '')
          }}
          value={isAssistantLink}
        />
      ) : null}
      <div className={styles.buttons}>
        <AlfAssistantAddButton title='Добавить источник' isButtonValid={areInputsFilled} onClick={handleSubmit} />
        <button className={styles.cancelButton} onClick={handleCloseAddSource}>
          Назад
        </button>
      </div>
    </section>
  )
}
