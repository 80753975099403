import React from 'react'
import styles from './styles.module.scss'

interface IAlfAssistantSettingsTab {
  path: string
  text: string
  activeTab: string
  setActiveTab: (path: string) => void
}

export function AlfAssistantSettingsTab({ path, text, activeTab, setActiveTab }: IAlfAssistantSettingsTab) {
  const [pathValue, setPathValue] = React.useState(null)

  React.useEffect(() => {
    setPathValue(path)
  }, [path])

  return (
    <>
      <button
        className={activeTab === pathValue ? [styles.tab, styles.tab_is_active].join(' ') : styles.tab}
        onClick={() => setActiveTab(path)}
      >
        {text}
      </button>
    </>
  )
}
