import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = { gap: 1, nodePosition: { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0 } }
export type nodePosition = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}
export const modalPositionSlice = createSlice({
  name: 'modalPositionSlice',
  initialState,
  reducers: {
    changeGap(state, { payload }: PayloadAction<number>) {
      state.gap = payload
    },
    changeNodePosition(state, { payload }: PayloadAction<nodePosition>) {
      state.nodePosition = payload
    },
  },
})
export const { changeGap, changeNodePosition } = modalPositionSlice.actions

export default modalPositionSlice.reducer
