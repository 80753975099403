import React, { FC, memo } from 'react'
import styles from './styles.module.scss'
import Record from './Icon/Record'
import Pencil from './Icon/Pencil'
import Cancel from './Icon/Cancel'
import Stop from './Icon/Stop'
import ModalInfoHelpItem from './components/ModalInfoHelpItem/ModalInfoHelpItem'

export const helpInfo = {
  record: {
    title: 'Начать запись:',
    text1: 'Нажмите кнопку или удерживайте ',
    text2: ' , чтобы начать запись.',
    keysItem: 'Пробел',
    textInfo: '* в реальном времени будет происходить разговорная транскрибация.',
    icon: <Record />,
  },
  stop: {
    title: 'Остановить запись:',
    text1: 'Нажмите кнопку снова или отпустите ',
    text2: ' , чтобы остановить запись.',
    keysItem: 'Пробел',
    textInfo: '* сообщение будет отправлено автоматически после остановки.',
    icon: <Stop />,
  },
  cancel: {
    title: 'Отменить запись:',
    text1: 'Нажмите значок отмены или нажмите ',
    text2: ' , чтобы остановить запись.',
    keysItem: 'Esc',
    textInfo: '',
    icon: <Cancel />,
  },
  pencil: {
    title: 'Редактировать транскрипцию:',
    text1: 'Нажмите значок Редактировать или клавишу ',
    text2: ' , чтобы поместить текущий транскрипт в поле ввода сообщения для редактирования.',
    keysItem: 'E',
    textInfo: '* доступно только тогда, когда транскрипт не пустой.',
    icon: <Pencil />,
  },
}

const ModalInfoHelp: FC<{ isOpenModal: boolean }> = memo(({ isOpenModal }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
      className={isOpenModal ? `${styles.modalContainer} ${styles.modalContainerActive}` : styles.modalContainer}
    >
      <ul className={styles.list}>
        {Object.keys(helpInfo).map((item, index) => (
          <ModalInfoHelpItem
            key={index}
            keysItem={helpInfo[item].keysItem}
            icon={helpInfo[item].icon}
            title={helpInfo[item].title}
            text1={helpInfo[item].text1}
            text2={helpInfo[item].text2}
            textInfo={helpInfo[item].textInfo}
          />
        ))}
      </ul>
    </div>
  )
})

export default ModalInfoHelp
