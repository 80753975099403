import React from 'react'
import styles from './styles.module.scss'

export const AlfAssistantFooterSendIcon = () => {
  return (
    <svg
      className={styles.svg}
      width='21'
      height='18'
      viewBox='0 0 21 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 18L21 9L0 0V7L15 9L0 11V18Z' />
    </svg>
  )
}
