import React from 'react'

export const CameraIcon = ({ cameraSize }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' style={cameraSize} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.19844 6.11999V7.11999C7.5541 7.11999 7.88301 6.93108 8.06222 6.62386L7.19844 6.11999ZM8.87844 3.23999V2.23999C8.52277 2.23999 8.19387 2.4289 8.01466 2.73612L8.87844 3.23999ZM15.1184 3.23999L15.9822 2.73612C15.803 2.4289 15.4741 2.23999 15.1184 2.23999V3.23999ZM16.7984 6.11999L15.9347 6.62386C16.1139 6.93108 16.4428 7.11999 16.7984 7.11999V6.11999ZM3.39844 18.36V8.51999H1.39844V18.36H3.39844ZM4.79844 7.11999H7.19844V5.11999H4.79844V7.11999ZM8.06222 6.62386L9.74222 3.74386L8.01466 2.73612L6.33466 5.61612L8.06222 6.62386ZM8.87844 4.23999H15.1184V2.23999H8.87844V4.23999ZM14.2547 3.74386L15.9347 6.62386L17.6622 5.61612L15.9822 2.73612L14.2547 3.74386ZM16.7984 7.11999H19.1984V5.11999H16.7984V7.11999ZM20.5984 8.51999V18.36H22.5984V8.51999H20.5984ZM20.5984 18.36C20.5984 19.1332 19.9716 19.76 19.1984 19.76V21.76C21.0762 21.76 22.5984 20.2378 22.5984 18.36H20.5984ZM19.1984 7.11999C19.9716 7.11999 20.5984 7.74679 20.5984 8.51999H22.5984C22.5984 6.64222 21.0762 5.11999 19.1984 5.11999V7.11999ZM3.39844 8.51999C3.39844 7.74679 4.02524 7.11999 4.79844 7.11999V5.11999C2.92067 5.11999 1.39844 6.64222 1.39844 8.51999H3.39844ZM4.79844 19.76C4.02524 19.76 3.39844 19.1332 3.39844 18.36H1.39844C1.39844 20.2378 2.92067 21.76 4.79844 21.76V19.76ZM14.5984 12.84C14.5984 14.2759 13.4344 15.44 11.9984 15.44V17.44C14.5389 17.44 16.5984 15.3805 16.5984 12.84H14.5984ZM11.9984 15.44C10.5625 15.44 9.39844 14.2759 9.39844 12.84H7.39844C7.39844 15.3805 9.45793 17.44 11.9984 17.44V15.44ZM9.39844 12.84C9.39844 11.404 10.5625 10.24 11.9984 10.24V8.23999C9.45793 8.23999 7.39844 10.2995 7.39844 12.84H9.39844ZM11.9984 10.24C13.4344 10.24 14.5984 11.404 14.5984 12.84H16.5984C16.5984 10.2995 14.5389 8.23999 11.9984 8.23999V10.24ZM19.1984 19.76H4.79844V21.76H19.1984V19.76Z'
        fill='#95999E'
      />
    </svg>
  )
}
