import React from 'react'
import styles from './style.module.scss'
import { AddChatButtonPlusIcon } from './components/AddChatButtonPlusIcon'
import { HistoryIcon } from './components/HistoryIcon'
import { ChevronIcon } from './components/ChevronIcon'

interface ButtonBlockProps {
  handleOpenHistoryModal: () => void
  handleCreateNewChat: () => void
}
export const MobileButtonBlock = (props: ButtonBlockProps) => {
  const { handleOpenHistoryModal, handleCreateNewChat } = props
  return (
    <section className={styles.buttonBlock}>
      <div className={styles.wrapper}>
        <button className={styles.addChatButton} onClick={handleCreateNewChat} onTouchStart={handleCreateNewChat}>
          <AddChatButtonPlusIcon />
          <span>Новый чат</span>
        </button>
        <button className={styles.historyButton} onClick={handleOpenHistoryModal} onTouchStart={handleOpenHistoryModal}>
          <HistoryIcon />
          <span>История</span>
        </button>
      </div>
      <button className={styles.chatChangeButton}>
        <span>ALF</span>
        <ChevronIcon />
      </button>
    </section>
  )
}
