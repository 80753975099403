import React from 'react'
import styles from './styles.module.scss'

interface ISwitchButtonProps {
  onChange: () => void
  checked: boolean
}

export const SwitchButton: React.FC<ISwitchButtonProps> = ({ onChange, checked }) => {
  return (
    <>
      <input className={styles.checkbox} id='switch' type='checkbox' checked={checked} onChange={onChange} />
      <label className={styles.label} htmlFor='switch'>
        <span className={styles.button} />
      </label>
    </>
  )
}
