import React from 'react'
import styles from './style.module.scss'

interface ISwitchProps {
  checked?: boolean
  disabled?: boolean
  onChange?: (id: any) => void
}

export const Switch: React.FC<ISwitchProps> = ({ checked, disabled, onChange }) => {
  return (
    <label>
      <input
        type='checkbox'
        className={styles.invisibleCheckbox}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span className={styles.visibleCheckbox}>
        <div className={styles.visibleCheckbox__switch} />
      </span>
    </label>
  )
}
