import React from 'react'

export const PlusIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M7.90608 9.09311L8.09311 9.09311L8.09313 12.4064C8.09313 12.4065 8.09313 12.4065 8.09313 12.4065C8.09311 12.4581 8.05126 12.5 7.99964 12.5L7.90608 9.09311ZM7.90608 9.09311L7.90607 12.4065V12.4065C7.90607 12.4581 7.94786 12.5 7.99957 12.5L7.90608 9.09311ZM12.4065 7.90606H12.4065C12.4581 7.90606 12.5 7.94786 12.5 7.9996C12.5 8.05115 12.4582 8.09308 12.4065 8.09313C12.4064 8.09313 12.4064 8.09313 12.4064 8.09313L9.09311 8.09312L9.09311 7.90606L12.4065 7.90606ZM8.09311 7.90605L8.09311 8.09311L7.90608 8.09311V7.90605L8.09311 7.90605ZM6.90608 7.90605V8.09311L3.59353 8.0931C3.54187 8.0931 3.5 8.05122 3.5 7.99957C3.5 7.94791 3.54188 7.90604 3.59352 7.90604L6.90608 7.90605ZM7.90608 6.90605L7.90608 3.59349V3.59346C7.90608 3.54185 7.94793 3.5 7.99954 3.5H7.99961C8.05124 3.5 8.0931 3.54184 8.09309 3.59349V3.59353L8.09311 6.90605L7.90608 6.90605Z'
        fill='#2F3136'
        stroke='#2F3136'
      />
    </svg>
  )
}
