import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { MobileInputPlusIcon } from './components/MobileInputPlusIcon'
import { MobileSendIcon } from './components/MobileSendIcon'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

interface ALFAssistantMobileChatInputProps {
  inputValue: string
  handleInputChange: (event: ChangeEvent) => void
  handleKeyPress: React.KeyboardEventHandler<HTMLTextAreaElement>
  handleSendMessage: () => void
  handleOpenCommandMenu: () => void
  handleInputEvent: (cursorPosition: number) => void
}

export const ALFAssistantMobileChatInput = ({
  inputValue,
  handleInputChange,
  handleKeyPress,
  handleSendMessage,
  handleOpenCommandMenu,
  handleInputEvent,
}: ALFAssistantMobileChatInputProps) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)
  const focusOnTextArea = () => {
    textAreaRef.current.focus()
  }
  const preProcessInputEvent = () => {
    const textArea = textAreaRef.current
    if (textArea) {
      const cursorPosition = textArea.selectionStart
      handleInputEvent(cursorPosition)
    }
  }
  React.useEffect(() => {
    focusOnTextArea()
  }, [])
  React.useEffect(() => {
    textAreaRef.current.style.height = 'auto'
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
  }, [inputValue])
  return (
    <section className={styles.inputSection}>
      <button className={styles.button} onClick={handleOpenCommandMenu} onTouchStart={handleOpenCommandMenu}>
        <MobileInputPlusIcon />
      </button>
      <textarea
        ref={textAreaRef}
        className={styles.textarea}
        value={inputValue}
        rows={1}
        onChange={(e) => handleInputChange(e)}
        onKeyPress={handleKeyPress}
        onKeyUp={preProcessInputEvent}
        onClick={preProcessInputEvent}
        placeholder={`'/' для команд, '@' для переменных...`}
      >
        <ReactMarkdown remarkPlugins={[gfm]}>{inputValue}</ReactMarkdown>
      </textarea>
      <button className={styles.button} onClick={handleSendMessage} onTouchStart={handleSendMessage}>
        <MobileSendIcon />
      </button>
    </section>
  )
}
