import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import VoiceQuestionIcon from './VoiceQuestionIcon/VoiceQuestionIcon'
import WriteIcon from './WriteIcon/WriteIcon'
import ModalInfoHelp from '../ModalInfoHelp/ModalInfoHelp'
import { createPortal } from 'react-dom'

type TSetVoiceActive = {
  setVoiceActive: (e: boolean) => void
}

const AlfAssistantFooterInputVoiceHelp: FC<TSetVoiceActive> = ({ setVoiceActive }) => {
  const [isOpenModal, setModalOpened] = useState<boolean>(false)
  const [isShown, setIsShown] = useState(false)
  const footer = document.getElementById('footer')
  useEffect(() => {
    document.addEventListener('click', () => {
      setModalOpened(false)
    })
    return () => {
      document.removeEventListener('click', () => setModalOpened(false))
    }
  }, [document])
  return (
    <div className={styles.container} id='containerRef'>
      <div onClick={() => setVoiceActive(false)} className={styles.writeIcon}>
        <WriteIcon />
      </div>
      <div
        className={styles.question}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={(e) => {
          setModalOpened(!isOpenModal)
          e.stopPropagation()
        }}
      >
        <VoiceQuestionIcon isShown={isShown} />
        <p className={styles.text}>Как использовать</p>
      </div>
      {createPortal(<ModalInfoHelp isOpenModal={isOpenModal} />, footer)}
    </div>
  )
}

export default AlfAssistantFooterInputVoiceHelp
