import React from 'react'
import styles from './style.module.scss'
import { setActiveTab } from '../../../../app/redux/tabHeaderDiskSlice'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'

import diskIcon from '../../images/disk.svg'
import floppyDiskIcon from '../../images/floppyDisk.svg'
import { goBackParentsChain, setCheckedItems } from 'pages/disk/redux/diskSlice'

interface Tabdata {
  title: string
  [image: string]: any
  active: boolean
}

export const TabSection = () => {
  const dispatch = useAppDispatch()

  const activeTab = useAppSelector((state) => state.tabIndex.value)

  const tabsData: Tabdata[] = [
    {
      title: 'Мой диск',
      image: diskIcon,
      active: true,
    },
    {
      title: 'Доступные мне',
      image: floppyDiskIcon,
      active: false,
    },
  ]

  const titles = tabsData.map((name, i, icon) => {
    return (
      <li className={`${styles.tab} ${i === activeTab ? styles.active : ''}`} key={i} onClick={() => onSelectedTab(i)}>
        <div className={styles.div}>
          <img
            className={`${styles.icon} ${i === activeTab ? styles.iconActive : ''}`}
            src={icon[i].image}
            alt='Здесь должна быть картинка'
          />
        </div>
        {name.title}
      </li>
    )
  })

  const onSelectedTab = (index) => {
    dispatch(setCheckedItems([]))
    dispatch(goBackParentsChain(null))
    dispatch(setActiveTab(index))
  }

  return (
    <div className={styles.tabSection}>
      <div className={styles.tabs}>
        <ul className={styles.group}>{titles}</ul>
      </div>
    </div>
  )
}
