import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { AlfAssistantHeaderPhoneIcon } from './components/AlfAssistantHeaderPhoneIcon'
import { setIsOpenBotListModal, setPinnedBotAssistants, setViewedBotAssistants } from 'app/redux/alfAssistantSlice'
import { PlusIcon } from './icons/PlusIcon'
import { AssistantIconList } from './components/AssistantIconList'

export const AflAssistantHeader: React.FC = () => {
  const dispatch = useAppDispatch()
  const assistants = useAppSelector((state) => state.alfAssistant.assistants)
  const newAssistants = useAppSelector((state) => state.alfAssistant.newAssistants)

  const handleClickOpen = () => dispatch(setIsOpenBotListModal(true))

  useEffect(() => {
    const pinned = assistants.filter((assistant) => assistant.isPinned === true)
    dispatch(setPinnedBotAssistants(pinned))
    const viewed = newAssistants.filter((assistant) => assistant.isViewed === true)
    dispatch(setViewedBotAssistants(viewed))
  }, [assistants, dispatch])

  return (
    <header className={styles.header}>
      <div className={styles.icon}>
        <AssistantIconList type='pinned' quantity={7} />
        <div className={styles.assistant}>
          <button type='button' className={styles.btn_add_assistant} onClick={handleClickOpen}>
            <PlusIcon />
          </button>
        </div>
        <AssistantIconList type='viewed' quantity={3} />
      </div>
      <AlfAssistantHeaderPhoneIcon />
    </header>
  )
}
