import React from 'react'

export const MobileInputPlusIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 3.42871V20.0954' stroke='#95999E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.42969 12H20.0964' stroke='#95999E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
