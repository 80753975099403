import React, { useEffect, useRef, useState } from 'react'

export const useElementInView = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '5px',
      threshold: 1,
    })

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef && containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [containerRef])

  return { isVisible, containerRef }
}
