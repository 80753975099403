import React, { useRef, useState } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import useOutsideClick from 'lib/hooks/useOutsideClick'
import AssistantListRemnant from './AssistantListRemnant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { AlfAssistantIconModal } from '../../AlfAssistantIconModal/AlfAssistantIconModal'
import {
  changeIsPinned,
  removePinning,
  removeViewedAssistant,
  setCurrentAssistantId,
  addViewed,
  removeViewed,
  viewedAssistant,
} from 'app/redux/alfAssistantSlice'
import { ChatAvatar } from 'components/UI-ALF/ChatAvatar/ChatAvatar'
import { selectChat } from 'app/redux/userSlice'
import CloseIcon from './CloseIcon'

interface IAssistant {
  isViewed: boolean
  avatar: string | null
  description: string
  id: number
  isEditable: boolean
  isPinned: boolean
  name: string
}
interface IAssistantIconList {
  type: 'viewed' | 'pinned'
  quantity: number
}

export const AssistantIconList: React.FC<IAssistantIconList> = ({ type, quantity }) => {
  const dispatch = useAppDispatch()
  const modalRef = useRef(null)
  const currentAssistantId = useAppSelector((state) => state.alfAssistant.currentAssistantId)
  const pinnedBotAssistants = useAppSelector((state) => state.alfAssistant.pinnedBotAssistants)
  const viewedBotAssistants = useAppSelector((state) => state.alfAssistant.viewedBotAssistants)
  const [isOpenAssistantListRemnant, setIsOpenAssistantListRemnant] = useState(false)
  const toggleOpenAssistantListRemnant = () => {
    setIsOpenAssistantListRemnant(!isOpenAssistantListRemnant)
  }
  const detectTypeBotAssistant = (type: string) => {
    const types = {
      viewed: viewedBotAssistants,
      pinned: pinnedBotAssistants,
    }
    return types[type]
  }
  const avatar = {
    width: '33px',
    height: '33px',
    background: '#7B61FF',
  }
  useOutsideClick(modalRef, () => {
    setIsOpenAssistantListRemnant(false)
  })

  const removePin = (assistant: IAssistant) => {
    dispatch(removePinning(assistant.id)).then(() => {
      dispatch(changeIsPinned({ id: assistant.id, isPinned: !assistant.isPinned }))
    })
  }
  const removeView = (assistant: IAssistant) => {
    dispatch(removeViewedAssistant(assistant.id)).then(() => {
      dispatch(removeViewed({ id: assistant.id }))
    })
  }
  const addViewedAssistent = (assistant: IAssistant) => {
    dispatch(addViewed({ id: assistant.id }))
    if (!assistant.isViewed) {
      dispatch(viewedAssistant(assistant.id))
    }
  }
  const handleOpenChat = ({ id, chatUid }: { id: number | null; chatUid: string }) => {
    dispatch(setCurrentAssistantId(id))
    dispatch(selectChat(chatUid))
    dispatch(addViewed({ id }))
  }

  return (
    <div className={styles.AssistantIconList}>
      {detectTypeBotAssistant(type)
        .slice(0, quantity)
        .map((assistant) => (
          <div
            key={assistant.id}
            className={clsx(styles.assistant, { [styles.assistant_active]: currentAssistantId === assistant.id })}
            onClick={() => addViewedAssistent(assistant)}
          >
            {assistant.id !== 1 ? (
              <button
                className={styles.close}
                onClick={(e) => {
                  e.stopPropagation()
                  type === 'pinned' && removePin(assistant)
                  type === 'viewed' && removeView(assistant)
                }}
              >
                <CloseIcon />
              </button>
            ) : (
              type === 'viewed' && (
                <button
                  className={styles.close}
                  onClick={(e) => {
                    e.stopPropagation()
                    type === 'viewed' && removeView(assistant)
                  }}
                >
                  <CloseIcon />
                </button>
              )
            )}
            <div className={styles.tooltip}>
              <span className={styles.tooltip_text}>{assistant?.name}</span>
            </div>
            <button
              type='button'
              className={styles.const_btn_add_assistant}
              onClick={() => handleOpenChat({ id: assistant.id, chatUid: assistant.chatUid })}
            >
              <div className={styles.nonСlickable}>
                {assistant.id === 1 ? (
                  <AlfAssistantIconModal style={{ display: 'flex' }} />
                ) : (
                  <ChatAvatar style={avatar} icon={assistant.avatar} cameraSize={{ width: '20px' }} />
                )}
              </div>
            </button>
          </div>
        ))}
      {detectTypeBotAssistant(type)?.length > quantity && (
        <div className={clsx(styles.assistant)}>
          <button type='button' onClick={toggleOpenAssistantListRemnant} className={styles.const_btn_add_assistant}>
            <div className={styles.assistantListRemnantBtn}>{detectTypeBotAssistant(type)?.length - quantity}</div>
          </button>
        </div>
      )}
      <AssistantListRemnant
        ref={modalRef}
        isOpen={isOpenAssistantListRemnant}
        setOpen={setIsOpenAssistantListRemnant}
        assistants={detectTypeBotAssistant(type).slice(quantity)}
        handleOpenChat={handleOpenChat}
      />
    </div>
  )
}
