import React from 'react'
import styles from './style.module.scss'

interface IPrimaryButtonProps {
  title: string
  disabled?: boolean
  onClick?: () => void
}

export const PrimaryButton: React.FC<IPrimaryButtonProps> = ({ title, disabled, onClick }) => {
  return (
    <button className={styles.button} disabled={disabled} onClick={onClick}>
      {title}
    </button>
  )
}
