import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { api } from 'app/api'
import type { PayloadAction } from '@reduxjs/toolkit'

// ручка для создания интеграции с Telegram
export const createdTelegramIntegration = createAsyncThunk<any, any, any>(
  'checklist/createdTelegramIntegration',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post<any>('telegram/channels/', data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

/* export const createdTelegramIntegration = createAsyncThunk(
  'checklist/createdTelegramIntegration',
  async (data: any) => {
    const response = await api.post<any>(`telegram/channels/`, data)
    return response.data
  },
) */

export const deleteTelegramIntegration = createAsyncThunk('checklist/deleteTelegramIntegration', async (id: number) => {
  const response = await api.delete<any>(`telegram/channels/${id}`)
  return response.data
})

// ручка для редактирования данных интеграции Telegram
export const editTelegramIntegration = createAsyncThunk(
  'checklist/editTelegramIntegration',
  async ({ id, data }: { id: number; data: any }) => {
    const response = await api.patch<any>(`telegram/channels/${id}/`, data)
    return response.data
  },
)
// ручка для получения данных текущей интеграции Telegram
export const getTelegramChannelInfo = createAsyncThunk('getTelegramChannel', async (pollId: number) => {
  const response = await api.get<any>(`telegram/channels/info/${pollId}`)
  return response.data
})

export const sendMessageTelegram = createAsyncThunk('sendMessageTelegram', async (data: any) => {
  const response = await api.post<any>(`telegram/channels/send_message/`, data)
  return response.data
})

export const getTelegramChannel = createAsyncThunk('getTelegramChannel', async (id: number) => {
  const response = await api.get<any>(`telegram/channels/${id}`)
  return response.data
})

export const getChannelsTelegram = createAsyncThunk('getChannelsTelegram', async () => {
  const response = await api.get<any>(`telegram/channels/`)
  return response.data
})
export const getGooglesheetIntegration = createAsyncThunk('checklist/getGooglesheetIntegration', async (id: number) => {
  const response = await api.get<any>(`googlesheet-integration/${id}/checkisactive/`)
  return response.data
})
export const createdGooglesheetIntegration = createAsyncThunk(
  'checklist/createdGooglesheetIntegration',
  async (data: any) => {
    const response = await api.post<any>(`googlesheet-integration/get_credentials/`, data)
    return response.data
  },
)

export const switchGooglesheetIntegration = createAsyncThunk(
  'checklist/switchGooglesheetIntegration',
  async (id: number) => {
    const response = await api.post<any>(`googlesheet-integration/${id}/switch_activity/`, '')
    return response.data
  },
)

export const checkNewSurveyPassing = createAsyncThunk('checklist/checkNewSurveyPassing', async (id: number) => {
  const response = await api.get<any>(`googlesheet-integration/${id}/check_new_survey_passings/`, '')
  return response.data
})

export const addOldDataGooglesheetIntegration = createAsyncThunk(
  'checklist/addOldDataGooglesheetIntegration',
  async (id: number) => {
    const response = await api.post<any>(`googlesheet-integration/${id}/update_old_data/`, '')
    return response.data
  },
)

export const deleteGooglesheetIntegration = createAsyncThunk(
  'checklist/deleteGooglesheetIntegration',
  async (id: number) => {
    const response = await api.post<any>(`googlesheet-integration/${id}/disable/`, '')
    return response.data
  },
)

export const checklistSlice = createSlice({
  name: 'checklist',
  initialState: {
    isActiveDescriptionCheckList: true,
    URLGoogleSheets: null,
    telegramIntegrationStatus: null,
    error: null,
  } as any,
  reducers: {
    changedActiveDescriptionCheckList(state, { payload }: PayloadAction<{ value: boolean }>) {
      state.isActiveDescriptionCheckList = payload.value
    },
    changedURLGoogleSheets(state, { payload }: PayloadAction<{ value: string }>) {
      state.URLGoogleSheets = payload.value
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createdTelegramIntegration.pending, (state: any, action) => {
      state.telegramIntegrationStatus = 'loading'
    })
    builder.addCase(createdTelegramIntegration.fulfilled, (state: any, action) => {
      state.telegramIntegrationStatus = 'succeeded'
      /* console.log('createdTelegramIntegration fulfilled', action.payload) */
    })
    builder.addCase(createdTelegramIntegration.rejected, (state: any, action) => {
      state.telegramIntegrationStatus = 'failed'
      state.error = action.payload
      console.log('createdTelegramIntegration rejected', action.payload)
    })
    /* builder.addCase(editTelegramIntegration.fulfilled, (state: any, action) => {
      state.editTelegramIntegration = action.payload
      console.log('editTelegramIntegration fulfilled', action.payload)
    }) */
    // builder.addCase(postIntegrationGoogle.fulfilled, (state: any, action) => {
    //   state.postIntegrationGoogle = action.payload
    //   console.log('postIntegrationGoogle fulfilled', action.payload)
    // })
    // builder.addCase(postIntegrationGoogle.rejected, (state: any, action) => {
    //   console.log('postIntegrationGoogle rejected', action.payload)
    // })
  },
})

// export const {} = checklistSlice.actions
export const { changedActiveDescriptionCheckList, changedURLGoogleSheets } = checklistSlice.actions
export default checklistSlice.reducer
function rejectWithValue(data: any): any {
  throw new Error('Function not implemented.')
}
