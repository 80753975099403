import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { Header } from './components/Header'
import { StartScreen } from './components/StartScreen'
import { Footer } from './components/Footer'
import { TabsScreen } from './components/TabsScreen'

const fontsToNeedBeLoaded = [
  '400 74px BravoRG',
  '600 18px Source Sans Pro',
  '400 14px Source Sans Pro',
  '400 16px Source Sans Pro',
]

const PageLanding = () => {
  // const showLanding = useAppSelector((state) => state.profile.showLanding)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    fontsToNeedBeLoaded.forEach((font) => document.fonts.load(font))
    document.fonts.ready.then(() => {
      setIsReady(true)
    })
  }, [])

  return (
    isReady && (
      <>
        <div className={Styles.container}>
          <Header />
          <div className={Styles.landing}>
            <div className={Styles.wrapper}>
              <StartScreen />
              <TabsScreen />
            </div>
            <Footer />
          </div>
        </div>
      </>
    )
  )
}

export default PageLanding
