import React from 'react'
import styles from './styles.module.scss'
import { AlfAssistantIconModal } from '../AlfAssistantIconModal/AlfAssistantIconModal'
import { useAppSelector } from 'app/redux/hooks'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import camera from '../../../../assets/icons/cameraIcon.svg'
import { MessageMenu } from './MessageMenu/MessageMenu'
import { toast } from 'react-toastify'
import { CodeBlock } from 'components/CodeBlock'

interface AlfAssistantMessageItemProps {
  message: string
  answer: boolean
  id?: string
  index?: number
  handleCreateCommandFromMessage: (content: string) => void
}

export const AlfAssistantMessageItem = ({
  message,
  answer,
  index,
  handleCreateCommandFromMessage,
}: AlfAssistantMessageItemProps) => {
  const user = useAppSelector((state) => state.user)
  const { avatar } = user.userData
  const messageStyle = answer ? styles.container : `${styles.container} ${styles.request}`
  const [isOpenMessageMenu, setIsOpenMessageMenu] = React.useState(false)

  const callbacks = {
    showMessageMenu: () => {
      if (index > 0) {
        setIsOpenMessageMenu(true)
      }
    },
    hideMessageMenu: () => {
      if (index > 0) {
        setIsOpenMessageMenu(false)
      }
    },
    handleCopyMessage: async () => {
      await navigator.clipboard.writeText(message)
      toast.info('Сообщение скопировано')
    },
  }
  return (
    <div className={styles.item} onMouseOver={callbacks.showMessageMenu} onMouseLeave={callbacks.hideMessageMenu}>
      {isOpenMessageMenu && (
        <MessageMenu
          isAnswerMessage={answer}
          message={message}
          handleCopyMessage={callbacks.handleCopyMessage}
          handleCreateCommandFromMessage={handleCreateCommandFromMessage}
        />
      )}
      {answer ? (
        <AlfAssistantIconModal />
      ) : (
        <div className={styles.avatar}>
          {avatar ? (
            <img className={styles.image} src={avatar} alt='Фотография' />
          ) : (
            <img src={camera} alt='Фотография' />
          )}
        </div>
      )}
      <div className={messageStyle}>
        <div className={styles.message}>
          {message !== '' ? (
            <ReactMarkdown remarkPlugins={[gfm]} components={{ code: CodeBlock }}>
              {message}
            </ReactMarkdown>
          ) : (
            <span style={{ color: 'gray' }}>Думаю...</span>
          )}
        </div>
      </div>
    </div>
  )
}
