import translationBottomPanelEN from './BottomPanel/en.json'
import translationBottomPanelRU from './BottomPanel/ru.json'
import translationTableEN from './Table/en.json'
import translationTableRU from './Table/ru.json'
import translationFileCellEN from './FileCell/en.json'
import translationFileCellRU from './FileCell/ru.json'
import translationStoryboardRowFormEN from './StoryboardRowForm/en.json'
import translationStoryboardRowFormRU from './StoryboardRowForm/ru.json'
import translationColumnMenuEN from './Table/ColumnMenu/en.json'
import translationColumnMenuRU from './Table/ColumnMenu/ru.json'

export const translationStoryboardEN = {
  ...translationBottomPanelEN,
  ...translationTableEN,
  ...translationFileCellEN,
  ...translationColumnMenuEN,
  ...translationStoryboardRowFormEN,
}
export const translationStoryboardRU = {
  ...translationBottomPanelRU,
  ...translationTableRU,
  ...translationFileCellRU,
  ...translationColumnMenuRU,
  ...translationStoryboardRowFormRU,
}
