import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export const DocumentVariablesInput = ({ state, title, name, handleChangeForm, placeholder, ...props }) => {
  return (
    <div className={styles.container}>
      <label>{title}</label>
      <textarea
        className={clsx(
          props.stroke ? styles.stroke : null,
          props.height ? styles.height : null,
          props.resize ? styles.resize : null,
          props.error ? styles.error : null,
        )}
        onChange={handleChangeForm}
        data-name={name}
        value={state[name]}
        placeholder={placeholder}
      />
    </div>
  )
}
