import React from 'react'

export const PinIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7936 4.60971L19.3436 9.15971C20.175 9.99469 20.175 11.3447 19.3436 12.1797L16.5536 14.9697C16.395 15.1333 16.2604 15.3185 16.1536 15.5197L14.6136 18.6097C14.3037 19.2231 13.7192 19.6514 13.041 19.7621C12.3628 19.8728 11.6725 19.6527 11.1836 19.1697L8.51357 16.4997L5.28357 19.7297C5.14369 19.8713 4.95264 19.9507 4.75357 19.9497C4.55382 19.9542 4.36141 19.8744 4.22357 19.7297C4.08062 19.5904 4 19.3993 4 19.1997C4 19.0001 4.08062 18.809 4.22357 18.6697L7.45357 15.4397L4.78357 12.7697C4.29628 12.2828 4.0732 11.591 4.1842 10.9111C4.2952 10.2313 4.72671 9.64638 5.34357 9.33971L8.43357 7.79971C8.63482 7.69293 8.81998 7.55827 8.98357 7.39971L11.7936 4.60971C12.6284 3.79676 13.9588 3.79676 14.7936 4.60971ZM15.4236 13.8297L18.2036 11.0497C18.4205 10.8476 18.4339 10.5083 18.2336 10.2897L13.6936 5.74971C13.5938 5.64707 13.4567 5.58917 13.3136 5.58917C13.1704 5.58917 13.0334 5.64707 12.9336 5.74971L10.1536 8.52971C9.86141 8.81694 9.52345 9.05351 9.15357 9.22971L6.06357 10.7797C5.90719 10.8523 5.7981 10.999 5.77357 11.1697C5.74111 11.3386 5.79777 11.5124 5.92357 11.6297L12.3236 18.0297C12.4182 18.1337 12.553 18.1919 12.6936 18.1897C12.8987 18.1932 13.0868 18.0757 13.1736 17.8897L14.7236 14.8297C14.8998 14.4598 15.1363 14.1219 15.4236 13.8297Z'
        fill='#95999E'
      />
    </svg>
  )
}
