import { createSlice, PayloadAction } from '@reduxjs/toolkit' // eslint-disable-line import/named
import { StepType, TrackerType } from '../helpers/trackerTypes'

const initialState: TrackerType = {
  currentStep: 0,
  steps: [],
}

export const trackerSlice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    saveStep: (state: TrackerType, action: PayloadAction<StepType>) => {
      const stepsUpdate = state.steps.slice(0, state.currentStep)
      stepsUpdate.push(action.payload)
      state.steps = stepsUpdate
      state.currentStep++
    },
    stepBack: (state: TrackerType) => {
      state.currentStep--
    },
    stepForward: (state: TrackerType) => {
      state.currentStep++
    },
    resetSteps: (state: TrackerType) => {
      Object.assign(state, initialState)
    },
  },
})

export const { saveStep, stepBack, stepForward, resetSteps } = trackerSlice.actions
export default trackerSlice.reducer
