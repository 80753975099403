import React from 'react'
import styles from './styles.module.scss'
import { useAppDispatch } from 'app/redux/hooks'
import { ReturnButtonIcon } from './components/ReturnButtonIcon'
import { ALFAssistantAvatar } from '../ALFAssistantAvatar'
import { setIsOpenAssistantChat } from 'app/redux/assistantSlice'

export const ALFAssistantMobileChatHeader = () => {
  const dispatch = useAppDispatch()
  const handleReturnIconClick = () => {
    dispatch(setIsOpenAssistantChat(false))
  }
  return (
    <header className={styles.header}>
      <button className={styles.returnButton} onClick={handleReturnIconClick}>
        <ReturnButtonIcon />
      </button>
      <ALFAssistantAvatar />
      <h4 className={styles.title}>ALF</h4>
    </header>
  )
}
