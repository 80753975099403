import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { ApolloProvider } from '@apollo/client'
import client from './apollo/client'
import { Provider } from 'react-redux'
import App from './components/App'
import { store } from 'app/redux/store'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18nest'
import { BrowserRouter as Router } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/react'
import { ErrorModal } from 'components/ErrorModal'

Sentry.init({
  enabled: process.env.REACT_APP_ENV === 'production',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
})

document.documentElement.classList.add('mts-theme', 'mts-theme-light')

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <ErrorModal
          title='Непредвиденная ошибка'
          text='Попробуйте перезагрузить страницу или обратитесь в поддержку'
          isOpened
        />
      }
    >
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Router>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_KEY}>
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            </GoogleOAuthProvider>
          </Router>
        </Provider>
      </I18nextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)
