import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as ALfaAvatarIcon } from '../../../../assets/icons/alfa-historyPageIcon.svg'

const ALfaAvatar = () => {
  return (
    <div className={styles.chatHistoryPage__alfaAvatar}>
      <ALfaAvatarIcon />
    </div>
  )
}

export default ALfaAvatar
