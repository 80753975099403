import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CommandInfo } from '../interfaces/commands'
import { api } from 'app/api'

interface CommandEditorProps {
  isOpened: boolean
  isNew: boolean
  isLoading: boolean
  command: CommandInfo
}

const initialState: CommandEditorProps = {
  isOpened: false,
  isNew: true,
  isLoading: false,
  command: {
    name: '',
    templateText: '',
    example: '',
    result: '',
    favourite: false,
    isCommon: false,
    categories: [],
  },
}

export const loadCommandInfo = createAsyncThunk('commands/loadComandInfo', async (id: number) => {
  const res = await api.get<CommandInfo>(`catalog_template/${id}/`)
  if (res.status === 200) {
    const { data } = res
    return data
  } else {
    throw Error(res.statusText)
  }
})

export const saveEditingCommandInfo = createAsyncThunk(
  'commands/saveEditingCommandInfo',
  async (params: Partial<CommandInfo>) => {
    const { id, ...others } = params
    const res = await api.patch<CommandInfo>(`catalog_template/${id}/`, { ...others })
    if (res.status === 200) {
      return res.data
    } else {
      throw Error(res.statusText)
    }
  },
)

export const deleteCommand = createAsyncThunk('commands/deleteCommand', async (id: number) => {
  const res = await api.delete(`catalog_template/${id}/`)
  if (res.status === 204) {
    return ''
  } else {
    throw Error(res.statusText)
  }
})

interface CommandWithResultProps {
  templateText: string
  result: string
}

export const commandEditorSlice = createSlice({
  name: 'commandEditorSlice',
  initialState,
  reducers: {
    createNewNullComand(state) {
      state.isOpened = true
      state.isNew = true
      state.command = { ...initialState.command }
    },
    createNewCommand(state, { payload }: PayloadAction<string>) {
      state.isOpened = true
      state.isNew = true
      state.command = { ...initialState.command, templateText: payload }
    },
    createNewCommandWithResult(state, { payload }: PayloadAction<CommandWithResultProps>) {
      state.isOpened = true
      state.isNew = true
      const { result, templateText } = payload
      state.command = { ...initialState.command, templateText, result }
    },
    openForEdit(state) {
      state.isOpened = true
      state.isNew = false
    },
    closeAICommandEditorPanel(state) {
      state.isOpened = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCommandInfo.pending, (state) => {
      state.isLoading = true
      state.isOpened = true
      state.isNew = false
      state.command = {
        name: '',
        templateText: '',
        example: '',
        result: '',
        favourite: false,
        isCommon: false,
        categories: [],
      }
    })
    builder.addCase(loadCommandInfo.fulfilled, (state, { payload }: PayloadAction<CommandInfo>) => {
      state.isLoading = false
      state.command = payload
    })
    builder.addCase(saveEditingCommandInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(saveEditingCommandInfo.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(deleteCommand.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteCommand.fulfilled, (state) => {
      state.isOpened = false
      state.isLoading = false
    })
  },
})

export const {
  createNewCommand,
  createNewNullComand,
  openForEdit,
  closeAICommandEditorPanel,
  createNewCommandWithResult,
} = commandEditorSlice.actions

export default commandEditorSlice.reducer
