import React from 'react'

export const AddChatButtonPlusIcon = () => {
  return (
    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.90608 6.09311L5.09311 6.09311L5.09313 9.40645C5.09313 9.40646 5.09313 9.40646 5.09313 9.40647C5.09311 9.45809 5.05126 9.49996 4.99964 9.5L4.90608 6.09311ZM4.90608 6.09311L4.90607 9.40646V9.4065C4.90607 9.45805 4.94786 9.49996 4.99957 9.5L4.90608 6.09311ZM9.40647 4.90606H9.40653C9.4581 4.90606 9.5 4.94786 9.5 4.9996C9.5 5.05115 9.45818 5.09308 9.40647 5.09313C9.40645 5.09313 9.40643 5.09313 9.4064 5.09313L6.09311 5.09312L6.09311 4.90606L9.40647 4.90606ZM5.09311 4.90605L5.09311 5.09311L4.90608 5.09311V4.90605L5.09311 4.90605ZM3.90608 4.90605V5.09311L0.593528 5.0931C0.54187 5.0931 0.5 5.05122 0.5 4.99957C0.5 4.94791 0.541878 4.90604 0.593522 4.90604L3.90608 4.90605ZM4.90608 3.90605L4.90608 0.593489V0.593459C4.90608 0.541847 4.94793 0.499997 4.99954 0.5H4.99961C5.05124 0.499996 5.0931 0.541837 5.09309 0.593495V0.593529L5.09311 3.90605L4.90608 3.90605Z'
        fill='#2F3136'
        stroke='#2F3136'
      />
    </svg>
  )
}
