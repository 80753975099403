import React from 'react'
import styles from './styles.module.scss'
import { AlfAssistantSettingsTabBar } from './components/AlfAssistantSettingsTabBar/AlfAssistantSettingsTabBar'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getAssistantList } from 'app/redux/userSlice'
import { AlfAssistantCreateAssistant } from 'components/AlfAssistantModal/components/AlfAssistantCreateAssistant/AlfAssistantCreateAssistant'

const tabList = ['Основные', 'Интеграция', 'Публикация']

export const AlfAssistantSettings: React.FC = () => {
  const isOpenAssistantSettings = useAppSelector((state) => state.alfAssistant.isOpenAssistantSettings)

  const [activeTab, setActiveTab] = React.useState(tabList[0])
  const currentAssistantId = useAppSelector((state) => state.alfAssistant.currentAssistantId)
  const [loading, setLoading] = React.useState(true)
  // const currentAssistantData = useAppSelector((state) => state.alfAssistant.currentAssistantData)
  const [currentAssistantData, setCurrentAssistantData] = React.useState({
    name: '',
    description: '',
    avatar: null,
    prompt: '',
    engine: null,
    temperature: null,
    id: null,
  })
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    async function getAssistant() {
      try {
        const data = await dispatch(getAssistantList())
        const currentData = data.payload.find((item) => item.id === currentAssistantId)
        setCurrentAssistantData(currentData)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getAssistant()
  }, [])
  const settings = {
    Основные: (
      <AlfAssistantCreateAssistant
        create={false}
        name={currentAssistantData.name}
        description={currentAssistantData.description}
        isAvatatar={currentAssistantData.avatar}
        id={currentAssistantData.id}
      />
    ),
    Интеграция: <div />,
    Публикация: <div />,
  }

  return (
    <>
      {!loading && isOpenAssistantSettings && (
        <div className={styles.settings__container}>
          <div className={styles.setting__tabBar}>
            <h2 className={styles.settings__title}>Настройки</h2>
            <AlfAssistantSettingsTabBar list={tabList} activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div className={styles.settings__container2}>{settings[activeTab]}</div>
        </div>
      )}
    </>
  )
}
