import React from 'react'

export const ArrowRightIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M13.5273 5.52851C13.7876 5.26816 14.2098 5.26816 14.4701 5.52851L17.8034 8.86185C17.9255 8.98389 17.9903 9.14147 17.9979 9.30127C17.9984 9.31192 17.9987 9.32259 17.9987 9.33325'
        fill='#95999E'
      />
      <path
        d='M15.6997 10.0227L13.5273 12.1952C13.2669 12.4555 13.2669 12.8776 13.5273 13.138C13.7876 13.3983 14.2098 13.3983 14.4701 13.138L17.8034 9.80466C17.8674 9.74074 17.9156 9.66707 17.9481 9.58844C17.98 9.51162 17.9979 9.42756 17.9987 9.33941'
        fill='#95999E'
      />
      <path
        d='M5.9987 15.9999C6.36689 15.9999 6.66536 16.2984 6.66536 16.6666V17.3333H17.332V16.6666C17.332 16.2984 17.6305 15.9999 17.9987 15.9999C18.3669 15.9999 18.6654 16.2984 18.6654 16.6666V17.9999C18.6654 18.3681 18.3669 18.6666 17.9987 18.6666H5.9987C5.63051 18.6666 5.33203 18.3681 5.33203 17.9999V16.6666C5.33203 16.2984 5.63051 15.9999 5.9987 15.9999Z'
        fill='#95999E'
      />
      <path
        d='M14.4701 5.52851C14.2098 5.26816 13.7876 5.26816 13.5273 5.52851C13.2669 5.78886 13.2669 6.21097 13.5273 6.47132L15.7434 8.68743C13.0601 8.76195 10.7028 9.04935 8.92 9.75505C7.84557 10.1803 6.94003 10.7725 6.30391 11.5983C5.66117 12.4326 5.33203 13.4556 5.33203 14.6666C5.33203 15.0348 5.63051 15.3333 5.9987 15.3333C6.36689 15.3333 6.66536 15.0348 6.66536 14.6666C6.66536 13.7109 6.91956 12.9839 7.36016 12.412C7.80737 11.8315 8.48516 11.3612 9.41073 10.9948C10.9674 10.3786 13.1107 10.0994 15.6997 10.0227L13.5273 12.1952C13.2669 12.4555 13.2669 12.8776 13.5273 13.138C13.7876 13.3983 14.2098 13.3983 14.4701 13.138L17.8034 9.80466C17.8674 9.74074 17.9156 9.66707 17.9481 9.58844C17.98 9.51162 17.9979 9.42756 17.9987 9.33941L17.9987 9.33325C17.9987 9.32259 17.9984 9.31192 17.9979 9.30127C17.9903 9.14147 17.9255 8.98389 17.8034 8.86185L14.4701 5.52851Z'
        fill='#95999E'
      />
    </svg>
  )
}
