import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { converterSize } from 'helpers/converterSize'
import { api } from '../api'

export interface responseDiskSpace {
  data: diskSpaceType
}

export interface diskSpaceType {
  // diskSpace: null | number
  trashSize: null | number
  projects: undefined | Array<any>
  deleteFileBox: Array<any>
  status: null | string
}

/* export const getDiskSpace: any = createAsyncThunk('disk/getDiskSpace', async function async(_, { dispatch }) {
  const response: responseDiskSpace = await api.get('auth/profile/')
  const size = converterSize(response.data.diskSpace)
  dispatch(addTotalSpace(size))
  return size
}) */

/* export const getBasketSpace: any = createAsyncThunk('disk/getBasketSpace', async function async(_, { dispatch }) {
  const response: responseDiskSpace = await api.get('trash/space/')
  const size = converterSize(response.data.trashSize)
  dispatch(addTrashSize(size))
  return response.data.trashSize
}) */

export const getDocumentsProject: any = createAsyncThunk('disk/getDocuments', async function async() {
  const response: responseDiskSpace = await api.get('projects/space/')
  return response.data.projects
})

export const deleteDocuments: any = createAsyncThunk(
  'disk/deleteDocuments',
  async function async(params, { dispatch }) {
    const response: any = await api.post('projects/space/clean/', params)
    dispatch(getDocumentsProject())
    return response
  },
)

export const deleteBasket: any = createAsyncThunk('disk/deleteBasket', async function async(_, { dispatch }) {
  const response: any = await api.delete('trash/clean/')
  // dispatch(getBasketSpace())
  return response
})

export const diskSpaceSlice = createSlice({
  name: 'disk',
  initialState: {
    // diskSpace: {},
    trashSize: {},
    projects: [],
    deleteFileBox: [],
    status: null,
  } as diskSpaceType,
  reducers: {
    /* addTotalSpace(state, action) {
      state.diskSpace = action.payload
    }, */
    addTrashSize(state, action) {
      state.trashSize = action.payload
    },
    addProjectSpace(state, action) {
      state.projects.push(action.payload)
    },
    addFileBoxDelete(state, action) {
      state.deleteFileBox = state.deleteFileBox.concat(action.payload)
    },
    cancelFileBoxDelete(state, action) {
      state.deleteFileBox = state.deleteFileBox.filter((item) => item.id !== action.payload)
    },
  },
  extraReducers: {
    [getDocumentsProject.pending]: (state) => {
      state.status = 'loading'
    },

    [getDocumentsProject.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.projects = action.payload
    },
  },
})

export const { addProjectSpace, addFileBoxDelete, cancelFileBoxDelete, addTrashSize } = diskSpaceSlice.actions
export default diskSpaceSlice.reducer
