import React from 'react'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const activityStateSlice = createSlice({
  name: 'activityState',
  initialState: [
    {
      modalTypeQuestion: false,
    },
    {
      modalCopyAndDelete: false,
      positionTop: 0,
    },
  ],
  reducers: {
    changedActiveTypeQuestion(state, { payload }: PayloadAction<{ value: boolean }>) {
      state[0].modalTypeQuestion = payload.value
    },
    changedActiveCopyAndDelete(state, { payload }: PayloadAction<{ value: boolean }>) {
      state[1].modalCopyAndDelete = payload.value
    },
    changedPositionTop(state, { payload }: PayloadAction<{ positionTop: number }>) {
      state[1].positionTop = payload.positionTop
    },
  },
})

export const { changedActiveTypeQuestion, changedActiveCopyAndDelete, changedPositionTop } = activityStateSlice.actions

export default activityStateSlice.reducer
