import React from 'react'
import styles from './styles.module.scss'

interface ICheckboxProps {
  label?
  id?
  name?
  value?
  title?
  checked?: boolean
  onChange?: () => void
}

export const Checkbox: React.FC<ICheckboxProps> = ({ id, name, value, title, label, checked, onChange }) => {
  return (
    <label htmlFor={label}>
      <input
        id={id}
        name={name}
        value={value}
        type='checkbox'
        className={styles.checkboxButton}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.checkboxLabel}>{title}</span>
    </label>
  )
}
