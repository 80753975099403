import { gql } from '@apollo/client'

export const CREATE_VARIABLE = gql`
  mutation CreatVar($name: String!, $value: String!, $description: String!, $type: String) {
    createVar(input: { name: $name, value: $value, description: $description, type: $type }) {
      ok
    }
  }
`

export const UPDATE_VARIABLE = gql`
  mutation UpdateVar($varId: ID!, $name: String!, $value: String!, $description: String!, $type: String) {
    updateVar(varId: $varId, input: { name: $name, value: $value, description: $description, type: $type }) {
      ok
    }
  }
`

export const CHANGE_ORDER = gql`
  mutation ChangeOrderVar($varId: ID!, $orderId: Int!) {
    updateVar(varId: $varId, input: { orderId: $orderId }) {
      ok
    }
  }
`

export const DELETE_VARIABLE = gql`
  mutation DeleteVar($varId: ID!) {
    deleteVar(varId: $varId) {
      ok
    }
  }
`
