import React from 'react'

export const CreateTemplateIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.33333 4.6665H3.33333C2.97971 4.6665 2.64057 4.80698 2.39052 5.05703C2.14048 5.30708 2 5.64622 2 5.99984V11.9998C2 12.3535 2.14048 12.6926 2.39052 12.9426C2.64057 13.1927 2.97971 13.3332 3.33333 13.3332H12.6667C13.0203 13.3332 13.3594 13.1927 13.6095 12.9426C13.8595 12.6926 14 12.3535 14 11.9998V5.99984C14 5.64622 13.8595 5.30708 13.6095 5.05703C13.3594 4.80698 13.0203 4.6665 12.6667 4.6665H10.6667M10 7.33317L8 9.33317M8 9.33317L6 7.33317M8 9.33317V2.6665'
        stroke='#95999E'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
