import React, { FC } from 'react'
import { AlfAssistantAddButton } from '../AlfAssistantAddButton/AlfAssistantAddButton'
import styles from './styles.module.scss'

type TAlfAssistantCreateAssistBtns = {
  isAssistantNameValid: boolean
  handleSubmit: () => void
  handleCloseCreateAssistant: () => void
}

const AlfAssistantCreateAssistBtns: FC<TAlfAssistantCreateAssistBtns> = ({
  isAssistantNameValid,
  handleSubmit,
  handleCloseCreateAssistant,
}) => {
  return (
    <div className={styles.buttons}>
      <AlfAssistantAddButton title='Создать' isButtonValid={isAssistantNameValid} onClick={handleSubmit} />
      <button className={styles.cancelButton} onClick={handleCloseCreateAssistant}>
        Отмена
      </button>
    </div>
  )
}

export default AlfAssistantCreateAssistBtns
