import React from 'react'

export const ArrowRightIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.33464 8H12.668' stroke='#DBDCDD' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.0013 12.6666L12.668 7.99992L8.0013 3.33325'
        stroke='#DBDCDD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
