import React from 'react'
import styles from './styles.module.scss'

interface ALFAssistantMobileMockElementProps {
  canselRequest: () => void
}

export const ALFAssistantMobileMockElement = ({ canselRequest }: ALFAssistantMobileMockElementProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.description}>ALF печатает ...</p>
      <p className={styles.command} onClick={canselRequest}>
        СТОП
      </p>
    </div>
  )
}
