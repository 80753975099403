const language = [
  { value: 'Авто', label: 'Авто' },
  { value: 'Русский', label: 'Русский' },
  { value: 'Немецкий', label: 'Немецкий' },
  { value: 'Английский', label: 'Английский' },
]

const model = [{ value: 'Open AI', label: 'Open AI' }]

const source = [
  { value: 'Текст', label: 'Текст' },
  { value: 'Файл', label: 'Файл' },
  { value: 'Документ', label: 'Документ' },
  { value: 'Ссылка', label: 'Ссылка' },
]

export { language, model, source }
