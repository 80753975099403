import React, { useEffect, useMemo } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark as dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import type { CodeProps } from 'react-markdown/lib/ast-to-react'

// CodeBlock for display in ALF and AI request

export const CodeBlock: React.FC<CodeProps> = (codeProps) => {
  const { inline, className, children } = codeProps

  const language = useMemo(() => /language-(\w+)/.exec(className || '') ?? ['javascript', 'javascript'], [className])

  useEffect(() => {
    // console.log('CODE DETECT', language)
  }, [language])

  useEffect(() => {
    // console.log('CODE DETECT', codeProps)
  }, [codeProps])

  return (
    <>
      {!inline && language ? (
        <SyntaxHighlighter
          // eslint-disable-next-line react/no-children-prop
          children={String(children).replace('/\n$/', '')}
          PreTag='div'
          language={language[1]}
          wrapLongLines
          style={dark}
          customStyle={{ borderRadius: '8px' }}
        />
      ) : (
        <code className={className}>{children}</code>
      )}
    </>
  )
}
