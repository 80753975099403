import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { apiTypes } from 'app/apiTypes'
import { api } from 'app/api'
import { AppDispatch, RootState } from './store'
import type { PayloadAction } from '@reduxjs/toolkit'
// import { AssistantStateType } from './assistantSlice'

export const getChats = createAsyncThunk('alf/getChats', async (ai_assistant_id?: number) => {
  const params: { ai_assistant_id?: number } = {}
  ai_assistant_id && (params.ai_assistant_id = ai_assistant_id)
  const response = await api.get<any>('ai/history/chat/', params)
  return response.data
})
export const createChat = createAsyncThunk('alf/createChat', async (data: any) => {
  const response = await api.post<any>(`ai/query_ai/`, data)
  return response.data
})
export const continueChat = createAsyncThunk('alf/continue', async (data: any) => {
  const response = await api.post<any>(`ai/query_ai/`, data)
  return response.data
})

export const getAssistantList = createAsyncThunk('alf/getAssistantList', async () => {
  const response = await api.get<any>(`ai_assistants/`)
  return response.data
})

export const deleteHistory = createAsyncThunk('alf/deleteHistory', async (uid: string) => {
  const response = await api.delete<any>(`ai/history/chat/${uid}`)
  return response.data
})
export const renameChatHistory = createAsyncThunk(
  'alf/renameChat',
  async (data: { chatId: string; newName: string }) => {
    const response = await api.patch<any>(`ai/history/chat/`, data)
    return response
  },
)

export const getChatId = createAsyncThunk('alf/getChatId', async () => {
  const response = await api.get<any>(`ai/history/chat/`)
  return response.data
})

export const sharePublicChatIdById = createAsyncThunk('alf/sharePublicChatIdById', async (chatId: string) => {
  const response = await api.patch<any>(`ai/history/share_chat/${chatId}/`, {})
  return response
})

export const getPublicChatById = createAsyncThunk('alf/getPublicChatById', async (chatId: string) => {
  const response = await api.get<any>(`ai/history/get_public_chat/${chatId}/`, {})
  return response
})

export const getUserProfile = createAsyncThunk('user/getUserProfile', async () => {
  const response = await api.get<any>(`auth/profile/`)
  return response.data
})

export const deleteUserProfile = createAsyncThunk('user/deleteUserProfile', async () => {
  const response = await api.delete<any>(`auth/profile/`)
  return response.data
})

export const changeUserPassword = createAsyncThunk('user/changeUserPassword', async (data: any) => {
  const response = await api.post<any>(`auth/password/change/`, data)
  return response.data
})

export const sendToAlfAssistantMessage = createAsyncThunk('ai/query', async (data: any) => {
  const { REACT_APP_API } = process.env
  const { source, signal, assistantId } = data

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${api.userToken}`,
    },
    body: JSON.stringify({ source, assistantId }),
    signal,
  }
  try {
    const response = await fetch(`${REACT_APP_API}/ai/query_ai/`, options)
    const data = await response.json()
    return data.payload
  } catch (error) {
    console.error(error)
  }
})

interface StreamResponse {
  error?: string
  payload: {
    delta: string
    finish_reason: string
  }
  debug_info?: any
  chat_uid?: any
}

interface AnswerGeneratedPart {
  id: string
  data: string
}

interface AnswerGeneratedPartError {
  id: string
  error: string
}

export const sendToAlfAssistantMessageStream = createAsyncThunk<any, any, { dispatch: AppDispatch; state: RootState }>(
  'ai/stream_query',
  async (inputData: any, { dispatch, getState }) => {
    console.log(inputData, { dispatch, getState })
    const { REACT_APP_API } = process.env
    const { source, signal, assistantId, chatUid } = inputData
    const {
      user: { chat },
    } = getState()
    const contextsList = chat
      .slice(1)
      .slice(-7)
      .map(({ text }) => text) // возможно здесь следует заменить и отправлять не n последних сообщений а, к примеру, 2000 последних символов
    const context = contextsList.join('\n\n')
    // assistantId используется в случае если выбран ассистент
    const dataBody = chatUid ? { source, assistantId, chatUid } : { source, assistantId }
    const body = JSON.stringify(contextsList.length > 1 ? { ...dataBody, context } : dataBody)

    let length = 0
    const uid = nanoid()
    dispatch(beginStreamAnswer(uid))
    const response = await fetch(`${REACT_APP_API}/stream/ai/query_ai/`, {
      headers: {
        'Content-Type': 'application/json',
        // Accept: 'text/event-stream',
        Authorization: `Bearer ${api.userToken}`,
      },
      method: 'POST',
      // credentials: 'include',
      cache: 'no-cache',
      // keepalive: true,
      body,
      signal,
    })
    const clone = response.clone()
    const reader = response.body.getReader()
    const textDecoder = new TextDecoder()
    let escape = false
    const handleEscapeKeyCheck = (ev: KeyboardEvent) => {
      if (ev.code === 'Escape') {
        escape = true
      }
    }

    let stopGenerateBot = false
    const handleStop = () => {
      stopGenerateBot = true
    }

    const stop = document.getElementById('stop') // Находим кнопку Стоп в чате ассистентов
    document.addEventListener('keydown', handleEscapeKeyCheck)
    // При нажатии на стоп изменяем состояния на true
    stop.addEventListener('click', handleStop)
    while (true) {
      if (escape) {
        dispatch(finishStreamAnswer())
        break
      }
      if (stopGenerateBot) {
        dispatch(finishStreamAnswer())
        break
      }

      try {
        const { value, done } = await reader.read()
        if (done) {
          dispatch(finishStreamAnswer())
          break
        }
        const text = textDecoder.decode(value)
        const datas = text.split('\n')
        // eslint-disable-next-line no-loop-func
        datas.forEach((data) => {
          if (data !== '') {
            const {
              // chunk_id,
              error,
              payload,
              chat_uid,
            }: StreamResponse = JSON.parse(data)
            if (error !== null) {
              ///
              console.error('ALF generation error', error)
              dispatch(setGeneratedError({ id: uid, error: `что то пошло не так: ${error}` }))
            }
            if (payload !== null && payload.delta !== null) {
              dispatch(setGeneratedPartData({ id: uid, data: payload.delta }))
              length += payload.delta.length
            }
            if (chatUid === null) {
              chat_uid !== undefined && dispatch(addChatId(chat_uid))
            }
          }
        })
      } catch (e) {
        console.error('ALF generation error', e)
        dispatch(setDebugId(uid))
        dispatch(setGeneratedError({ id: uid, error: 'Произошла ошибка ИИ' }))
      }
    }
    document.removeEventListener('keydown', handleEscapeKeyCheck)
    stop.removeEventListener('click', handleStop)
    // const responseText = await clone.text()
    const responseJson = await clone.json()
    return responseJson
  },
)

export const changeUserInfo = createAsyncThunk('user/changeUserInfo', async (data: any) => {
  const response = await api.patch<any>(`auth/profile/`, data)
  return response.data
})

export const changeUserEmail = createAsyncThunk('user/changeUserEmail', async (newEmail: string) => {
  const response = await api.post<apiTypes.UserData>(`auth/email/change/`, { new_email: newEmail })
  return response.data
})

export const verifyChangeEmail = createAsyncThunk('user/verifyChangeEmail', async ({ newEmail, code }: any) => {
  const response = await api.post<apiTypes.UserData>(`auth/email/change/confirm/`, { new_email: newEmail, code })
  return response.data
})

export const uploadAvatar: any = createAsyncThunk('user/uploadAvatar', async ({ avatar, onUploadProgress }: any) => {
  const response = await api.updateFileWithProgress<any>(`auth/profile/`, avatar, onUploadProgress)
  return response.data
})

export const registration = createAsyncThunk('user/registration', async (data: any) => {
  const response = await api.post<any>(`auth/signup/`, data)
  return response.data
})

export const login = createAsyncThunk('user/login', async (data: any) => {
  const response = await api.post<any>(`auth/login/`, data)
  return response.data
})

export const googleLogin = createAsyncThunk(
  'user/googleLogin',
  async (data: { provider: 'google-oauth2'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)

export const googleDisconnect = createAsyncThunk(
  'user/googleDisconnect',
  async (data: { provider: 'google-oauth2' }) => {
    const response = await api.post<any>(`oauth/social-disconnect/`, data)
    return response.data
  },
)
export const vkDisconnect = createAsyncThunk('user/vkDisconnect', async (data: { provider: 'vk-oauth2' }) => {
  const response = await api.post<any>(`oauth/social-disconnect/`, data)
  return response.data
})
export const mailruDisconnect = createAsyncThunk('user/mailruDisconnect', async (data: { provider: 'mailru' }) => {
  const response = await api.post<any>(`oauth/social-disconnect/`, data)
  return response.data
})
export const yandexDisconnect = createAsyncThunk(
  'user/yandexDisconnect',
  async (data: { provider: 'yandex-oauth2' }) => {
    const response = await api.post<any>(`oauth/social-disconnect/`, data)
    return response.data
  },
)

export const vkLogin = createAsyncThunk(
  'user/vkLogin',
  async (data: { provider: 'vk-oauth2'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)
export const mailRuLogin = createAsyncThunk(
  'user/mailRuLogin',
  async (data: { provider: 'mailru'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)
export const yandexLogin = createAsyncThunk(
  'user/yandexLogin',
  async (data: { provider: 'yandex-oauth2'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)

export const addEmail = createAsyncThunk('user/addEmail', async (data: { newEmail: string; newPassword: string }) => {
  const response = await api.post<any>(`auth/add_email/`, data)
  return response
})

export const confirmEmail = createAsyncThunk('user/confirmEmail', async (data: { code: string }) => {
  const response = await api.post<any>(`auth/add_email/confirm/`, data)
  return response
})

export const verifyEmail = createAsyncThunk('user/verifyEmail', async (data: any) => {
  const response = await api.post<any>(`auth/email/verify/`, data)
  return response.data
})

export const resendVerifyEmail = createAsyncThunk('user/resendVerifyEmail', async (email: string) => {
  const response = await api.post<any>(`auth/email/verify/resend/`, { email })
  return response.data
})

export const resetPassword = createAsyncThunk('user/resetPassword', async (email: string) => {
  const response = await api.post<any>(`auth/password/reset/`, { email })
  return response.data
})

export const tokenCheck = createAsyncThunk('user/tokenCheck', async (accessToken: string) => {
  const response = await api.post<any>(`auth/login/verify/`, { token: accessToken })
  return response.data
})

export const tokenRefresh = createAsyncThunk('user/tokenRefresh', async (refreshToken: string) => {
  const response = await api.post<any>(`auth/login/refresh/`, { refresh: refreshToken })
  return response.data
})

export const getDocUuid = createAsyncThunk('user/getDocUuid', async (data: { model: string; docId: string }) => {
  const response = await api.post<any>(`document/share/`, data)
  return response.data
})

export const getAnonymousToken = createAsyncThunk('user/getAnonymousToken', async (uuid: string) => {
  const response = await api.get<any>(`document/share/token/${uuid}/`)
  return response.data
})

export const deleteDocUuid = createAsyncThunk('user/getAnonymousToken', async (uuid: string) => {
  const response = await api.delete<any>(`document/share/${uuid}/`)
  return response.data
})

export interface Chat {
  chatUuid: string
  chatName?: string
  updatedAt?: string
  chatHistory?: ChatHistory[]
  name?: string
  avatar?: string
  description?: string
  id?: number
}

interface ChatHistory {
  role: string
  content: string
}

interface UserSliceType {
  userData: {
    id: null | string
    firstName: null | string
    lastName: null | string
    email: null | string
    phone: null | string
    birthday: null | string
    avatar: null | string
    subscriptionEnd: number
    subscriptionTrial: boolean
    oauth2Providers: [] | Array<'google-oauth2' | 'vk-oauth2' | 'mailru' | 'yandex-oauth2'>
    changedPasswordDate: null | string
    userLanguage: null | 'ru' | 'eng'
    isVerified: boolean
    isInvited: boolean
    loadedSpace: null | number
    subscription: {
      endDate: null | string
      tokens: null | number
      discSpace: null | number
    }
  }
  addedEmail: boolean
  dealerId: any
  userId: any
  status: null | string
  dataForConfirm: {
    email: null | string
    password: null | string
  }
  role: 'guest' | 'user' | 'anonymous' | 'read' | null
  chat: { id: string; answer: boolean; text: string }[]
  openTable: any
  chatHistory: { id: string; answer: boolean; text: string }[]
  chats: Chat[] | null
  continueChat: any
  firstMessage: { id: string; answer: boolean; text: string }
  firstMessageStableDiffusion: { id: string; answer: boolean; text: string }
  chatId: string | null
  publicChatId: string | null
  debugInfo: any
  debugId: string
  waiting: boolean
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      birthday: null,
      avatar: null,
      oauth2Providers: [],
      userLanguage: null,
      isVerified: false,
      isInvited: true,
      changedPasswordDate: null,
      loadedSpace: null,
      subscription: {
        endDate: null,
        tokens: null,
        discSpace: null,
      },
    },
    addedEmail: false,
    dealerId: null,
    userId: null,
    status: null,
    dataForConfirm: { email: null, password: null },
    role: null,
    chat: [],
    openTable: [],
    chatHistory: [
      {
        id: nanoid(),
        answer: true,
        text: ' Я - ALF AI, готов помочь вам в поиске ответов на любые вопросы. Чтобы начать чат, просто задайте мне вопрос.',
      },
    ],
    firstMessage: {
      id: nanoid(),
      answer: true,
      text: ' Я - ALF AI, готов помочь вам в поиске ответов на любые вопросы. Чтобы начать чат, просто задайте мне вопрос.',
    },
    firstMessageStableDiffusion: {
      id: nanoid(),
      answer: true,
      text: ' Ваш персональный художник теперь онлайн и готов воплотить ваши мечты в жизнь! Я умею генерировать изображения по описанию, смешивать  картинку и текст, а также генерировать вариации изображений Чтобы начать, выберите один из вариантов работы с моделью.',
    },
    chats: [],
    chatId: null,
    publicChatId: null,
    debugInfo: null,
    debugId: null,
    waiting: false,
  } as UserSliceType,
  reducers: {
    addChatId(state, action) {
      state.chatId = action.payload
    },
    addNumberTable(state, { payload }) {
      state.openTable = [...state.openTable, payload]
    },
    setDealerId(state, { payload }) {
      state.dealerId = payload
    },
    setUserDataForConfirm(state, { payload }) {
      state.dataForConfirm.email = payload.email
      state.dataForConfirm.password = payload.password
    },
    setRole(state, { payload }) {
      state.role = payload
    },
    setStatus(state, { payload }) {
      state.status = payload
    },
    setUserEmail(state, { payload }) {
      state.userData.email = payload
    },
    setIsVerified(state, { payload }) {
      state.userData.isVerified = payload
    },
    setChatHistoryUpdate(state, { payload = 'ALF' }) {
      state.chatHistory = [
        {
          id: nanoid(),
          answer: true,
          text: `Я - ${payload} AI, готов помочь вам в поиске ответов на любые вопросы. Чтобы начать чат, просто задайте мне вопрос.`,
        },
      ]
      state.firstMessage = {
        id: nanoid(),
        answer: true,
        text: `Я - ${payload} AI, готов помочь вам в поиске ответов на любые вопросы. Чтобы начать чат, просто задайте мне вопрос.`,
      }
    },
    setRequestToAlfAssistant(state, { payload }) {
      state.chat = [...state.chat, { id: nanoid(), answer: false, text: payload }]
    },
    beginStreamAnswer(state, { payload }: PayloadAction<string>) {
      state.chat = [...state.chat, { id: payload, answer: true, text: '' }]
    },
    setGeneratedPartData(state, { payload }: PayloadAction<AnswerGeneratedPart>) {
      const chat = Array.from(state.chat)
      const index = chat.findIndex((item) => item.id === payload.id)
      const [item] = chat.splice(index, 1)
      const nItem = { ...item, text: `${item.text}${payload.data}` }
      chat.splice(index, 0, nItem)
      state.chat = chat
    },
    setGeneratedError(state, { payload }: PayloadAction<AnswerGeneratedPartError>) {
      const chat = Array.from(state.chat)
      const index = chat.findIndex((item) => item.id === payload.id)
      const [item] = chat.splice(index, 1)
      const nItem = { ...item, text: `${item.text}  **${payload.error}**` }
      chat.splice(index, 0, nItem)
      state.chat = chat
      state.status = payload.error
    },
    finishStreamAnswer(state) {
      state.status = 'success'
    },
    deleteLastAlfMessage(state) {
      state.chat = state.chat.splice(state.chat.length - 1, 1)
    },
    deleteDebugInfo(state) {
      state.debugInfo = null
      state.debugId = null
    },
    setDebugId(state, { payload }) {
      state.debugId = payload
    },
    // selectChat(state, { payload }) {
    //   const currentChat = state.chats.find((chat) => chat.chatUuid === payload)
    //   state.chat = []
    //   state.chatHistory = currentChat.chatHistory.map((item: { role: string; content: any }) => {
    //     return { id: nanoid(), answer: item.role === 'assistant', text: item.content }
    //   })
    //   state.chatId = payload
    // },
    selectChat(state, { payload }) {
      const currentChat = state.chats.find((chat) => chat.chatUuid === payload)
      state.chat = []

      if (currentChat) {
        if (currentChat.chatHistory && currentChat.chatHistory.length > 0) {
          state.chatHistory = currentChat.chatHistory.map((item: { role: string; content: any }) => {
            return { id: nanoid(), answer: item.role === 'assistant', text: item.content }
          })
        } else {
          state.chatHistory = []
        }
        state.chatId = payload
      }
    },
    /* selectChat(state, { payload }) {
      const currentChat = state.chats.find((chat) => chat.chatUuid === payload)
      state.chat = []

      if (currentChat) {
        if (currentChat.chatHistory && currentChat.chatHistory.length > 0) {
          const newMessages = currentChat.chatHistory.map((item: { role: string; content: any }) => {
            return { id: nanoid(), answer: item.role === 'assistant', text: item.content }
          })
          state.chatHistory = [state.firstMessage, ...newMessages]
        } else {
          state.chatHistory = []
        }
        state.chatId = payload
      }
    }, */
    createNewChat(state) {
      state.chat = []
      state.chatHistory = [state.firstMessage]
      state.chatId = null
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.userData = payload
    })
    builder.addCase(sendToAlfAssistantMessageStream.fulfilled, (state, { payload }) => {
      state.debugInfo = payload
    })
    builder.addCase(deleteUserProfile.fulfilled, (state) => {
      localStorage.removeItem('JWT')
      api.setUserToken('')
      state.role = 'guest'
    })

    builder.addCase(changeUserInfo.fulfilled, (state, { payload }) => {
      state.userData = payload
    })

    builder.addCase(uploadAvatar.fulfilled, (state, { payload }) => {
      state.userData = payload
    })

    builder.addCase(verifyEmail.fulfilled, (state, { payload }) => {
      if (payload.access) {
        const { access, refresh } = payload
        localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
        api.setUserToken(access)
      }
    })

    builder.addCase(addEmail.fulfilled, (state) => {
      state.addedEmail = true
    })

    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(googleLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'google-oauth2']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(vkLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'vk-oauth2']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(mailRuLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'mailru']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(yandexLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'yandex-oauth2']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(googleDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'google-oauth2')
      state.userData.oauth2Providers = updatedProviders
    })

    builder.addCase(vkDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'vk-oauth2')
      state.userData.oauth2Providers = updatedProviders
    })

    builder.addCase(mailruDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'mailru')
      state.userData.oauth2Providers = updatedProviders
    })
    builder.addCase(yandexDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'yandex-oauth2')
      state.userData.oauth2Providers = updatedProviders
    })

    builder.addCase(tokenCheck.fulfilled, () => {
      const token = JSON.parse(localStorage.getItem('JWT')).access
      api.setUserToken(token)
    })

    builder.addCase(tokenRefresh.fulfilled, (state, { payload }) => {
      const refreshToken = JSON.parse(localStorage.getItem('JWT')).refresh
      localStorage.setItem('JWT', JSON.stringify({ access: payload.access, refresh: refreshToken }))
      api.setUserToken(payload.access)
      state.role = 'user'
    })

    builder.addCase(sendToAlfAssistantMessage.fulfilled, (state, { payload }) => {
      if (payload) {
        state.chat = [...state.chat, { id: nanoid(), answer: true, text: payload }]
      }
    })

    builder.addCase(getChats.fulfilled, (state, { payload }) => {
      // пока что сохраняем историю единственного чата
      state.chats = payload.chats
      state.waiting = false
    })
    builder.addCase(getChats.pending, (state, { payload }) => {
      // пока что сохраняем историю единственного чата
      state.waiting = true
    })

    // редьюсер и поделиться чатом (этап загрузки)
    builder.addCase(sharePublicChatIdById.pending, (state, { payload }) => {
      state.waiting = true
    })
    // редьюсер и поделиться чатом (получение айди чата, который сделали публичным)
    builder.addCase(sharePublicChatIdById.fulfilled, (state, { payload }) => {
      state.publicChatId = payload.data
      state.waiting = false
    })
    // редьюсер и поделиться чатом (ошибка)
    builder.addCase(sharePublicChatIdById.rejected, (state, { payload }) => {
      state.waiting = false
    })
    // редьюсер получения публичного чата (этап загрузки)
    builder.addCase(getPublicChatById.pending, (state, { payload }) => {
      state.waiting = true
    })
    // редьюсер получения публичного чата (получение данных публичного чата)
    builder.addCase(getPublicChatById.fulfilled, (state, { payload }) => {
      state.publicChatId = payload.data
      state.waiting = false
    })
    // редьюсер получения публичного чата (ошибка)
    builder.addCase(getPublicChatById.rejected, (state, { payload }) => {
      state.waiting = false
    })

    builder.addCase(renameChatHistory.fulfilled, (state) => {
      state.waiting = false
    })
    builder.addCase(renameChatHistory.rejected, (state) => {
      state.waiting = false
    })

    builder.addCase(renameChatHistory.pending, (state) => {
      state.waiting = true
    })

    builder.addCase(deleteHistory.fulfilled, (state) => {
      state.waiting = false
    })

    builder.addCase(deleteHistory.pending, (state) => {
      state.waiting = true
    })

    builder.addCase(createChat.fulfilled, (state, { payload }) => {
      const { chatUid } = payload
      state.chatId = chatUid
    })
    builder.addCase(continueChat.fulfilled, (state, { payload }) => {
      state.continueChat = payload
    })

    // Rejected thunks
    builder.addCase(tokenRefresh.rejected, (state) => {
      localStorage.removeItem('JWT')
      state.role = 'guest'
    })

    builder.addCase(getAnonymousToken.rejected, (state, action) => {
      throw action.payload
    })

    builder.addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state) => {
        state.status = 'success'
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.status = 'loading'
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state) => {
        state.status = 'rejected'
      },
    )
  },
})

export const {
  addChatId,
  addNumberTable,
  setDealerId,
  setUserDataForConfirm,
  setRole,
  setStatus,
  setUserEmail,
  setIsVerified,
  setRequestToAlfAssistant,
  setChatHistoryUpdate,
  deleteLastAlfMessage,
  deleteDebugInfo,
  beginStreamAnswer,
  setGeneratedPartData,
  setGeneratedError,
  finishStreamAnswer,
  setDebugId,
  selectChat,
  createNewChat,
} = userSlice.actions

export default userSlice.reducer
