import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as ExtensionIcon } from '../../../../assets/icons/extension-icon.svg'
import { ReactComponent as StarIcon } from '../../../../assets/icons/star-icon.svg'

const { REACT_APP_EXTENSION_ID } = process.env

declare const browser: any
declare const chrome: any

const ExtensionLinkBtn = () => {
  const [isInstalled, setIsInstalled] = useState(false)

  const checkIsInstalled = async () => {
    try {
      const message = { type: 'isInstalled' }
      const browserAPI = typeof chrome !== 'undefined' ? chrome : browser
      const response = await browserAPI.runtime.sendMessage(REACT_APP_EXTENSION_ID, message)
      setIsInstalled(true)
    } catch (error) {
      console.log('extention not installed', error)
    }
  }

  useEffect(() => {
    checkIsInstalled()
  }, [])

  return (
    <div className={styles.extensionLinkBtn__сontainer}>
      <h3 className={styles.extensionLinkBtn__title}>Расширьте возможности сервиса</h3>
      <a href='https://clck.ru/35Bb9W' target='blank'>
        {isInstalled ? (
          <button className={styles.extensionLinkBtn}>
            <StarIcon />
            Оцените нас на 5 звезд
          </button>
        ) : (
          <button className={styles.extensionLinkBtn}>
            <ExtensionIcon />
            Установить расширение
          </button>
        )}
      </a>
    </div>
  )
}

export default ExtensionLinkBtn
