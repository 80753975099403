import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'
import { apiTypes } from 'pages/tablePage/helpers/apiTypes'
import { IStoryboardData } from '../helpers/types'

// Можно получать данные по конкретному id, также можно получать сториборд текущего проекта по id 0
export const getStoryboard = createAsyncThunk(
  'storyboard/getStoryboard',
  async (data: apiTypes.CurrentStoryboard, { rejectWithValue }) => {
    try {
      const response = await api.get<IStoryboardData>(
        `projects/${data.projectId}/storyboards/${data.storyboardId || 0}/`,
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },
)

export const addNewColumn = createAsyncThunk(
  'storyboard/addNewColumn',
  async (data: apiTypes.ColumnAdd, { rejectWithValue }) => {
    try {
      const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/usercolumns/`
      const response = await api.post(url, data.newColumn)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const changeExistColumn = createAsyncThunk(
  'storyboard/changeExistColumn',
  async (data: apiTypes.ColumnChange) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/usercolumns/${data.columnId}/`
    const response = await api.patch(url, data.newColumn)
    return response.data
  },
)

export const changeExistUserfield = createAsyncThunk(
  'storyboard/changeExistUserfield',
  async (data: apiTypes.UserfieldChange) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/`
    const response = await api.patch(url, data.newUserfield)
    return response.data
  },
)

export const uploadImage = createAsyncThunk('storyboard/uploadImage', async (data: apiTypes.UploadImage) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/userfields/${data.userfieldId}/`
  const response = await api.patch(url, data.images)
  return response.data
})

// add audio
export const uploadAudio = createAsyncThunk('storyboard/uploadAudio', async (data: any) => {
  const url = `save_audio/`
  const response = await api.post(url, { ...data })
  return response.data
})

export const deleteImage = createAsyncThunk('storyboard/deleteImage', async (data: apiTypes.DeleteImage) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/userfields/${data.userfieldId}/images/${data.imageId}/`
  const response = await api.delete(url)
  return response.data
})

export const removeColumn = createAsyncThunk(
  'storyboard/removeColumn',
  async (data: apiTypes.ColumnRemove, { rejectWithValue }) => {
    try {
      const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/usercolumns/${data.columnId}/`
      await api.delete(url)
      return data.columnId
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const addNewFrame = createAsyncThunk(
  'storyboard/addNewFrame',
  async (data: apiTypes.FrameAdd, { rejectWithValue }) => {
    try {
      const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/`
      const response = await api.post(url, data.newFrame)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const removeFrame = createAsyncThunk('storyboard/removeFrame', async (data: apiTypes.FrameRemove) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/`
  await api.delete(url)
  return data.frameId
})

export const reorderFrames = createAsyncThunk(
  'storyboard/reorderFrames',
  async (data: apiTypes.FramesReorder, { rejectWithValue }) => {
    try {
      const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/`
      const response = await api.patch(url, data.newOrder)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const postShots = createAsyncThunk('storyboard/postShots', async (data: apiTypes.PostShots) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/shots/`
  const response = await api.post(url, data.files)
  return response.data
})

export const deleteShot = createAsyncThunk('storyboard/deleteShot', async (data: apiTypes.DeleteShots) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/shots/${data.shotId}/`
  const response = await api.delete(url)
  return response.data
})

export const addChrono = createAsyncThunk('storyboard/addChrono', async (data: apiTypes.AddChrono) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/chronos/`
  const response = await api.post(url, { name: data.name })
  return response.data
})

export const deleteChrono = createAsyncThunk('storyboard/deleteChrono', async (data: apiTypes.DeleteChrono) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/chronos/${data.chronoId}/`
  const response = await api.delete(url)
  return response.data
})

export const editChrono = createAsyncThunk('storyboard/editChrono', async (data: apiTypes.EditChrono) => {
  const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/chronos/${data.chronoId}/`
  const response = await api.patch(url, { name: data.name })
  return response.data
})

export const addHostFramesToChrono = createAsyncThunk(
  'storyboard/addHostFramesToChrono',
  async (data: apiTypes.AddHostFramesToChrono) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/chronos/${data.chronoId}/`
    const response = await api.patch(url, { frames: data.frames })
    return response.data
  },
)

export const removeHostFramesFromChrono = createAsyncThunk(
  'storyboard/removeHostFramesFromChrono',
  async (data: apiTypes.RemoveHostFramesToChrono) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/chronos/${data.chronoId}/frames/${data.frameId}/`
    await api.delete(url)
    return { frameId: data.frameId, chronoId: data.chronoId }
  },
)

export const reorderChronoFrames = createAsyncThunk(
  'storyboard/reorderChronoFrames',
  async (data: apiTypes.ChronoFramesReorder) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/chronos/${data.chronoId}/`
    const response = await api.patch(url, data.newOrder)
    return response.data
  },
)

export const changeStoryboard = createAsyncThunk(
  'storyboard/changeStoryboard',
  async (data: apiTypes.ChangeStoryboardNameOrDesc) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/`
    const response = await api.patch(url, data.newData)
    return response.data
  },
)

export const changeFrameName = createAsyncThunk(
  'storyboard/changeFrameDesc',
  async (data: apiTypes.ChangeFrameName) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/`
    const response = await api.patch(url, data.newName)
    return response.data
  },
)

export const changeStoryboardFrameDuration = createAsyncThunk(
  'storyboard/changeStoryboardFrameDuration',
  async (data: apiTypes.changeFrameDuration) => {
    const url = `projects/${data.projectId}/storyboards/${data.storyboardId}/frames/${data.frameId}/`
    const response = await api.patch(url, data.newDuration)
    return response.data
  },
)

export const getLikes = createAsyncThunk('storyboard/getLikes', async () => {
  try {
    const url = `auth/profile/likes/`
    const response = await api.get(url)
    return response.data
  } catch (error) {
    return new Error(error.message)
  }
})

export const setLike = createAsyncThunk('storyboard/setLike', async (data: apiTypes.isLike, { rejectWithValue }) => {
  try {
    const url = `auth/profile/likes/`
    const response = await api.patch(url, data.isLike)
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const postNewBordomatic = createAsyncThunk('storyboard/postNewBordomatic', async (data: any) => {
  const response = await api.post<any>(`bordomatic/`, data)
  return response.data
})

export const storyboardSlice = createSlice({
  name: 'storyboard',
  initialState: {
    storyboardData: {} as IStoryboardData,
    currentUnit: [],
    currentUnitName: '',
  },
  reducers: {
    setStoryboardData(state, { payload }) {
      state.storyboardData = { ...state.storyboardData, ...payload }
    },
    setFrames(state, { payload }) {
      state.storyboardData.frames = payload
    },
    setHeaders(state, { payload }) {
      state.storyboardData.frameColumns = payload
    },
    setCurrentUnit(state, { payload }) {
      state.currentUnit = payload
    },
    setCurrentUnitName(state, { payload }) {
      state.currentUnitName = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeExistUserfield.fulfilled, (state, { payload }: any) => {
      state.storyboardData = payload
    })
    builder.addCase(getStoryboard.fulfilled, (state, { payload }: any) => {
      state.storyboardData = payload
      state.currentUnit = payload.frames
    })
    builder.addCase(getStoryboard.rejected, (state, { payload }: any) => {
      throw payload
    })
    builder.addCase(changeStoryboard.fulfilled, (state, { payload }: any) => {
      state.storyboardData = payload
    })
    builder.addCase(changeStoryboard.rejected, (state, { payload }: any) => {
      throw payload
    })
    builder.addCase(removeColumn.fulfilled, (state, { payload }) => {
      const copyColumns = JSON.parse(JSON.stringify(state.storyboardData.frameColumns))
      const newColumns = copyColumns.filter((column) => {
        return column.columnId !== payload
      })
      state.storyboardData.frameColumns = newColumns
    })
    builder.addCase(removeColumn.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(changeExistColumn.fulfilled, (state, { payload }: any) => {
      const newColumns = state.storyboardData.frameColumns.map((column) => {
        if (column.columnId !== payload.columnId) {
          return column
        } else {
          return payload
        }
      })
      state.storyboardData.frameColumns = newColumns
    })
    builder.addCase(removeFrame.fulfilled, (state, { payload }) => {
      const copyFrames = JSON.parse(JSON.stringify(state.storyboardData.frames))
      const framesWithoutDeleted = copyFrames.filter((frame) => frame.id !== payload)
      state.storyboardData.frames = framesWithoutDeleted
      const copyChronos = JSON.parse(JSON.stringify(state.storyboardData.chronos))
      const newChronos = copyChronos.map((chrono) => {
        const filteredChronoFrames = chrono.frames.filter((frame) => frame.sbdframe.id !== payload)
        chrono.frames = filteredChronoFrames
        return chrono
      })
      state.storyboardData.chronos = newChronos
    })
    builder.addCase(removeHostFramesFromChrono.fulfilled, (state, { payload }) => {
      state.storyboardData.chronos = state.storyboardData.chronos.map((chrono) => {
        if (chrono.id === payload.chronoId) {
          chrono.frames = chrono.frames.filter((frame) => frame.id !== payload.frameId)
          return chrono
        } else {
          return chrono
        }
      })
    })
    builder.addCase(addNewFrame.fulfilled, (state, { payload }) => {
      const copyFrames = JSON.parse(JSON.stringify(state.storyboardData.frames))
      state.storyboardData.frames = [...copyFrames, payload]
    })
    builder.addCase(addNewFrame.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(reorderFrames.fulfilled, (state, { payload }: any) => {
      state.storyboardData = payload
    })
    builder.addCase(reorderFrames.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(postShots.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(deleteShot.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(changeFrameName.fulfilled, (state, { payload }: any) => {
      const newFrames = state.storyboardData.frames.map((frame) => {
        if (frame.id !== payload.id) {
          return frame
        } else {
          return payload
        }
      })
      state.storyboardData.frames = newFrames
    })
    builder.addCase(changeStoryboardFrameDuration.fulfilled, (state, { payload }: any) => {
      const copyFrames = JSON.parse(JSON.stringify(state.storyboardData.frames))
      const editedFrames = copyFrames.map((frame) => {
        if (frame.id !== payload.id) {
          return frame
        } else {
          return payload
        }
      })
      state.storyboardData.frames = editedFrames
    })
    builder.addCase(getLikes.fulfilled, (state, { payload }: any) => {
      state.storyboardData.likesCount = JSON.parse(payload.total)
    })
    builder.addCase(getLikes.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
  },
})

export const { setStoryboardData, setFrames, setHeaders, setCurrentUnit, setCurrentUnitName } = storyboardSlice.actions
export default storyboardSlice.reducer
