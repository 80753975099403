import { api } from 'app/api'
import { createEffect, createEvent, createStore } from 'effector'
import { createGate } from 'effector-react'
import { debounce } from 'patronum/debounce'
import { debug } from 'patronum/debug'
import type { CommandInfo, CommandList, CommandRequestResultWrapper, FavoriteInfo, SearchCommandsProps } from './model'

export const $search = createStore('')

export const searchChanged = createEvent<string>()
export const performSearch = debounce({ source: searchChanged, timeout: 500 })
// export const nullResultOnStartSearch = performSearch.prepend((v) => '')
// debug(performSearch, nullResultOnStartSearch)

export const $page = createStore(0)

export const searchFx = createEffect<SearchCommandsProps, CommandList, Error>(async ({ search, page, pageSize }) => {
  const res = await api.get<CommandRequestResultWrapper>('catalog_template/', {
    name: search,
    page,
    page_size: pageSize,
  })
  if (res.status === 200) {
    return res.data.results
  } else {
    throw Error('Error in command catalog')
  }
})

export const $commandsLoading = searchFx.pending.map((pending) => pending)

export const switchFavorite = createEvent<FavoriteInfo>()
export const switchFavoriteFx = createEffect<FavoriteInfo, boolean, Error>(async ({ favourite, id }) => {
  const res = await api.patch<CommandInfo>(`catalog_template/${id}/`, { favourite })
  if (res.status === 200) {
    return res.data.favourite
  } else {
    throw Error('Error in favorite switch')
  }
})

export const $searchingCommands = searchFx.pending.map((pending) => pending)
export const $findedCommands = createStore<CommandList>([])
export const $findedCommandsCount = $findedCommands.map((data) => data.length)

export const tryLoadNextPage = createEvent()
// export const updatePageNumber = tryLoadNextPage.map((p) => p)
export const updatePageNumber = createEvent()

export const SearchCommandGate = createGate()
