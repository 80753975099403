/* eslint-disable import/namespace */
/* eslint-disable import/default */
/* eslint-disable import/no-named-as-default-member */
import { configureStore } from '@reduxjs/toolkit'
import allProjects from '../../pages/disk/redux/allProjects'
import userSlice from './userSlice'
import inputEvent from 'components/Input/redux/inputEvent'
import calendarSlice from 'components/Calendar/redux/CalendarSlice'
import projectSlice from '../../pages/disk/redux/projectSlice'
import paymentSlice from 'components/Payment/redux/paymentSlice'
import basketSlice from '../../components/Basket/redux/basketSlice'
import storyboardSlice from '../../pages/tablePage/redux/storyboardSlicer'
import documentsSlice from '../../pages/document/redux/documentSlice'
import generatorSlice from '../../components/Editor/ui/AIGenPanel/redux/generatorSlice'
import commandSlice from '../../components/Editor/redux/commandEditorSlice'
import modalPositionSlice from '../../components/Editor/redux/modalPositionSlice'
import contactSlice from './contactSlice'
import diskSpaceSlice from './diskSpaceSlice'
import tabHeaderDiskSlice from './tabHeaderDiskSlice'
import planSlice from './planSlice'
import checklistSlice from 'pages/checkList/redux/checklistSlice'
import testSlice from 'pages/test/redux/testSlise'
import questionSlice from 'pages/test/redux/questionSlice'
import activityStateSlice from 'pages/test/redux/activityStateSlice'
import isActiveFinalScreenSlice from 'pages/test/redux/isActiveFinalScreen'
import { alfAssistant } from './alfAssistantSlice'
import trackerSlice from 'pages/checkList/redux/trackerSlice'
import diskSlice from 'pages/disk/redux/diskSlice'
import listNodesSlice from 'components/Editor/redux/listNodesSlice'

export const store = configureStore({
  reducer: {
    isActiveFinalScreen: isActiveFinalScreenSlice,
    activityStateModal: activityStateSlice,
    question: questionSlice,
    test: testSlice,
    allProjects,
    user: userSlice,
    alfAssistant,
    input: inputEvent,
    calendar: calendarSlice,
    basket: basketSlice,
    project: projectSlice,
    payment: paymentSlice,
    document: documentsSlice,
    storyboard: storyboardSlice,
    contact: contactSlice,
    disk: diskSpaceSlice,
    checklist: checklistSlice,
    textGenerator: generatorSlice,
    commands: commandSlice,
    diskPage: diskSlice,
    tabIndex: tabHeaderDiskSlice,
    modalPosition: modalPositionSlice,
    tracker: trackerSlice,
    plan: planSlice,
    listNodes: listNodesSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
