import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { ALFAssistantMobileChatHeader } from './components/ALFAssistantMobileChatHeader/ALFAssistantMobileChatHeader'
import { ALFAssistantMobileChatFooter } from './components/ALFAssistantMobileChatFooter/ALFAssistantMobileChatFooter'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  deleteLastAlfMessage,
  setRequestToAlfAssistant,
  sendToAlfAssistantMessageStream,
  continueChat,
  getChats,
  createChat,
  deleteDebugInfo,
  setGeneratedError,
  createNewChat,
  deleteHistory,
  renameChatHistory,
  selectChat,
} from 'app/redux/userSlice'
import { useScroll } from 'helpers/useScroll'
import { ALFAssistantMobileMessageItem } from './components/ALFAssistantMobileMessageItem/ALFAssistantMobileMessageItem'
import { createNewCommand } from 'components/Editor/redux/commandEditorSlice'
import { setIsEditedCommand, setIsOpenAlfChat } from 'app/redux/alfAssistantSlice'
import { removeLastSlash } from 'helpers/removeLastSlash'
import { shallowEqual } from 'react-redux'
import { ALFMobileCommandMenu } from './components/ALFMobileCommandMenu/ALFMobileCommandMenu'
import { toast } from 'react-toastify'
import AlfAssistantVariablesMenu from 'components/AlfAssistantModal/components/AlfAssistantFooter/components/AlfAssistantVariablesMenu/AlfAssistantVariablesMenu'
export const ALFAssistantMobileChat = () => {
  const dispatch = useAppDispatch()
  const chats = useAppSelector((state) => state.user.chats)
  const { debugInfo, debugId, chatHistory, chatId, chat, waiting } = useAppSelector((state) => state.user, shallowEqual)
  const currentChat = [...chatHistory, ...chat]
  const { isOpenAlfChat } = useAppSelector((state) => state.alfAssistant)
  const [inputValue, setInputValue] = React.useState('')
  const [isPending, setIsPending] = React.useState(false)
  const [visibleMenu, setVisibleMenu] = React.useState(false)
  const [visibleVariablesMenu, setVisibleVariablesMenu] = React.useState(false)
  const [visibleCommandMenu, setVisibleCommandMenu] = React.useState(false)
  const [cursorPosition, setCursorPosition] = React.useState(0)
  const [menuContent, setMenuContent] = React.useState('')
  const [searchTerm, setSearchTerm] = React.useState('')
  const ref = useScroll([chat])
  const initialFooterHeight = 56
  const menuRef = React.useRef(null)
  const footerRef = React.useRef(null)
  const mainRef = React.useRef(null)
  const wrapperRef = React.useRef(null)
  const [footerHeight, setFooterHeight] = React.useState<number | null>(initialFooterHeight)
  const [menuHeight, setMenuHeight] = React.useState<number>(0)
  const [openHistoryModal, setOpenHistoryModal] = React.useState(false)
  const { currentAssistantId } = useAppSelector((state) => state.alfAssistant)
  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const { value } = e.target
    setInputValue(value)
  }
  const filteredChats = chats.filter(
    (chat) =>
      chat.chatHistory.some((message) => message.content?.toLowerCase().includes(searchTerm.toLowerCase())) ||
      chat.chatName.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const changeScrollHeigt = React.useCallback(() => {
    if (visibleMenu) {
      setMenuHeight(menuRef.current.getBoundingClientRect().height)
    }
    if (!visibleMenu) {
      setMenuHeight(0)
    }
  }, [visibleMenu, setMenuHeight])

  React.useEffect(() => {
    if (inputValue === '') {
      setVisibleMenu(false)
    }
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [inputValue, ref, visibleMenu])
  const abortController = new AbortController()
  const canselRequest = () => {
    dispatch(deleteLastAlfMessage())
    abortController.abort()
    setIsPending(false)
    setInputValue('')
  }
  const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (e) => {
    if (e.key === 'Escape') {
      canselRequest()
    }
  }
  const handleKeyPress = (event) => {
    if (!event.shiftKey && !event.ctrlKey && event.key === 'Enter') {
      handleSendMessage()
    }
    if (event.metaKey && event.key === 'Enter') {
      handleSendMessage()
    }
  }

  const handleCtrlAndEnterPress = (event) => {
    if (event.ctrlKey && event.key === 'Enter') {
      event.target.value = event.target.value + '\n'
      setInputValue(event.target.value)
    }
  }
  const handleOpenCommandMenu = () => {
    inputValue.lastIndexOf('/') === -1 ? setInputValue(inputValue + '/') : setInputValue(removeLastSlash(inputValue))
    changeScrollHeigt()
  }
  React.useEffect(() => {
    if (debugInfo) {
      const errorMessage = debugInfo.debug_info.error_details.message
      dispatch(setGeneratedError({ id: debugId, error: errorMessage }))
      toast.error(errorMessage)
      dispatch(deleteDebugInfo())
    }
  }, [debugId, debugInfo, dispatch])
  const handleSendMessage = () => {
    setIsPending(true)
    dispatch(deleteDebugInfo())
    if (chatId) {
      dispatch(continueChat({ source: inputValue, chatUid: chatId }))
      // dispatch(getChats())
    } else {
      dispatch(createChat({ source: inputValue }))
    }
    dispatch(setRequestToAlfAssistant(inputValue))
    dispatch(sendToAlfAssistantMessageStream({ source: inputValue, signal: abortController.signal })).then(() => {
      setIsPending(false)
    })

    setInputValue('')
  }
  const handleApplyCommand = (value: string) => {
    const indexOfSearchSymbolBeforeCursor = inputValue.lastIndexOf(visibleVariablesMenu ? '@' : '/', cursorPosition)
    const newInputValue =
      inputValue.slice(0, indexOfSearchSymbolBeforeCursor) + value + inputValue.slice(cursorPosition)
    setInputValue(newInputValue)
    trackCursorPositionInInput(0)
  }
  React.useEffect(() => {
    setTimeout(() => {
      if (wrapperRef.current) {
        setMenuHeight(wrapperRef.current.getBoundingClientRect().height)
      }
      if (footerRef.current) {
        setFooterHeight(footerRef.current.getBoundingClientRect().height)
      }
      if (menuRef.current) {
        setMenuHeight(menuRef.current.getBoundingClientRect().height)
      }
    }, 500)
  }, [inputValue, footerRef])
  const handleCreateCommandFromText = React.useCallback(() => {
    dispatch(createNewCommand(inputValue))
    dispatch(setIsOpenAlfChat(false))
    dispatch(setIsEditedCommand(true))
  }, [dispatch, createNewCommand, inputValue])

  React.useEffect(() => {
    window.addEventListener('keydown', handleCtrlAndEnterPress)
    return () => window.removeEventListener('keydown', handleCtrlAndEnterPress)
  }, [])
  const processCursorPosition = (searchSymbol: string, cursorPosition: number) => {
    const indexOfSearchSymbolBeforeCursor = inputValue.lastIndexOf(searchSymbol, cursorPosition - 1)
    if (
      indexOfSearchSymbolBeforeCursor !== -1 &&
      (inputValue[indexOfSearchSymbolBeforeCursor - 1] === ' ' ||
        inputValue[indexOfSearchSymbolBeforeCursor - 1] === '\n' ||
        indexOfSearchSymbolBeforeCursor === 0)
    ) {
      const charsBeforeCursor = inputValue.substring(indexOfSearchSymbolBeforeCursor + 1, cursorPosition)
      if (!charsBeforeCursor.includes(' ') && !charsBeforeCursor.includes('\n')) {
        setMenuContent(charsBeforeCursor)
        return true
      }
    }
    setMenuContent('')
    return false
  }
  const trackCursorPositionInInput = (cursorPosition: number) => {
    const commandMenuBool = processCursorPosition('/', cursorPosition)
    const variablesMenuBool = processCursorPosition('@', cursorPosition)
    setVisibleVariablesMenu(variablesMenuBool)
    setVisibleCommandMenu(commandMenuBool)
    setVisibleMenu(commandMenuBool || variablesMenuBool)
    setCursorPosition(cursorPosition)
  }
  const callbacks = {
    renameChatHistory: React.useCallback(
      (data) => {
        dispatch(renameChatHistory(data))
          .unwrap()
          .catch(() => {
            if (data.newName.length > 128) {
              toast.error('Убедитесь, что это новое имя содержит не более 128 символов')
            }
          })
          .then(() => dispatch(getChats(currentAssistantId)))
      },
      [dispatch],
    ),
    handleOpenHistoryModal: React.useCallback(() => {
      dispatch(getChats(currentAssistantId))
      setOpenHistoryModal(true)
    }, [dispatch]),
    handleCreateNewChat: React.useCallback(() => {
      dispatch(createNewChat())
    }, [dispatch]),
    handleCloseHistoryModal: React.useCallback(() => {
      setSearchTerm('')
      setOpenHistoryModal(false)
    }, []),
    deleteHistory: React.useCallback(
      (id) => {
        dispatch(deleteHistory(id))
          .then(() => dispatch(getChats(currentAssistantId)))
          .then(() =>
            toast.info('Переписка удалена', {
              icon: false,
              position: 'bottom-center',
              progressClassName: styles.progressBar,
              style: {
                backgroundColor: '#FFFFFF',
                color: '#2F3136',
              },
            }),
          )
      },
      [dispatch],
    ),
    selectChatHistory: React.useCallback(
      (id) => {
        dispatch(selectChat(id))
        changeScrollHeigt()
      },
      [changeScrollHeigt, dispatch],
    ),
    handleSearch: React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value)
    }, []),
    handleCreateCommandFromMessage: React.useCallback(
      (content: string) => {
        dispatch(createNewCommand(content))
        dispatch(setIsOpenAlfChat(false))
        dispatch(setIsEditedCommand(true))
      },
      [dispatch],
    ),
  }
  return (
    <main className={styles.main} ref={mainRef}>
      <ALFAssistantMobileChatHeader />
      <section className={styles.scroll} ref={ref} style={{ bottom: footerHeight + menuHeight }}>
        <section
          className={styles.section}
          style={{ paddingBottom: visibleMenu ? menuHeight + footerHeight - 48 : footerHeight - 48 }}
        >
          {currentChat.map((message, index) => {
            return (
              <ALFAssistantMobileMessageItem
                key={message.id}
                message={message.text}
                answer={message.answer}
                id={message.id}
                index={index}
                handleCreateCommandFromMessage={callbacks.handleCreateCommandFromMessage}
              />
            )
          })}
        </section>
      </section>
      {visibleMenu && (
        <div className={styles.wrapper} style={{ bottom: footerHeight }} ref={wrapperRef}>
          {visibleCommandMenu && (
            <ALFMobileCommandMenu
              ref={menuRef}
              handleApplyCommand={handleApplyCommand}
              handleCreateCommandFromText={handleCreateCommandFromText}
              inputValue={inputValue}
            />
          )}
          {visibleVariablesMenu && (
            <AlfAssistantVariablesMenu
              onSelectVariable={handleApplyCommand}
              textToFilter={menuContent}
              inputValue={inputValue}
            />
          )}
        </div>
      )}
      {isOpenAlfChat && (
        <ALFAssistantMobileChatFooter
          ref={footerRef}
          canselRequest={canselRequest}
          inputValue={inputValue}
          isPending={isPending}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          handleKeyPress={handleKeyPress}
          handleSendMessage={handleSendMessage}
          handleOpenCommandMenu={handleOpenCommandMenu}
          handleCreateNewChat={callbacks.handleCreateNewChat}
          handleOpenHistoryModal={callbacks.handleOpenHistoryModal}
          chats={filteredChats}
          openHistoryModal={openHistoryModal}
          handleSearch={callbacks.handleSearch}
          searchTerm={searchTerm}
          selectChatHistory={callbacks.selectChatHistory}
          handleCloseHistoryModal={callbacks.handleCloseHistoryModal}
          chatId={chatId}
          deleteHistory={callbacks.deleteHistory}
          renameChatHistory={callbacks.renameChatHistory}
          waiting={waiting}
          handleInputEvent={trackCursorPositionInInput}
        />
      )}
    </main>
  )
}
