import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface GeneratorProps {
  isCustomGenerator: boolean
  isImageGenerator: boolean
  generatedTexts: Array<string>
}

const initialState: GeneratorProps = {
  isCustomGenerator: false,
  isImageGenerator: false,
  generatedTexts: [],
}

export const generatorSlice = createSlice({
  name: 'generatorSlice',
  initialState,
  reducers: {
    showCustomGeneratorPanel(state) {
      state.isCustomGenerator = true
    },
    hideCustomGeneratorPanel(state) {
      state.isCustomGenerator = false
    },
    addGeneratedToHistory(state, { payload }: PayloadAction<string>) {
      state.generatedTexts = [...state.generatedTexts, payload]
    },
    showImageGenerator(state) {
      state.isImageGenerator = true
    },
    hideImageGenerator(state) {
      state.isImageGenerator = false
    },
  },
})

export const {
  showCustomGeneratorPanel,
  hideCustomGeneratorPanel,
  addGeneratedToHistory,
  showImageGenerator,
  hideImageGenerator,
} = generatorSlice.actions

export default generatorSlice.reducer
