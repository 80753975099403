import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { AlfAssistantFooterInput } from './components/AlfAssistantFooterInput/AlfAssistantFooterInput'
import { AlfAssistantMockElement } from './components/AlfAssistantMockElement/AlfAssistantMockElement'
import { toast } from 'react-toastify'
import {
  // continueChat,
  deleteLastAlfMessage,
  deleteDebugInfo,
  sendToAlfAssistantMessageStream,
  setRequestToAlfAssistant,
  setGeneratedError,
  deleteHistory,
  renameChatHistory,
  getChats,
  // createChat,
  createNewChat,
  selectChat,
  sharePublicChatIdById,
} from 'app/redux/userSlice'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { AlfAssistantCommandMenu } from '../AlfAssisstantCommandMenu/AlfAssistantCommandMenu'
import { createNewCommand } from 'components/Editor/redux/commandEditorSlice'
import {
  setIsEditedCommand,
  setIsOpenAlfChat,
  setHistoryShareModalLinkValue,
  setOpenHistoryShareModal,
  setIsOpenAssistantSettings,
} from 'app/redux/alfAssistantSlice'
import { shallowEqual } from 'react-redux'
import { ButtonBlock } from './components/ButtonBlock/ButtonBlock'
import { AlfAssistantHistoryModal } from './components/AlfAssistantHistoryModal/AlfAssistantHistoryModal'
import AlfAssistantVariablesMenu from './components/AlfAssistantVariablesMenu/AlfAssistantVariablesMenu'
import AlfAssistantChatHistoryShareModal from '../AlfAssistantChatHistoryShareModal/AlfAssistantChatHistoryShareModal'
import { AlfAssistantSettings } from './components/AlfAssistantSettings/AlfAssistantSettings'
export interface Message {
  id: number
  answer: boolean
  text: string
}
export type Chat = Message[]

export const AlfAssistantFooter = () => {
  const dispatch = useAppDispatch()
  const chats = useAppSelector((state) => state.user.chats)
  const { debugInfo, debugId, chatHistory, chatId, chat, waiting } = useAppSelector((state) => state.user, shallowEqual)
  const { currentAssistantId } = useAppSelector((state) => state.alfAssistant)
  const currentChat = [...chatHistory, ...chat]
  const [inputValue, setInputValue] = React.useState('')
  const [isPending, setIsPending] = React.useState(false)
  const [visibleMenu, setVisibleMenu] = React.useState(false)
  const [visibleVariablesMenu, setVisibleVariablesMenu] = React.useState(false)
  const [visibleCommandMenu, setVisibleCommandMenu] = React.useState(false)
  const [cursorPosition, setCursorPosition] = React.useState(0)
  const [menuContent, setMenuContent] = React.useState('')
  const [openHistoryModal, setOpenHistoryModal] = React.useState(false)
  const isOpenAssistantSettings = useAppSelector((state) => state.alfAssistant.isOpenAssistantSettings)
  // состояние для открытия и закрытия модального окна, где можно поделиться историей переписки
  const isOpenHistoryShareModal = useAppSelector((store) => store.alfAssistant.isOpenHistoryShareModal)

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const { value } = e.target
    setInputValue(value)
  }
  const [searchTerm, setSearchTerm] = React.useState('')
  const filteredChats = chats.filter(
    (chat) =>
      chat.chatHistory.some((message) => message.content?.toLowerCase().includes(searchTerm.toLowerCase())) ||
      chat.chatName.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  React.useEffect(() => {
    const handleEscapeKeyCheck = (ev: KeyboardEvent) => {
      if (ev.code === 'Escape') {
        canselRequest()
      }
    }
    document.addEventListener('keydown', handleEscapeKeyCheck)
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyCheck)
    }
  }, [])

  React.useEffect(() => {
    if (debugInfo) {
      const errorMessage = debugInfo.debug_info.error_details.message
      dispatch(setGeneratedError({ id: debugId, error: errorMessage }))
      toast.error(errorMessage)
      dispatch(deleteDebugInfo())
    }
  }, [debugId, debugInfo, dispatch])

  const abortController = new AbortController()
  const canselRequest = () => {
    dispatch(deleteLastAlfMessage())
    abortController.abort()
    setIsPending(false)
    setInputValue('')
  }
  const handleApplyCommand = (value: string) => {
    const indexOfSearchSymbolBeforeCursor = inputValue.lastIndexOf(visibleVariablesMenu ? '@' : '/', cursorPosition)
    const newInputValue =
      inputValue.slice(0, indexOfSearchSymbolBeforeCursor) + value + inputValue.slice(cursorPosition)
    setInputValue(newInputValue)
    trackCursorPositionInInput(0)
  }

  const processCursorPosition = (searchSymbol: string, cursorPosition: number) => {
    const indexOfSearchSymbolBeforeCursor = inputValue.lastIndexOf(searchSymbol, cursorPosition - 1)
    if (
      indexOfSearchSymbolBeforeCursor !== -1 &&
      (inputValue[indexOfSearchSymbolBeforeCursor - 1] === ' ' ||
        inputValue[indexOfSearchSymbolBeforeCursor - 1] === '\n' ||
        indexOfSearchSymbolBeforeCursor === 0)
    ) {
      const charsBeforeCursor = inputValue.substring(indexOfSearchSymbolBeforeCursor + 1, cursorPosition)
      if (!charsBeforeCursor.includes(' ') && !charsBeforeCursor.includes('\n')) {
        setMenuContent(charsBeforeCursor)
        return true
      }
    }
    setMenuContent('')
    return false
  }
  const trackCursorPositionInInput = (cursorPosition: number) => {
    const commandMenuBool = processCursorPosition('/', cursorPosition)
    const variablesMenuBool = processCursorPosition('@', cursorPosition)
    setVisibleVariablesMenu(variablesMenuBool)
    setVisibleCommandMenu(commandMenuBool)
    setVisibleMenu(commandMenuBool || variablesMenuBool)
    setCursorPosition(cursorPosition)
  }
  const handleCtrlEndEnterPress = (event) => {
    if (event.ctrlKey && event.key === 'Enter') {
      event.target.value = event.target.value + '\n'
      setInputValue(event.target.value)
    }
  }

  const handleSendMessage = (inputValue: string) => {
    setIsPending(true)
    dispatch(deleteDebugInfo())
    // if (chatId) {
    //   dispatch(continueChat({ source: inputValue, chatUid: chatId, assistantId: currentAssistantId }))
    // } else {
    //   // const req = currentAssistantId ? { assistantId: currentAssistantId, source: inputValue } : { source: inputValue }
    //   dispatch(createChat({ assistantId: currentAssistantId, source: inputValue }))
    // }
    dispatch(setRequestToAlfAssistant(inputValue))
    // const req = currentAssistantId
    //   ? { assistantId: currentAssistantId, source: inputValue, signal: abortController.signal, chatUid: chatId }
    //   : { source: inputValue, signal: abortController.signal }
    dispatch(
      sendToAlfAssistantMessageStream({
        assistantId: currentAssistantId,
        source: inputValue,
        signal: abortController.signal,
        chatUid: chatId,
      }),
    ).then(() => {
      setIsPending(false)
      dispatch(getChats(currentAssistantId))
    })
    setInputValue('')
  }

  React.useEffect(() => {
    window.addEventListener('keydown', handleCtrlEndEnterPress)
    return () => {
      window.removeEventListener('keydown', handleCtrlEndEnterPress)
    }
  }, [])

  const handleCreateCommandFromText = React.useCallback(() => {
    dispatch(createNewCommand(inputValue))
    dispatch(setIsEditedCommand(true))
  }, [dispatch, inputValue])

  const callbacks = {
    renameChatHistory: React.useCallback(
      (data) => {
        dispatch(renameChatHistory(data))
          .unwrap()
          .catch(() => {
            if (data.newName.length > 128) {
              toast.error('Убедитесь, что это новое имя содержит не более 128 символов')
            }
          })
          .then(() => dispatch(getChats(currentAssistantId)))
      },
      [dispatch, currentAssistantId],
    ),

    handleOpenHistoryModal: React.useCallback(() => {
      dispatch(getChats(currentAssistantId))
      setOpenHistoryModal(true)
    }, [dispatch, currentAssistantId]),

    handleOpenSettingsModal: React.useCallback(() => {
      // dispatch(getChats(currentAssistantId))
      dispatch(setIsOpenAssistantSettings(true))
    }, [dispatch]),

    handleCreateNewChat: React.useCallback(() => {
      dispatch(createNewChat())
    }, [dispatch]),

    // хендлер открытия модалки где можно поделиться историей переписки
    handleOpenHistoryShareModal: React.useCallback(() => {
      dispatch(getChats(currentAssistantId)).then((res) => {
        dispatch(sharePublicChatIdById(res.payload.currentChat))
        dispatch(setHistoryShareModalLinkValue(`http://localhost:3000/history/${res.payload.currentChat}`))
        dispatch(setOpenHistoryShareModal(true))
      })
    }, [dispatch]),

    handleCloseHistoryModal: React.useCallback(() => {
      setSearchTerm('')
      setOpenHistoryModal(false)
    }, []),

    deleteHistory: React.useCallback(
      (id) => {
        dispatch(deleteHistory(id)).then(() => dispatch(getChats(currentAssistantId)))
      },
      [dispatch, currentAssistantId],
    ),
    selectChatHistory: React.useCallback(
      (id) => {
        dispatch(selectChat(id))
      },
      [dispatch],
    ),
    handleSearch: React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value)
    }, []),
    handleCreateCommandFromMessage: React.useCallback(
      (content) => {
        dispatch(createNewCommand(content))
        dispatch(setIsOpenAlfChat(false))
        dispatch(setIsEditedCommand(true))
      },
      [dispatch],
    ),
  }

  return (
    <div className={styles.container} id='footer'>
      <ButtonBlock
        handleOpenHistoryModal={callbacks.handleOpenHistoryModal}
        handleOpenSettingsModal={callbacks.handleOpenSettingsModal}
        handleCreateNewChat={callbacks.handleCreateNewChat}
        handleOpenHistoryShareModal={callbacks.handleOpenHistoryShareModal}
        currentChatLength={currentChat.length}
      />
      {openHistoryModal && (
        <AlfAssistantHistoryModal
          handleSearch={callbacks.handleSearch}
          searchTerm={searchTerm}
          selectChat={callbacks.selectChatHistory}
          handleCloseHistoryModal={callbacks.handleCloseHistoryModal}
          chats={filteredChats}
          chatId={chatId}
          deleteHistory={callbacks.deleteHistory}
          renameChatHistory={callbacks.renameChatHistory}
          waiting={waiting}
        />
      )}
      {isOpenAssistantSettings && <AlfAssistantSettings />}
      {!openHistoryModal && (
        <footer className={styles.footer}>
          <div className={`${styles.menuContainer} ${visibleMenu && styles.menuContainer__active}`}>
            {visibleCommandMenu && (
              <AlfAssistantCommandMenu
                handleApplyCommand={handleApplyCommand}
                handleCreateCommandFromText={handleCreateCommandFromText}
                inputValue={inputValue}
                isVisible={visibleCommandMenu}
              />
            )}
            {visibleVariablesMenu && (
              <AlfAssistantVariablesMenu
                onSelectVariable={handleApplyCommand}
                textToFilter={menuContent}
                inputValue={inputValue}
              />
            )}
          </div>
          {isPending ? (
            <AlfAssistantMockElement canselRequest={canselRequest} />
          ) : (
            <AlfAssistantFooterInput
              handleInputChange={handleInputChange}
              inputValue={inputValue}
              handleInputEvent={trackCursorPositionInInput}
              setInputValue={setInputValue}
              handleSendMessage={handleSendMessage}
            />
          )}
        </footer>
      )}
      {isOpenHistoryShareModal && <AlfAssistantChatHistoryShareModal />}
    </div>
  )
}
