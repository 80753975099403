import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { CloseIcon } from './components/CloseIcon'
import { Spinner } from 'components/Spinner'
interface RenameChatHistoryModalProps {
  renameChat: (data: { chatId: string; newName: string }) => void
  closeModal: () => void
  chatName: string
  chatId: string
  waiting: boolean
}
export const RenameChatHistoryModal = (props: RenameChatHistoryModalProps) => {
  const { renameChat, closeModal, chatName, chatId, waiting } = props
  const [newChatName, setNewChatName] = React.useState('')
  const [isWaiting, setIsWaiting] = React.useState(false)
  React.useEffect(() => {
    if (isWaiting) {
      if (!waiting) {
        closeModal()
        setIsWaiting(false)
      }
    }
  }, [closeModal, isWaiting, waiting])
  const callbacks = {
    onRenameChat: () => {
      if (newChatName.trim() !== '') {
        renameChat({ chatId, newName: newChatName })
        setIsWaiting(true)
        setNewChatName('')
      }
    },
    cancelRenameChat: () => {
      setNewChatName(chatName)
      closeModal()
    },
    onChange: (e: ChangeEvent<HTMLInputElement>) => setNewChatName(e.target.value),
  }
  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.modal}>
        {waiting ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={styles.wrapper}>
              <h4 className={styles.title}>Переименовать чат</h4>
              <button className={styles.button} onClick={closeModal}>
                <CloseIcon />
              </button>
            </div>
            <input
              className={styles.input}
              name='name'
              value={newChatName}
              placeholder={chatName}
              onChange={callbacks.onChange}
            />
            <div className={styles.buttonBlock}>
              <button className={styles.cancelButton} onClick={callbacks.cancelRenameChat}>
                Отменить
              </button>
              <button className={styles.renameButton} onClick={callbacks.onRenameChat}>
                Переименовать
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}
