import React from 'react'

const CloseIcon = () => {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.75 3.01367L3.75 9.01367'
        stroke='#95999E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.75 3.01367L9.75 9.01367'
        stroke='#95999E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CloseIcon
