import React from 'react'

export const AddIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.99922 3.19995L7.99922 12.8M12.7992 7.99995L3.19922 7.99995'
        stroke='#4BBDEA'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
