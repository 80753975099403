import React from 'react'

const Pencil = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <rect width='18' height='18' rx='4' />
      <path
        d='M14.7187 5.96269L12.0379 3.28126C11.9488 3.19209 11.8429 3.12136 11.7264 3.0731C11.6099 3.02484 11.4851 3 11.359 3C11.2329 3 11.1081 3.02484 10.9916 3.0731C10.8751 3.12136 10.7692 3.19209 10.6801 3.28126L3.28141 10.6805C3.19187 10.7694 3.12089 10.8751 3.07258 10.9916C3.02427 11.1081 2.9996 11.233 3 11.3592V14.0406C3 14.2952 3.10115 14.5394 3.28119 14.7194C3.46122 14.8995 3.70541 15.0006 3.96002 15.0006H6.64145C6.76756 15.001 6.8925 14.9763 7.009 14.928C7.1255 14.8797 7.23123 14.8087 7.32006 14.7192L14.7187 7.32051C14.8079 7.23136 14.8786 7.12552 14.9269 7.00903C14.9752 6.89254 15 6.76769 15 6.6416C15 6.51551 14.9752 6.39065 14.9269 6.27417C14.8786 6.15768 14.8079 6.05184 14.7187 5.96269ZM6.64145 14.0406H3.96002V11.3592L9.24008 6.07909L11.9215 8.76052L6.64145 14.0406ZM12.6001 8.08131L9.91869 5.40048L11.3587 3.96047L14.0401 6.6413L12.6001 8.08131Z'
        fill='white'
      />
    </svg>
  )
}

export default Pencil
