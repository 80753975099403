import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { ModalCommandCard } from 'components/Editor/ui/ModalCommandCard/ModalCommandCard'
import {
  closeAICommandEditorPanel,
  deleteCommand,
  saveEditingCommandInfo,
} from 'components/Editor/redux/commandEditorSlice'
import { CommandInfo } from 'components/Editor/interfaces/commands'

export const AICommandEditorPlugin = () => {
  const { isOpened, isNew, isLoading, command } = useAppSelector((state) => state.commands)
  const dispatch = useAppDispatch()

  const handleClose = useCallback(() => {
    dispatch(closeAICommandEditorPanel())
  }, [dispatch, closeAICommandEditorPanel])

  const handleSaveEditingCommand = useCallback(
    async (data: Partial<CommandInfo>) => {
      await dispatch(saveEditingCommandInfo(data))
    },
    [dispatch, saveEditingCommandInfo],
  )

  const handleDeleteCommand = useCallback(
    async (id: number) => {
      console.log('Try delete COMMAND')
      await dispatch(deleteCommand(id))
    },
    [dispatch, deleteCommand],
  )

  return (
    <>
      {isOpened && (
        <ModalCommandCard
          open={isOpened}
          onCloseHandler={handleClose}
          isLoading={isLoading}
          isNew={isNew}
          command={command}
          onSaveEditingCommand={handleSaveEditingCommand}
          onDeleteCommand={handleDeleteCommand}
        />
      )}
    </>
  )
}
