import React from 'react'
import styles from './styles.module.scss'

const AlfAssistantFooterVoiceInputIcon = () => {
  return (
    <svg
      className={styles.svg}
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2236_25718)'>
        <path
          d='M10.6094 14.5257C10.7627 15.5794 11.2904 16.5427 12.0957 17.2393C12.9011 17.936 13.9303 18.3194 14.9952 18.3194M14.9952 18.3194C16.0601 18.3194 17.0893 17.936 17.8947 17.2393C18.7 16.5427 19.2277 15.5794 19.381 14.5257M14.9952 18.3194V20.5388M14.9958 9.46191C14.4922 9.46191 14.0092 9.66198 13.6531 10.0181C13.297 10.3742 13.0969 10.8572 13.0969 11.3608V13.8927C13.0969 14.3963 13.297 14.8793 13.6531 15.2354C14.0092 15.5915 14.4922 15.7916 14.9958 15.7916C15.4995 15.7916 15.9824 15.5915 16.3386 15.2354C16.6947 14.8793 16.8947 14.3963 16.8947 13.8927V11.3608C16.8947 10.8572 16.6947 10.3742 16.3386 10.0181C15.9824 9.66198 15.4995 9.46191 14.9958 9.46191Z'
          strokeWidth='1.38462'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.346154'
        y='0.346154'
        width='29.3077'
        height='29.3077'
        rx='14.6538'
        stroke='#4BBDEA'
        strokeWidth='0.692308'
      />
      <defs>
        <clipPath id='clip0_2236_25718'>
          <rect width='30' height='30' rx='15' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AlfAssistantFooterVoiceInputIcon
