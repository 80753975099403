import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { TabSection } from './components/TabSection/TabSection'
import PopupWithSettings from './components/Popup/PopupWithSettings'
import PopupWithInsert from './components/Popup/PopupWithInsert'

import { BurgerBtn } from './components/Btn/BurgerBtn'
import { PlusBtnWithBorder } from './components/Btn/PlusBtnWithBorder'
import { PencilBtn } from './components/Btn/PencilBtn'
import { CheckBlueBtn } from './components/Btn/CheckBlueBtn'
import { ArrowBtn } from './components/Btn/ArrowBtn'
import { AlfAssistant } from 'components/AlfAssistant/AlfAssistant'
import { Profile } from 'components/UserProfile/index'
import { Button } from 'components/Button'
import arrowIcon from '../../assets/icons/arrow_right_double.svg'
import pencilIcon from '../../assets/icons/pencilIcon.svg'
import dotslIcon from '../../assets/icons/more_dots.svg'
import nextArrowIcon from '../../assets/icons/arrow-curved-next.svg'
import prevArrowIcon from '../../assets/icons/arrow-curved-prev.svg'
import plusWhiteIcon from '../../assets/icons/white-plus.svg'

import styles from './style.module.scss'

import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { useStore, useEvent } from 'effector-react'
import {
  setIsShowMobileToolbar,
  setIsAllowedToEdit,
  setIsEditMode,
  setIsOpenSidePanel,
  setRedacting,
  setIsHeaderEditMode,
} from '../../pages/document/redux/documentSlice'
import { setIsOpenSidemenu } from 'pages/disk/redux/allProjects'
import useWindowSize from 'lib/hooks/useWindowSizeHook'
import { getSubs } from 'components/Payment/redux/paymentSlice'
import { toast } from 'react-toastify'
import { ALFAssistantMobile } from 'components/ALFAssistantMobile/ALFAssistantMobile'
import MobPullingOutModal from 'components/UI-ALF/MobileModal/MobileModalBottom'
import { $isAIPanelOpen } from 'components/Editor/models/panel'
import { useIsMobile } from 'lib/hooks/useIsMobile'
import { AICommandEditorPlugin } from 'components/Editor/plugins/AICommandEditorPlugin'
import { SuggestionLink } from './components/Btn/SuggestionLink/SuggestionLink'

export const Header = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { isAllowedToEdit, isOpenSidePanel, isHeaderEditMode } = useAppSelector((state) => state.document)
  const isMobile = useIsMobile()
  const { width } = useWindowSize()
  const [isPageSettingsPopupOpen, setIsPageSettingsPopupOpen] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)
  const [isInsertPopupOpen, setIsInsertPopupOpen] = useState(false)
  const { isOpenAlfChat } = useAppSelector((state) => state.alfAssistant)
  const [loader, setLoader] = useState(true)
  const role = useAppSelector((state) => state.user.role)
  const isPanelOpen = useStore($isAIPanelOpen)

  const routeDisk = pathname === `${'/disk'}`
  const routeHome = pathname === `${'/disk'}`
  const routeCalendar = pathname === `${'/calendar'}`

  const goToPage = () => {
    history.push('/login')
  }

  const handlePopupOpen = () => {
    // console.log('clickForPopup')
    setIsPageSettingsPopupOpen(true)
  }
  const { isShowMobileToolbar } = useAppSelector((state) => state.document)

  const handleEdit = () => {
    dispatch(setIsHeaderEditMode(true))
    dispatch(setIsShowMobileToolbar(true))

    // dispatch(setIsAllowedToEdit(true))
    // dispatch(setIsEditMode(true))
  }

  const handleNextStep = () => {
    // console.log('nextStep')
  }

  const handleCancelStep = () => {
    // console.log('cancelStep')
  }

  const insertPopupOpen = () => {
    setIsInsertPopupOpen(true)
  }

  const handleOpenSidePanel = () => {
    dispatch(setIsOpenSidePanel(true))
  }

  const isAddPopup = () => {
    setIsOpenPopup(true)
  }

  const headerTitle = routeDisk || routeHome ? 'Диск' : routeCalendar ? 'Календарь' : null
  const isDocumentPage = pathname.includes('/document')
  const isPublicDocumentPage = pathname.includes('/public/document')

  const navTabs = routeDisk ? <TabSection /> : null

  const desctopIcons = !isMobile ? (
    <>
      {role !== 'user' && <span className={styles.viewingOnlyText}>Только просмотр</span>}
      {role === 'user' ? (
        <>
          <AlfAssistant />
          <Profile />
        </>
      ) : (
        <>
          <Button type='yellow' onClick={goToPage}>
            {t('LoginForm_button_signIn')}
          </Button>
        </>
      )}
    </>
  ) : isPublicDocumentPage ? (
    <button onClick={goToPage}>{t('LoginForm_button_signIn')}</button>
  ) : null

  const mobileIcons =
    isDocumentPage && isHeaderEditMode && !isPanelOpen ? (
      // toDo закоментировано для будущей реализации пошагового редактирования текста
      /* <>
        <button onClick={handleCancelStep} className={styles.edit}>
          <img src={prevArrowIcon} alt='prevArrow-icon' />
        </button>
        <button onClick={handleNextStep} className={styles.edit}>
          <img src={nextArrowIcon} alt='nextArrow-icon' />
        </button> </> */
      <button onClick={insertPopupOpen} className={styles.edit}>
        <img src={plusWhiteIcon} alt='plus white icon' />
      </button>
    ) : isPublicDocumentPage ? (
      <button onClick={goToPage}>{t('LoginForm_button_signIn')}</button>
    ) : isDocumentPage && isAllowedToEdit ? (
      <>
        <PencilBtn />
        <button onClick={handlePopupOpen} className={styles.pagesArrow}>
          <img src={dotslIcon} alt='dots-icon' />
        </button>
      </>
    ) : null

  const burgerBtn = (isMobile && isDocumentPage && !isHeaderEditMode) || (isMobile && routeHome) ? <BurgerBtn /> : null

  /* useEffect(() => {
    dispatch(getSubs()).then(
      () => {
        setLoader(false)
      },
      (rej) => {
        toast.error(rej.message)
      },
    )
  }, []) */

  /* useEffect(() => {
    if (width > 1024) {
      dispatch(setIsOpenSidemenu(true))
      return () => {
        dispatch(setIsOpenSidemenu(false))
      }
    }
  }, [width]) */

  const closeAllPopups = () => {
    setIsPageSettingsPopupOpen(false)
    setIsInsertPopupOpen(false)
  }

  return (
    <>
      <div className={`${styles.headerContainer} ${isMobile && isDocumentPage && isOpenSidePanel && styles.isOpen}`}>
        <div className={styles.headerInfo}>
          {burgerBtn}
          <div className={styles.headerSection}>
            <h2 className={styles.headerTitle}>{headerTitle}</h2>
            {isMobile && isDocumentPage && !isHeaderEditMode && role === 'user' ? (
              <img className={styles.arrow} src={arrowIcon} alt='arrow-icon' onClick={handleOpenSidePanel} />
            ) : null}
            {isMobile && isDocumentPage && isHeaderEditMode && !isPanelOpen && <CheckBlueBtn />}
            {/* isMobile && isDocumentPage && isHeaderEditMode && isPanelOpen && <ArrowBtn /> */}
          </div>
          <div className={styles.tabSection}>{navTabs}</div>
        </div>
        <section className={styles.headerSection}>
          <SuggestionLink />
          {isMobile ? mobileIcons : desctopIcons}
          <>{role === 'user' && !isPanelOpen && <ALFAssistantMobile />}</>
          <>{isMobile && role === 'user' && (routeHome || routeDisk) && <Profile />}</>
        </section>
      </div>
      <AICommandEditorPlugin /> {/* <-- чтобы открывать карточку команды c главной страницы */}
    </>
  )
}
