import React from 'react'

export const DeleteChatFromHistoryIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2.5 5H4.16667H17.5' stroke='#A0A0A0' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6.66797 4.99984V3.33317C6.66797 2.89114 6.84356 2.46722 7.15612 2.15466C7.46869 1.8421 7.89261 1.6665 8.33464 1.6665H11.668C12.11 1.6665 12.5339 1.8421 12.8465 2.15466C13.159 2.46722 13.3346 2.89114 13.3346 3.33317V4.99984M15.8346 4.99984V16.6665C15.8346 17.1085 15.659 17.5325 15.3465 17.845C15.0339 18.1576 14.61 18.3332 14.168 18.3332H5.83464C5.39261 18.3332 4.96868 18.1576 4.65612 17.845C4.34356 17.5325 4.16797 17.1085 4.16797 16.6665V4.99984H15.8346Z'
        stroke='#A0A0A0'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
