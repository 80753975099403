import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as AdvantageIcon } from '../../../../../assets/icons/landingAdvanatageIcon.svg'
import { ReactComponent as Arrow } from '../../../../../assets/icons/arrow-right_landing.svg'
import { generateId } from 'pages/tablePage/helpers/utils'

interface ITabsPanelProps {
  listAdvantages: string[]
  role: string
}

export const TabsPanel = ({ listAdvantages, role }: ITabsPanelProps) => {
  return (
    <div className={styles.tabsPanel}>
      <div className={styles.tabsPanel__left}>
        <div className={styles.tabsPanel__leftTop}>
          <h3 className={styles.tabsPanel__header}>C помощью ИИ всего за пару минут смогут создавать:</h3>
          <ul className={styles.tabsPanel__list}>
            {listAdvantages.map((advantage) => {
              return (
                <li key={generateId()} className={styles.tabsPanel__item}>
                  <AdvantageIcon />
                  <p className={styles.tabsPanel__advantageDescription}>{advantage}</p>
                </li>
              )
            })}
          </ul>
        </div>
        {/* <a className={styles.tabsPanel__link} href='#'>
          {role === 'other' ? 'Посмотреть все доступные команды' : `Посмотреть все команды ИИ для ${role}`} <Arrow />
        </a> */}
      </div>
      <div className={styles.tabsPanel__right}>
        <video src='#' className={styles.tabsPanel__video}>
          {' '}
        </video>
      </div>
    </div>
  )
}
