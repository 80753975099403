import React from 'react'
import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setCurrentAssistantId } from 'app/redux/alfAssistantSlice'
import { createNewChat, selectChat } from 'app/redux/userSlice'
import AlfBotOneAssistant from 'components/AlfAssistantModal/components/AlfAssistantBotListModal/components/AlfBotAssistants/AlfBotOneAssistant'

const botAssistantsStyles = {
  width: '635px',
  margin: '0 16px',
  borderTop: '0',
  borderRadius: '8px',
  background: '#323D49',
  height: 'fit-content',
  maxHeight: '100px',
}

const hover = {
  cursor: 'default',
}

interface IAlfFixedAssistantProps {
  style?: React.CSSProperties | typeof styles
  hover?: React.CSSProperties
  isClickable?: boolean
}

export const AlfFixedAssistant: React.FC<IAlfFixedAssistantProps> = ({ style = botAssistantsStyles, isClickable }) => {
  const dispatch = useAppDispatch()
  const { assistants, currentAssistantId } = useAppSelector((state) => state.alfAssistant)

  const handleOpenChat = ({ id, chatUid }: { id: number | null; chatUid: string }) => {
    if (chatUid === null) {
      dispatch(setCurrentAssistantId(id))
      dispatch(createNewChat())
    } else {
      dispatch(setCurrentAssistantId(id))
      dispatch(selectChat(chatUid))
    }
  }

  return (
    <>
      {currentAssistantId &&
        assistants.map(
          (assistant) =>
            currentAssistantId === assistant.id && (
              <AlfBotOneAssistant
                key={assistant.id}
                assistant={assistant}
                style={style}
                hover={hover}
                isClickable={isClickable}
                handleOpenChat={handleOpenChat}
                fixed
              />
            ),
        )}
    </>
  )
}
