import { CloseIcon } from 'components/DocumentSettingsModal/components/CloseIcon/CloseIcon'
import { Drawer } from 'components/UI/Drawer/Drawer'
import React from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { DocumentVariablesInput } from '../DocumentVariablesInput/DocumentVariablesInput'
import styles from './documenVariablesItem.module.scss'
import { Button } from 'components/Button'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { useMutation } from '@apollo/client'
import { variablesMutation } from 'apollo/variables'
import { DocumentVariableEditing } from '../interfaces'

interface DocumentVariablesItemModalProps {
  isNew: boolean
  modalOpen: boolean
  item?: DocumentVariableEditing
  handleCloseModal: () => void
  changeIncludesName: (name: string, id: string) => void
  errorIncludeName: boolean
  onSaveVariable?: () => void
}

export const DocumentVariablesItemModal: React.FC<DocumentVariablesItemModalProps> = ({
  changeIncludesName,
  errorIncludeName,
  modalOpen,
  handleCloseModal,
  isNew,
  item,
  onSaveVariable,
}) => {
  console.log('errorIncludeName', errorIncludeName)
  const [variablesInfo, setVariablesInfo] = React.useState(
    isNew
      ? { id: '', name: '', type: '', value: '', description: '' }
      : {
          id: item.id,
          name: item.name,
          type: item.type,
          value: item.value,
          description: item.description,
        },
  )

  const [createVariable, { data, loading }] = useMutation(variablesMutation.CREATE_VARIABLE)
  const [updateVariable] = useMutation(variablesMutation.UPDATE_VARIABLE)

  const [startEditing, setStartEditing] = React.useState(true)

  const handleChangeForm = (e) => {
    const field = e.target.getAttribute('data-name')

    setVariablesInfo({
      ...variablesInfo,
      [field]: e.target.value,
    })
    setStartEditing(false)
  }

  const addNewVariable = async () => {
    const res = await createVariable({
      variables: {
        name: variablesInfo.name,
        value: variablesInfo.value,
        description: variablesInfo.description,
        type: 'string',
      },
    })
    if (res.data.createVar.ok) {
      setVariablesInfo({ id: '', name: '', type: '', value: '', description: '' })
      handleCloseModal()
      if (onSaveVariable) {
        onSaveVariable()
      }
    }
  }

  const saveChangeVariables = async (variable: DocumentVariableEditing) => {
    // changeVariablesField(variable)
    const res = await updateVariable({
      variables: {
        varId: variable.id,
        name: variable.name,
        value: variable.value,
        description: variable.description,
        type: 'string',
      },
    })
    if (res.data.updateVar.ok) {
      handleCloseModal()
      if (onSaveVariable) {
        onSaveVariable()
      }
    }
  }

  React.useEffect(() => {
    changeIncludesName(variablesInfo.name, variablesInfo.id)
  }, [variablesInfo])

  return (
    <Drawer isOpen={modalOpen} onClose={handleCloseModal} width='auto' overlay btnForVariables>
      <div className={styles.container}>
        <div className={styles.title_box}>
          <h1> {isNew ? 'Создать' : 'Изменить'} переменную</h1>
          <button>Помощь</button>
        </div>

        <DocumentVariablesInput
          state={variablesInfo}
          title='Название'
          name='name'
          handleChangeForm={handleChangeForm}
          placeholder='Введите название'
          error={errorIncludeName && variablesInfo.name && !startEditing}
        />
        {errorIncludeName && variablesInfo.name && !startEditing && (
          <p className={styles.error}>Переменная с таким названием уже есть</p>
        )}
        <DocumentVariablesInput
          state={variablesInfo}
          title='Тип'
          name='type'
          handleChangeForm={handleChangeForm}
          placeholder='Строка'
          stroke='stroke'
        />
        <DocumentVariablesInput
          state={variablesInfo}
          title='Значение'
          name='value'
          handleChangeForm={handleChangeForm}
          placeholder='Не заполнено'
          height='200px'
        />
        <DocumentVariablesInput
          state={variablesInfo}
          title='Описание'
          name='description'
          handleChangeForm={handleChangeForm}
          placeholder=''
          height='200px'
          resize='resize'
        />
        <div className={styles.btn_box}>
          {isNew ? (
            <Button
              type='yellow'
              fullWidth={false}
              disabled={!(!!variablesInfo.name && !!variablesInfo.value && !errorIncludeName)}
              onClick={() => addNewVariable()}
            >
              Создать
            </Button>
          ) : (
            <Button
              type='yellow'
              fullWidth={false}
              disabled={!(!!variablesInfo.name && !!variablesInfo.value && !startEditing && !errorIncludeName)}
              onClick={() => saveChangeVariables(variablesInfo)}
            >
              Изменить
            </Button>
          )}
          <button className={styles.btn_gray} onClick={handleCloseModal}>
            Отмена
          </button>
        </div>
      </div>
    </Drawer>
  )
}
