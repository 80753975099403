import React, { useState } from 'react'
import styles from './style.module.scss'
import { TabsPanel } from './TabsPanel/TabsPanel'

export const TabsScreen = () => {
  enum TabsPanelRoles {
    leaders = 0,
    hr = 1,
    marketers = 2,
    lawyers = 3,
    other = 4,
  }

  const [idSelectedTabsPanelVar, setIdSelectedTabsPanelVar] = useState(0)

  const tabsPanelVar = [
    {
      listAdvantages: [
        'Рабочие инструкции и служебные записки',
        'Договора',
        'Маркетинговые стратегии',
        'Любые текстовые документы (письма, коммерческие предложения, презентации)',
      ],
      role: 'руководителей',
    },
    {
      listAdvantages: [
        'HR-стратегию бренда',
        'Обучающие курсы и инструкции',
        'Контент для внутренних коммуникаций',
        'Объявления о вакансиях и письма для кандидатов',
      ],
      role: 'HR',
    },
    {
      listAdvantages: [
        'Генерировать идеи для контента',
        'Анализировать данные о клиентах',
        'Создавать маркетинговые стратегии',
        'Провести анализ конкурентов',
      ],
      role: 'маркетологов',
    },
    {
      listAdvantages: [
        'Быть в курсе последних изменений в законодательстве',
        'Автоматизировать подготовку однотипных документов',
        'Проверять документы на соответствие законодательству',
        'Быстро разработать договор, претензию, контракт',
      ],
      role: 'юриста',
    },
    {
      listAdvantages: [
        'Сценарии',
        'Резюме и сопроводительные письма',
        'Оригинальный контент для социальных сетей',
        'И многое многое другое',
      ],
      role: 'other',
    },
  ]
  return (
    <section className={styles.tabs}>
      <div className={styles.container}>
        <h2 className={styles.tabs__header}>
          Что могут создавать <br />с помощью BrainCloud AI?
        </h2>
        <ul className={styles.tabs__btnsList}>
          <li className={styles.tabs__btnItem}>
            <button
              className={`${styles.tabs__btn} ${
                idSelectedTabsPanelVar === TabsPanelRoles.leaders ? styles.active : ''
              }`}
              onClick={() => setIdSelectedTabsPanelVar(TabsPanelRoles.leaders)}
            >
              Руководители
            </button>
          </li>
          <li className={styles.tabs__btnItem}>
            <button
              className={`${styles.tabs__btn} ${idSelectedTabsPanelVar === TabsPanelRoles.hr ? styles.active : ''}`}
              onClick={() => setIdSelectedTabsPanelVar(TabsPanelRoles.hr)}
            >
              HR-менеджеры
            </button>
          </li>
          <li className={styles.tabs__btnItem}>
            <button
              className={`${styles.tabs__btn} ${
                idSelectedTabsPanelVar === TabsPanelRoles.marketers ? styles.active : ''
              }`}
              onClick={() => setIdSelectedTabsPanelVar(TabsPanelRoles.marketers)}
            >
              Маркетологи
            </button>
          </li>
          <li className={styles.tabs__btnItem}>
            <button
              className={`${styles.tabs__btn} ${
                idSelectedTabsPanelVar === TabsPanelRoles.lawyers ? styles.active : ''
              }`}
              onClick={() => setIdSelectedTabsPanelVar(TabsPanelRoles.lawyers)}
            >
              Юристы
            </button>
          </li>
          <li className={styles.tabs__btnItem}>
            <button
              className={`${styles.tabs__btn} ${idSelectedTabsPanelVar === TabsPanelRoles.other ? styles.active : ''}`}
              onClick={() => setIdSelectedTabsPanelVar(TabsPanelRoles.other)}
            >
              Прочее
            </button>
          </li>
        </ul>
        <TabsPanel
          listAdvantages={tabsPanelVar[idSelectedTabsPanelVar].listAdvantages}
          role={tabsPanelVar[idSelectedTabsPanelVar].role}
        />
      </div>
    </section>
  )
}
