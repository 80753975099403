import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { ALFAssistantMobileChatInput } from './components/ALFAssistantMobileChatInput/ALFAssistantMobileChatInput'
import { ALFAssistantMobileMockElement } from './components/ALFAssistantMobileMockElement/ALFAssistantMobileMockElement'
import { MobileButtonBlock } from './components/MobileButtonBlock/MobileButtonBlock'
import { Chat } from 'app/redux/userSlice'
import { ALFMobileHistoryModal } from '../ALFMobileHistoryModal/ALFMobileHistoryModal'
interface ALFAssistantMobileChatFooterProps {
  isPending: boolean
  inputValue: string
  handleInputChange: (event: ChangeEvent) => void
  handleKeyDown: React.KeyboardEventHandler<HTMLElement>
  handleKeyPress: React.KeyboardEventHandler<HTMLElement>
  handleSendMessage: () => void
  canselRequest: () => void
  handleOpenCommandMenu: () => void
  openHistoryModal: boolean
  handleCloseHistoryModal: () => void
  chats: Chat[]
  chatId: string
  deleteHistory: (id: string) => void
  renameChatHistory: (data: { id: string; name: string }) => void
  waiting: boolean
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
  searchTerm: string
  handleCreateNewChat: () => void
  handleOpenHistoryModal: () => void
  selectChatHistory: (id: string) => void
  handleInputEvent: (cursorPosition: number) => void
}

export const ALFAssistantMobileChatFooter = React.forwardRef(
  (props: ALFAssistantMobileChatFooterProps, ref: React.LegacyRef<HTMLElement>) => {
    const {
      isPending,
      inputValue,
      handleInputChange,
      handleKeyDown,
      handleKeyPress,
      handleSendMessage,
      canselRequest,
      handleOpenCommandMenu,
      openHistoryModal,
      handleSearch,
      searchTerm,
      selectChatHistory,
      handleCloseHistoryModal,
      chats,
      chatId,
      deleteHistory,
      renameChatHistory,
      waiting,
      handleCreateNewChat,
      handleOpenHistoryModal,
      handleInputEvent,
    } = props
    return openHistoryModal ? (
      <ALFMobileHistoryModal
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        selectChat={selectChatHistory}
        handleCloseHistoryModal={handleCloseHistoryModal}
        chats={chats}
        chatId={chatId}
        deleteHistory={deleteHistory}
        renameChatHistory={renameChatHistory}
        waiting={waiting}
      />
    ) : (
      <footer className={styles.footer} onKeyDown={handleKeyDown} ref={ref}>
        <MobileButtonBlock handleCreateNewChat={handleCreateNewChat} handleOpenHistoryModal={handleOpenHistoryModal} />
        {isPending ? (
          <ALFAssistantMobileMockElement canselRequest={canselRequest} />
        ) : (
          <ALFAssistantMobileChatInput
            inputValue={inputValue}
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            handleSendMessage={handleSendMessage}
            handleOpenCommandMenu={handleOpenCommandMenu}
            handleInputEvent={handleInputEvent}
          />
        )}
      </footer>
    )
  },
)
