import React from 'react'
import styles from './styles.module.scss'

export const HistoryIcon = () => {
  return (
    <svg
      className={styles.svgFill}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 25C13.9 25 12.0417 24.3623 10.425 23.087C8.80834 21.8117 7.75834 20.1827 7.275 18.2C7.20834 17.95 7.25834 17.721 7.425 17.513C7.59167 17.305 7.81667 17.184 8.1 17.15C8.36667 17.1167 8.60834 17.1667 8.825 17.3C9.04167 17.4333 9.19167 17.6333 9.275 17.9C9.675 19.4 10.5 20.625 11.75 21.575C13 22.525 14.4167 23 16 23C17.95 23 19.6043 22.3207 20.963 20.962C22.3217 19.6033 23.0007 17.9493 23 16C23 14.05 22.3207 12.3957 20.962 11.037C19.6033 9.67833 17.9493 8.99933 16 9C14.85 9 13.775 9.26667 12.775 9.8C11.775 10.3333 10.9333 11.0667 10.25 12H12C12.2833 12 12.521 12.096 12.713 12.288C12.905 12.48 13.0007 12.7173 13 13C13 13.2833 12.904 13.521 12.712 13.713C12.52 13.905 12.2827 14.0007 12 14H8C7.71667 14 7.479 13.904 7.287 13.712C7.095 13.52 6.99934 13.2827 7 13V9C7 8.71667 7.096 8.479 7.288 8.287C7.48 8.095 7.71734 7.99933 8 8C8.28334 8 8.521 8.096 8.713 8.288C8.905 8.48 9.00067 8.71733 9 9V10.35C9.85 9.28333 10.8877 8.45833 12.113 7.875C13.3383 7.29167 14.634 7 16 7C17.25 7 18.421 7.23767 19.513 7.713C20.605 8.18833 21.555 8.82967 22.363 9.637C23.171 10.4457 23.8127 11.3957 24.288 12.487C24.7633 13.5783 25.0007 14.7493 25 16C25 17.25 24.7623 18.421 24.287 19.513C23.8117 20.605 23.1703 21.555 22.363 22.363C21.5543 23.171 20.6043 23.8127 19.513 24.288C18.4217 24.7633 17.2507 25.0007 16 25ZM17 15.6L19.5 18.1C19.6833 18.2833 19.775 18.5167 19.775 18.8C19.775 19.0833 19.6833 19.3167 19.5 19.5C19.3167 19.6833 19.0833 19.775 18.8 19.775C18.5167 19.775 18.2833 19.6833 18.1 19.5L15.3 16.7C15.2 16.6 15.125 16.4877 15.075 16.363C15.025 16.2383 15 16.109 15 15.975V12C15 11.7167 15.096 11.479 15.288 11.287C15.48 11.095 15.7173 10.9993 16 11C16.2833 11 16.521 11.096 16.713 11.288C16.905 11.48 17.0007 11.7173 17 12V15.6Z'
        fill='#95999E'
      />
    </svg>
  )
}
