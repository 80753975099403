import React from 'react'
import styles from './style.module.scss'
import newProject from '../../../assets/images/NewProject-min.png'
import basket from '../../../assets/images/basket-min.png'
import notFound from '../../../assets/images/notFound-min.png'
import connectionLost from '../../../assets/images/connectionLost.png'

interface BlankScreenProps {
  children?: any
  title: string
  text: string
  imageNotFound?: boolean
  imageNewProject?: boolean
  imageBasket?: boolean
  imageConnectionLost?: boolean
}

export const BlankScreen: React.FC<BlankScreenProps> = ({
  children,
  title,
  text,
  imageNotFound = false,
  imageNewProject = false,
  imageBasket = false,
  imageConnectionLost = false,
}) => {
  const returnImage = () => {
    if (imageNewProject) {
      return newProject
    }
    if (imageNotFound) {
      return notFound
    }
    if (imageBasket) {
      return basket
    }
    if (imageConnectionLost) {
      return connectionLost
    }
  }

  return (
    <div className={styles.padding}>
      <div className={styles.blank}>
        <img src={returnImage()} className={styles.blank__img} alt='image' />
        <h3 className={styles.blank__title}>{title}</h3>
        <p className={styles.blank__text}>{text} </p>
        {children}
      </div>
    </div>
  )
}
