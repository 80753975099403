export const customStyles = {
  container: (provided) => ({
    ...provided,
    height: '40px',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    minHeight: '40px',
    background: '#36393F',
    borderWidth: '0',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'all .2s ease-in-out',
    border: '1px solid #4BBDEA',
    '&:hover': {
      background: '#3d4d5c33',
    },
    '&:focus-within': {
      background: '#0042577f',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#95999E',
    marginLeft: 0,
    marginRight: 0,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0 0 12px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    margin: 'auto 12px',
    color: '#95999E',
    '&:hover': {
      color: '#95999E',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    position: 'absolute',
    top: 'unset',
    bottom: '100%',
    background: '#36393F',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#3D4D5C' : 'transparent',
    borderLeft: state.isFocused ? '3px solid #63B3FF' : '0',
    padding: state.isFocused ? '4px 16px 4px 9px' : '4px 16px 4px 12px',
    cursor: 'pointer',
  }),
}
export const customStylesModelAI = {
  container: (provided) => ({
    ...provided,
    height: '40px',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    minHeight: '40px',
    background: '#36393F',
    borderWidth: '0',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'all .2s ease-in-out',
    border: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#95999E',
    marginLeft: 0,
    marginRight: 0,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0 0 12px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    margin: 'auto 12px',
    color: '#95999E',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    position: 'absolute',
    top: 'unset',
    bottom: '100%',
    background: '#36393F',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#3D4D5C' : 'transparent',
    borderLeft: state.isFocused ? '3px solid #63B3FF' : '0',
    padding: state.isFocused ? '4px 16px 4px 9px' : '4px 16px 4px 12px',
    cursor: 'pointer',
  }),
}
export const customStylesAutoLanguage = {
  container: (provided) => ({
    ...provided,
    height: '40px',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    minHeight: '40px',
    background: '#36393F',
    borderWidth: '0',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'all .2s ease-in-out',
    border: '1px solid #666',
    '&:hover': {
      background: '#3d4d5c33',
      border: '1px solid #DBDCDD',
    },
    '&:focus-within': {
      background: '#333945cc',
      border: '1px solid #DBDCDD',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#DBDCDD',
    marginLeft: 0,
    marginRight: 0,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0 0 12px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    margin: 'auto 12px',
    color: '#DBDCDD',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    position: 'absolute',
    top: 'unset',
    bottom: '100%',
    background: '#36393F',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#3D4D5C' : 'transparent',
    borderLeft: state.isFocused ? '3px solid #63B3FF' : '0',
    padding: state.isFocused ? '4px 16px 4px 9px' : '4px 16px 4px 12px',
    cursor: 'pointer',
  }),
}

export const customButtonStyles = {
  control: (provided, state) => ({
    ...customStyles.control(provided, state),
    width: '149px',
    borderWidth: '1px',
    borderColor: '#80BAF5',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#4BBDEA',
    marginLeft: 0,
    marginRight: 0,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 7px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
}
