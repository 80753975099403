import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'

interface IRequestAssistantData {
  name: string
  description?: string
  avatar?: File | string
  prompt?: string
  engine?: number
  temperature?: number
  id?: number
}

export const createAssistant = createAsyncThunk('ai_assistants', async (requestData: IRequestAssistantData) => {
  const formData = new FormData()
  for (const key in requestData) {
    if (requestData[key] !== undefined && key !== 'avatar') {
      formData.append(key, requestData[key].toString())
    }
  }
  if (requestData.avatar) {
    formData.append('avatar', requestData.avatar)
  }
  const response = await api.sendFile<any>(`ai_assistants/`, formData)
  return response
})

export const patchAssistant = createAsyncThunk('alf/patchAssistant', async (requestData: IRequestAssistantData) => {
  const formData = new FormData()
  for (const key in requestData) {
    if (requestData[key] !== undefined && key !== 'avatar' && key !== 'id') {
      formData.append(key, requestData[key].toString())
    }
  }
  if (requestData.avatar) {
    formData.append('avatar', requestData.avatar)
  }
  const response = await api.updateFile<any>(`ai_assistants/${requestData.id}/`, formData)
  return response.data
})

export const getAssistantList = createAsyncThunk('alf/getAssistantList', async () => {
  const response = await api.get<any>(`ai_assistants/`)
  return response.data
})

export const getAssistant = createAsyncThunk('alf/getAssistant', async (id: number) => {
  const response = await api.get<any>(`ai_assistants/${id}`)
  return response.data
})

export const pinAssistant = createAsyncThunk('alf/pinAssistant', async (id: number) => {
  const response = await api.post<any>(`ai_assistants/pin/`, { aiAssistant: id })
  return response
})
export const viewedAssistant = createAsyncThunk('alf/viewedAssistant', async (id: number) => {
  const response = await api.post<any>(`ai_assistants/viewed/`, { aiAssistant: id })
  return response
})
export const removeViewedAssistant = createAsyncThunk('alf/removeViewedAssistant', async (id: number) => {
  const response = await api.delete<any>(`ai_assistants/viewed/${id}/`)
  return response.data
})
export const removeAssistant = createAsyncThunk('alf/removeAssistant', async (id: number) => {
  const response = await api.delete<any>(`ai_assistants/${id}/`)
  return response.data
})

export const removePinning = createAsyncThunk('alf/removePinning', async (id: number) => {
  const response = await api.delete<any>(`ai_assistants/pin/${id}/`)
  return response
})

const initialState = {
  isOpenAlfChat: false,
  isEditedCommand: false,
  isOpenCreateAssistant: false,
  isOpenAssistantSettings: false,
  isOpenBotListModal: false,
  isOpenSourceModal: false,
  isOpenSourcesTable: false,
  isTextSourceChecked: false,
  isLinkSourceChecked: false,
  assistants: [],
  newAssistants: [],
  selectedAssistent: [],
  currentAssistantData: {},
  pinnedBotAssistants: [],
  viewedBotAssistants: [],
  currentAssistantId: 1,
  activeAssistant: null,
  isClickable: false,
  isOpenHistoryShareModal: false,
  historyShareModalLinkValue: '',
}

export const alfAssistantSlice = createSlice({
  name: 'alf',
  initialState,
  reducers: {
    setIsOpenAlfChat: (state, { payload }) => {
      state.isOpenAlfChat = payload
    },
    setIsEditedCommand: (state, { payload }) => {
      state.isEditedCommand = payload
    },
    setIsOpenCreateAssistant: (state, { payload }) => {
      state.isOpenCreateAssistant = payload
    },
    setIsOpenBotListModal: (state, { payload }) => {
      state.isOpenBotListModal = payload
    },
    setIsOpenSourceModal: (state, { payload }) => {
      state.isOpenSourceModal = payload
    },
    setIsOpenSourcesTable: (state, { payload }) => {
      state.isOpenSourcesTable = payload
    },
    setTextSourceChecked: (state, { payload }) => {
      state.isTextSourceChecked = payload
    },
    setLinkSourceChecked: (state, { payload }) => {
      state.isLinkSourceChecked = payload
    },
    setCurrentAssistantId: (state, { payload }) => {
      state.currentAssistantId = payload
      state.activeAssistant = state.assistants.find((bot) => bot.id === payload)
    },
    setAssistants: (state, { payload }) => {
      state.assistants = payload
      state.newAssistants = payload
    },
    setSelectedAssistants: (state, { payload }) => {
      state.assistants = payload
    },
    setIsClickable: (state, { payload }) => {
      state.isClickable = payload
    },
    // состояние открытия и закрытия модалки "поделиться перепиской"
    setOpenHistoryShareModal: (state, { payload }) => {
      state.isOpenHistoryShareModal = payload
    },
    // значение для копирования ссылки в модалке "поделиться перепиской"
    setHistoryShareModalLinkValue: (state, { payload }) => {
      state.historyShareModalLinkValue = payload
    },
    setIsOpenAssistantSettings: (state, { payload }) => {
      state.isOpenAssistantSettings = payload
    },
    setPinnedBotAssistants: (state, { payload }) => {
      state.pinnedBotAssistants = payload
    },
    setViewedBotAssistants: (state, { payload }) => {
      state.viewedBotAssistants = payload
    },
    updateViewedBotAssistants: (state, { payload }) => {
      const indexNewBot = state.viewedBotAssistants.findIndex((item) => item.id === payload.id)
      state.viewedBotAssistants[indexNewBot] = payload
    },
    removeViewed: (state, { payload }) => {
      const { id } = payload
      const indexIsAssistants = state.newAssistants.findIndex((bot) => bot.id === id)
      const updatedBotAssistants = [...state.newAssistants]
      updatedBotAssistants[indexIsAssistants] = { ...updatedBotAssistants[indexIsAssistants], isViewed: false }
      return {
        ...state,
        viewedBotAssistants: state.viewedBotAssistants.filter((bot) => bot.id !== id),
        newAssistants: updatedBotAssistants,
      }
    },
    removePinned: (state, { payload }) => {
      return { ...state, pinnedBotAssistants: state.pinnedBotAssistants.filter((bot) => bot.id !== payload) }
    },
    deleteAssistant: (state, { payload }) => {
      return {
        ...state,
        assistants: state.assistants.filter((bot) => bot.id !== payload),
        newAssistants: state.newAssistants.filter((bot) => bot.id !== payload),
      }
    },
    changeIsPinned: (state, { payload }) => {
      const { id, isPinned } = payload
      const index = state.assistants.findIndex((bot) => bot.id === id)
      const updatedBotAssistants = [...state.assistants]
      updatedBotAssistants[index] = { ...updatedBotAssistants[index], isPinned }
      return { ...state, assistants: updatedBotAssistants }
    },
    addViewed: (state, { payload }) => {
      const { id } = payload
      const indexIsAssistants = state.assistants.findIndex((bot) => bot.id === id)
      const updatedBotAssistants = [...state.newAssistants]
      updatedBotAssistants[indexIsAssistants] = { ...updatedBotAssistants[indexIsAssistants], isViewed: true }
      if (state.viewedBotAssistants.length === 0) {
        return {
          ...state,
          viewedBotAssistants: state.newAssistants.filter((item) => item.id === id),
          newAssistants: updatedBotAssistants,
        }
      }

      const index = state.viewedBotAssistants.findIndex((bot) => bot.id === id)
      const el = state.viewedBotAssistants[index]
      const updateSort = [...state.viewedBotAssistants]
      // логика в том что если выбираем ассистента который скрыт то он попадает в начало списка и отображается первым
      if (index !== -1) {
        if (index > 2) {
          updateSort.splice(index, 1)
          updateSort.unshift(el)
        } else {
          return { ...state, viewedBotAssistants: updateSort }
        }
      } else {
        updateSort.unshift(...state.assistants.filter((item) => item.id === id))
      }
      return { ...state, newAssistants: updatedBotAssistants, viewedBotAssistants: updateSort }
    },
  },
})

export const {
  setIsOpenAlfChat,
  setIsEditedCommand,
  setIsOpenCreateAssistant,
  setIsOpenAssistantSettings,
  setIsOpenBotListModal,
  setIsOpenSourceModal,
  setIsOpenSourcesTable,
  setTextSourceChecked,
  setAssistants,
  setLinkSourceChecked,
  setCurrentAssistantId,
  setIsClickable,
  setOpenHistoryShareModal,
  setHistoryShareModalLinkValue,
  setPinnedBotAssistants,
  setViewedBotAssistants,
  changeIsPinned,
  removeViewed,
  removePinned,
  deleteAssistant,
  addViewed,
  updateViewedBotAssistants,
} = alfAssistantSlice.actions

export const alfAssistant = alfAssistantSlice.reducer
