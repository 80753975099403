import React, { useCallback, useState } from 'react'
import styles from './styles.module.scss'
import { useAppSelector } from 'app/redux/hooks'
import { shallowEqual } from 'react-redux'
import { AlfAssistantAddButton } from './../../../AlfAssistantCreateAssistant/components/AlfAssistantAddButton/AlfAssistantAddButton'
import { Checkbox } from './../../../AlfAssistantCreateAssistant/components/Checkbox/Checkbox'
import { WheelIcon } from '../../images/WheelIcon'
import { DeleteIcon } from '../../images/DeleteIcon'
import { DeleteHistoryModal } from '../../../AlfAssistantFooter/components/AlfAssistantHistoryModal/components/HistoryItem/components/DeleteHistoryModal/DeleteHistoryModal'

export const AlfAssistantSourcesTable: React.FC = () => {
  const { waiting } = useAppSelector((state) => state.user, shallowEqual)
  const [selectAll, setSelectAll] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([])
  const [hover, setHover] = useState<number | null>()

  const callbacks = {
    handleDelete: useCallback(() => {
      console.log('delete')
    }, []),
    openDeleteModal: () => setOpenDeleteModal(true),
    closeDeleteModal: useCallback(() => setOpenDeleteModal(false), [setOpenDeleteModal]),
  }

  const handleCheckboxChange = (index: number) => {
    const updatedSelectedCheckboxes = [...selectedCheckboxes]
    if (updatedSelectedCheckboxes.includes(index)) {
      updatedSelectedCheckboxes.splice(updatedSelectedCheckboxes.indexOf(index), 1)
      setSelectAll(false)
    } else {
      updatedSelectedCheckboxes.push(index)
      if (updatedSelectedCheckboxes.length === 3) {
        setSelectAll(true)
      }
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes)
  }

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedCheckboxes([])
    } else {
      const allIndices = Array.from({ length: 3 }, (_, i) => i)
      setSelectedCheckboxes(allIndices)
    }
    setSelectAll(!selectAll)
  }

  return (
    <div className={styles.section}>
      <AlfAssistantAddButton title='Добавить источник' style={{ marginLeft: '21px' }} isButtonValid showIcon />
      <h1 className={styles.title}>Источники ассистента</h1>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tr}>
            <th className={styles.th} style={{ width: '40px', visibility: 'hidden', display: 'table' }}>
              none
            </th>
            <th className={styles.th} style={{ width: '40px', verticalAlign: 'bottom' }}>
              <WheelIcon />
            </th>
            <th className={styles.th} style={{ width: '40px' }}>
              <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
            </th>
            <th className={styles.th} style={{ width: '197px' }}>
              Документы
            </th>
            <th className={styles.th} style={{ width: '101px' }}>
              Статус
            </th>
            <th className={styles.th} style={{ width: '197px' }}>
              Документ изменён
            </th>
            <th className={styles.th} style={{ width: '197px' }}>
              Документ добавлен
            </th>
            <th className={styles.th} style={{ width: '197px' }}>
              Тип
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 3 }, (_, i) => i).map((index) => (
            <React.Fragment key={index}>
              <tr className={styles.tr} onMouseEnter={() => setHover(index)} onMouseLeave={() => setHover(null)}>
                <td>
                  {hover === index && (
                    <button className={styles.deleteButton} onClick={callbacks.openDeleteModal}>
                      <DeleteIcon />
                    </button>
                  )}
                </td>
                <td className={styles.td} style={{ textAlign: 'center' }}>
                  1
                </td>
                <td className={styles.td}>
                  <Checkbox checked={selectedCheckboxes.includes(index)} onChange={() => handleCheckboxChange(index)} />
                </td>
                <td className={styles.td}>
                  Текст 1 {hover === index && <button className={styles.openButton}>ОТКРЫТЬ</button>}
                </td>
                <td className={styles.td}>Загружен</td>
                <td className={styles.td}>-</td>
                <td className={styles.td}>19:22, 21 Фев. 2023</td>
                <td className={styles.td}>Текст</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className={styles.counter}>
        <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
        <span>Выбрать всё ({selectedCheckboxes.length} / 3)</span>
      </div>
      {openDeleteModal && (
        <DeleteHistoryModal
          title='Удалить источник'
          description='Вы действительно хотите удалить источник Текст 1?'
          handleDelete={callbacks.handleDelete}
          closeModal={callbacks.closeDeleteModal}
          waiting={waiting}
          style={{ right: '290px' }}
        />
      )}
    </div>
  )
}
