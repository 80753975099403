import React from 'react'
import styles from './styles.module.scss'

const ResetIcon = () => {
  return (
    <svg
      className={styles.svg}
      width='24'
      height='24'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8.15625' cy='8' r='8' fill='#32353A' />
      <path d='M11.1562 5L5.15625 11' stroke='#95999E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.15625 5L11.1562 11' stroke='#95999E' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default ResetIcon
