import React from 'react'
import styles from './styles.module.scss'
import { AlfAssistantSettingsTab } from '../AlfAssistantSettingsTab/AlfAssistantSettingsTab'
import { nanoid } from '@reduxjs/toolkit'

interface IAlfAssistantSettingsTabBar {
  list: string[]
  activeTab: string
  className?: string
  setActiveTab: (path: string) => void
}

export const AlfAssistantSettingsTabBar: React.FC<IAlfAssistantSettingsTabBar> = ({
  list,
  activeTab,
  className,
  setActiveTab,
}) => {
  return (
    <div className={`${styles.tabBar__wrap} ${className}`}>
      {list.map((item) => (
        <AlfAssistantSettingsTab
          text={item}
          activeTab={activeTab}
          path={item}
          setActiveTab={setActiveTab}
          key={nanoid()}
        />
      ))}
    </div>
  )
}
