import React, { useState } from 'react'
import styles from './styles.module.scss'

interface ISliderProps {
  min: number
  max: number
  step: number
  onChange: (value: number) => void
  value: number
}

export const Slider: React.FC<ISliderProps> = ({ min, max, step, onChange, value }) => {
  const [showValueWindow, setShowValueWindow] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value)
    onChange(newValue)
  }

  const handleMouseEnter = () => {
    setShowValueWindow(true)
  }

  const handleMouseLeave = () => {
    setShowValueWindow(false)
  }

  const values = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]

  // Позиционирование окна значения слайдера
  const positionStyle = {
    left: `${((value - min) / (max - min)) * 97.9}%`,
    transform: 'translateX(-19.1%)',
  }

  return (
    <div className={styles.sliderContainer}>
      <input
        type='range'
        className={styles.slider}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {showValueWindow && (
        <div className={styles.valueWindow} style={positionStyle}>
          {values[Math.floor((value - min) / step)]}
        </div>
      )}
      <div className={styles.values}>
        {values.map((val, index) => (
          <span key={index} className={styles.value}>
            {val}
          </span>
        ))}
      </div>
    </div>
  )
}
