import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { SearchIcon } from '../icons/SearchIcon'
import ResetIcon from './ResetIcon'

interface SearchInputProps {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  resetValue: () => void
}

const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, resetValue }) => {
  return (
    <div className={styles.search_input_wrapper}>
      <button type='button' className={styles.search__btn}>
        <SearchIcon />
      </button>
      <input type='text' className={styles.search_input} value={value} onChange={onChange} placeholder='Поиск ботов' />
      {value !== '' && (
        <button type='button' className={styles.reset__btn} onClick={resetValue}>
          <ResetIcon />
        </button>
      )}
    </div>
  )
}

export default SearchInput
