import React from 'react'
import styles from './styles.module.scss'

const AlfAssistantHelpQuestionIcon = ({ isShown }) => {
  return (
    <svg
      className={styles.svg}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={styles.path}
        d='M7.53453 8.425C7.91953 7.73 8.65953 7.32 9.08953 6.705C9.54453 6.06 9.28953 4.855 7.99953 4.855C7.15453 4.855 6.73953 5.495 6.56453 6.025L5.26953 5.48C5.62453 4.415 6.58953 3.5 7.99453 3.5C9.16953 3.5 9.97453 4.035 10.3845 4.705C10.7345 5.28 10.9395 6.355 10.3995 7.155C9.79953 8.04 9.22453 8.31 8.91453 8.88C8.78953 9.11 8.73953 9.26 8.73953 10H7.29453C7.28953 9.61 7.22953 8.975 7.53453 8.425ZM8.99953 12C8.99953 12.55 8.54953 13 7.99953 13C7.44953 13 6.99953 12.55 6.99953 12C6.99953 11.45 7.44953 11 7.99953 11C8.54953 11 8.99953 11.45 8.99953 12Z'
        fill={isShown ? '#FFF' : '#95999E'}
      />
      <rect
        className={styles.rect}
        x='0.5'
        y='0.5'
        width='15'
        height='15'
        rx='7.5'
        stroke={isShown ? '#FFF' : '#95999E'}
      />
    </svg>
  )
}

export default AlfAssistantHelpQuestionIcon
