import React from 'react'
import styles from './styles.module.scss'
import { WhiteDotIcon } from './components/images/WhiteDotIcon'
import { CommandCatalog } from 'components/Editor/ui/CommandCatalog'
import { SelectedItemProps } from 'components/Editor/ui/CommandCatalog/components/CommandItem/CommandItem'
import { useAppDispatch } from 'app/redux/hooks'
import { createNewCommand, loadCommandInfo } from 'components/Editor/redux/commandEditorSlice'
import { setIsEditedCommand, setIsOpenAssistantChat } from 'app/redux/assistantSlice'
import { AIMenuItem } from 'components/Editor/ui/AIGenPanel/AIMenuItem'

interface ALFMobileCommandMenuProps {
  handleApplyCommand: (text: string) => void
  handleCreateCommandFromText: () => void
  inputValue: string
}

export const ALFMobileCommandMenu = React.forwardRef(
  (props: ALFMobileCommandMenuProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const { handleApplyCommand, handleCreateCommandFromText, inputValue } = props
    const dispatch = useAppDispatch()
    const commandRef = React.useRef(null)
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    const openPopupHandler = () => {
      setIsModalOpen(true)
    }

    const handleClose = () => {
      setIsModalOpen(false)
    }

    const handleSelectCommand = React.useCallback(
      (item: SelectedItemProps) => {
        handleApplyCommand(item.templateText)
      },
      [handleApplyCommand],
    )
    const handleOpenCommand = React.useCallback(
      (id: number) => {
        dispatch(loadCommandInfo(id)).then(() => {
          dispatch(setIsOpenAssistantChat(false))
          dispatch(setIsEditedCommand(true))
        })
      },
      [dispatch, loadCommandInfo],
    )
    return (
      <>
        <div className={styles.menu} ref={ref}>
          <AIMenuItem
            icon={<WhiteDotIcon />}
            title='Создать шаблон на основе команды'
            onClick={handleCreateCommandFromText}
            blocked={inputValue.length === 0}
          />

          <div className={styles.catalog}>
            <CommandCatalog onItemSelect={handleSelectCommand} onEyeClick={handleOpenCommand} />
          </div>
        </div>
      </>
    )
  },
)
