import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { apiTypes } from 'app/apiTypes'
import { api } from 'app/api'
import { AppDispatch, RootState } from './store'
import type { PayloadAction } from '@reduxjs/toolkit'

export const getPlanBalance = createAsyncThunk('plan/balance', async () => {
  const response = await api.get<apiTypes.BalanceType>(`subscription/account/`)
  return response.data
})

export const getCurrentPlan = createAsyncThunk('plan/current', async () => {
  const response = await api.get<apiTypes.PlanType>(`subscription/my/`)
  return response.data
})

interface UserPlan {
  current: apiTypes.PlanType
  balance: apiTypes.BalanceType
}

export const planSlice = createSlice({
  name: 'plan',
  initialState: {
    current: {
      tariff: {
        id: null,
        name: '',
        description: '',
        payPerMonth: '',
        isDefault: false,
        tokens: null,
        discLimit: null,
        discLimitAsGb: null,
      },
      balance: null,
      start: '',
      end: null,
      payPerMonth: '',
      tokens: null,
      loadedSpace: null,
      discLimit: null,
    },
    balance: {
      balance: '',
    },
  } as UserPlan,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getPlanBalance.fulfilled, (state, { payload }) => {
      state.balance = payload
    })

    builder.addCase(getCurrentPlan.fulfilled, (state, { payload }) => {
      state.current = payload
    })

    // builder.addMatcher(
    //   (action) => action.type.endsWith('/fulfilled'),
    //   (state) => {
    //     state.status = 'success'
    //   },
    // )
    // builder.addMatcher(
    //   (action) => action.type.endsWith('/pending'),
    //   (state) => {
    //     state.status = 'loading'
    //   },
    // )
    // builder.addMatcher(
    //   (action) => action.type.endsWith('/rejected'),
    //   (state) => {
    //     state.status = 'rejected'
    //   },
    // )
  },
})

// export const {} = userSlice.actions

export default planSlice.reducer
