import React from 'react'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const isActiveFinalScreenSlice = createSlice({
  name: 'isActiveFinalScreen',
  initialState: [
    {
      isActiveFinalScreen: false,
    },
  ],
  reducers: {
    changedActiveFinalScreen(state, { payload }: PayloadAction<{ value: boolean }>) {
      state[0].isActiveFinalScreen = payload.value
    },
  },
})

export const { changedActiveFinalScreen } = isActiveFinalScreenSlice.actions
export default isActiveFinalScreenSlice.reducer
