import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  concat,
  split,
  defaultDataIdFromObject,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { api } from 'app/api'
import { getHttpUrl, getWsUrl } from './helpers'
import generatedIntrospection from '__generated__/generatedIntrospection'

const httpUrl = getHttpUrl()
const wsUrl = getWsUrl()

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: api.userToken ? `Bearer ${api.userToken}` : '',
    },
  })

  return forward(operation)
})

const httpLink = new HttpLink({
  uri: httpUrl,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: wsUrl,
  }),
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const client = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    dataIdFromObject(responseObject) {
      if (responseObject.pageId) {
        return `${responseObject.__typename}:${responseObject.pageId}`
      } else if (responseObject.questionId) {
        return `${responseObject.__typename}:${responseObject.questionId}`
      } else if (responseObject.itemSetId) {
        return `${responseObject.__typename}:${responseObject.itemSetId}`
      } else if (responseObject.itemQuestionId) {
        return `${responseObject.__typename}:${responseObject.itemQuestionId}`
      } else {
        return defaultDataIdFromObject(responseObject)
      }
    },
  }),
})

export default client
