import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { variablesQuery } from 'apollo/variables'
import classes from './styles.module.scss'
import clsx from 'clsx'
import { DocumentVariables } from 'components/DocumentVariables/DocumentVariables'
import { DocumentVariablesItemModal } from 'components/DocumentVariables/DocumentVariablesItemModal/DocumentVariablesItemModal'
import { DocumentVariableStoredList, DocumentVariablesSource } from 'components/DocumentVariables/interfaces'
import { WhiteDotIcon } from 'components/AlfAssistantModal/components/AlfAssisstantCommandMenu/components/images/WhiteDotIcon'

interface ShortVariable {
  allVars: {
    name: string
    value: string
  }[]
}
interface IVariablesPickerOptions {
  name: string
  value: string
}

interface AlfAssistantVariablesMenuProps {
  onSelectVariable: (variableName: string) => void
  inputValue: string
  textToFilter: string
}

const AlfAssistantVariablesMenu = (props: AlfAssistantVariablesMenuProps) => {
  const { onSelectVariable, textToFilter } = props
  const [selectedVariable, setSelectedVariable] = useState<IVariablesPickerOptions | null>(null)
  const { variables, refetch } = useQuery<DocumentVariablesSource>(variablesQuery.GET_VARIABLES)
  const [isDocumentVariablesModalOpen, setIsDocumentVariablesModalOpen] = useState(false)
  const [errorIncludeName, setErrorIncludeName] = React.useState(false)
  const [variablesList, setVariablesList] = React.useState<DocumentVariableStoredList>([])
  const { loading, data, previousData, error } = useQuery<ShortVariable>(variablesQuery.GET_SHOT_VARIABLES, {
    notifyOnNetworkStatusChange: true,
  })
  const changeIncludesName = (name: string, id: string) => {
    if (name && name.length > 0) {
      const matchedName = variablesList.find((item) => item.name.toLowerCase() === name.toLowerCase())
      if (matchedName && matchedName.id !== id) {
        setErrorIncludeName(true)
      } else {
        setErrorIncludeName(false)
      }
    }
  }
  const handleRefetch = useCallback(() => {
    refetch()
  }, [refetch])
  const openDocumentVariablesModal = () => {
    setIsDocumentVariablesModalOpen(true)
  }
  const closeDocumentVariablesModal = () => {
    setIsDocumentVariablesModalOpen(false)
  }
  if (error) {
    console.error(error)
  }

  function handleSelectCommand(item: IVariablesPickerOptions) {
    onSelectVariable(item.value)
    setSelectedVariable(item)
  }

  const filteredOptions = useMemo(() => {
    if (data || previousData) {
      const filteredText = textToFilter.toLowerCase()
      return (data || previousData).allVars
        .filter((item) => {
          if (filteredText) {
            return item.name.toLowerCase().includes(filteredText)
          }
          return true
        })
        .map((item) => ({ name: item.name, value: item.value }))
    } else {
      return []
    }
  }, [data, previousData, textToFilter])

  return (
    <div className={classes.AlfAssistantVariablesMenu_poppup}>
      <button type='button' className={classes.CreateVariablePoppup} onClick={openDocumentVariablesModal}>
        <WhiteDotIcon />
        <span className={classes.AlfAssistantVariablesMenu_poppup_text}>Создать переменную</span>
      </button>
      {!loading && filteredOptions.length > 0 && (
        <ul>
          {filteredOptions.map((option, i: number) => (
            <li key={i} onClick={() => handleSelectCommand(option)}>
              <span className={classes.AlfAssistantVariablesMenu_poppup_text}>{option.name}</span>
            </li>
          ))}
        </ul>
      )}
      {filteredOptions.length === 0 && <div className={classes.NoVariables}>Нет переменных</div>}
      {isDocumentVariablesModalOpen && (
        <DocumentVariablesItemModal
          modalOpen={isDocumentVariablesModalOpen}
          handleCloseModal={closeDocumentVariablesModal}
          isNew
          onSaveVariable={handleRefetch}
          errorIncludeName={errorIncludeName}
          changeIncludesName={changeIncludesName}
        />
      )}
      {/* {error && <div>Error occurred while fetching data</div>} */}
    </div>
  )
}

export default AlfAssistantVariablesMenu
