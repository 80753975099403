import React from 'react'
import styles from './styles.module.scss'
import { CopyMessageMobileIcon } from './components/CopyMessageMobileIcon'
import { CreateTemplateMobileIcon } from './components/CreateTemplateMobileIcon'

interface MessageMobileMenuProps {
  handleCreateTemplate?: () => void
  handleCopyMessage: () => void
  isAnswerMessage: boolean
}

export const MessageMobileMenu = ({
  handleCreateTemplate,
  handleCopyMessage,
  isAnswerMessage,
}: MessageMobileMenuProps) => {
  return (
    <div className={styles.menu}>
      {!isAnswerMessage && (
        <button className={styles.button} onClick={handleCreateTemplate} onTouchStart={handleCreateTemplate}>
          <CreateTemplateMobileIcon />
        </button>
      )}
      <button className={styles.button} onClick={handleCopyMessage} onTouchStart={handleCopyMessage}>
        <CopyMessageMobileIcon />
      </button>
    </div>
  )
}
