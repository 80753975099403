import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames/bind'
import styles from './style.module.scss'
import clsx from 'clsx'
import { Soon } from 'components/UI/Soon/Soon'

type ButtonType =
  | 'primary-red'
  | 'primary-blue'
  | 'primary-yellow'
  | 'primary-gray'
  | 'secondary-red'
  | 'naked'
  | 'secondary-blue'
  | 'invisible'
  | 'yellow'
  | 'yellow-signIn'
  | 'logo-button'
  | 'transparent'
  | 'table'
  | 'gray'
  | 'gray-hover-yellow'
  | 'popover'
  | 'popover-mobile'
  | 'default'

interface ButtonProps {
  type: ButtonType
  children: ReactNode
  buttonType?: 'button' | 'submit'
  fullWidth?: boolean
  disabled?: boolean
  uniqName?: string
  onClick?: (e) => void
  isSoon?: boolean
  isLoading?: boolean
  isActive?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  type,
  children,
  fullWidth = true,
  disabled = false,
  uniqName = 'Не указано',
  onClick,
  buttonType = 'button',
  isSoon = false,
  isLoading = false,
  isActive,
}: ButtonProps): ReactElement => {
  const cx = classNames.bind(styles)
  const className = cx(
    'button',
    { [`button-${type}`]: type },
    { buttonFullWidth: fullWidth },
    { 'button-disabled': disabled },
  )

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (onClick) {
      event.preventDefault()
      event.stopPropagation()
      onClick(event)
    }
  }

  return (
    <div className={styles.btnWrapper}>
      {isSoon ? <Soon isBtn /> : null}
      <button
        type={buttonType}
        className={clsx(className, isLoading && styles.loading, isActive && styles.active)}
        disabled={disabled || isLoading}
        data-uniq-name={uniqName}
        onClick={clickHandler}
      >
        {children}
      </button>
    </div>
  )
}
