import React, { forwardRef, ForwardedRef } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { ChatAvatar } from 'components/UI-ALF/ChatAvatar/ChatAvatar'

interface Assistant {
  chatUid: string
  id: number
  name: string
  description: string
  avatar: string
}

interface AssistantListRemnantProps {
  assistants: Assistant[]
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  handleOpenChat: ({ id, chatUid }: { id: number; chatUid: string }) => void
}

const avatar = {
  width: '24px',
  height: '24px',
  background: '#7B61FF',
}

const cameraSize = {
  width: '17px',
}

const AssistantListRemnant = (props: AssistantListRemnantProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { assistants, isOpen, setOpen, handleOpenChat } = props
  const onClickAssistant = (id: number, chatUid: string) => {
    setOpen(false)
    handleOpenChat({ id, chatUid })
    console.log(chatUid)
  }
  return (
    isOpen && (
      <div ref={ref} className={clsx(styles.assistantsRemnantList)}>
        {assistants.map((assistant, index) => (
          <button
            key={index}
            className={styles.assistant}
            onClick={() => onClickAssistant(assistant.id, assistant.chatUid)}
          >
            <div className={clsx(styles.nonСlickable, styles.avatarWrapper)}>
              <ChatAvatar style={avatar} icon={assistant.avatar} cameraSize={cameraSize} />
            </div>
            <span className={styles.span}>{assistant.name}</span>
          </button>
        ))}
      </div>
    )
  )
}

export default forwardRef(AssistantListRemnant)
