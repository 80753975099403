import React from 'react'
import { useAppDispatch } from 'app/redux/hooks'
import { setIsShowMobileToolbar, setIsHeaderEditMode } from '../../../../pages/document/redux/documentSlice'
import pencilIcon from '.././../../../assets/icons/pencilIcon.svg'
import styles from './style.module.scss'

export const PencilBtn = () => {
  const dispatch = useAppDispatch()
  const handleEdit = () => {
    dispatch(setIsHeaderEditMode(true))
    dispatch(setIsShowMobileToolbar(true))

    // dispatch(setIsAllowedToEdit(true))
    // dispatch(setIsEditMode(true))
  }

  return (
    <button onClick={handleEdit} className={styles.pencilEdit}>
      <img className={styles.imageIcon} src={pencilIcon} alt='pencil-icon' />
    </button>
  )
}
