import React from 'react'
import hamburgerIcon from '../../../../assets/icons/hamburger.svg'
import { setIsOpenSidemenu } from 'pages/disk/redux/allProjects'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import styles from './style.module.scss'

export const BurgerBtn = () => {
  const handleToggle = () => {
    console.log('burgerClick')
  }

  const dispatch = useAppDispatch()

  return (
    <button className={styles.burger} onClick={() => dispatch(setIsOpenSidemenu(true))}>
      <img src={hamburgerIcon} alt='burger-icon' />
    </button>
  )
}
