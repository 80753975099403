import React, { useState, FC, memo } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import SearchInput from './components/SearchInput/SearchInput'
import { CloseIcon } from './components/icons/CloseIcon'
import { AlfBotAssistants } from './components/AlfBotAssistants/AlfBotAssistants'
import { PlusIcon } from './components/icons/PlusIcon'
import { setIsOpenCreateAssistant } from 'app/redux/alfAssistantSlice'
import { useAppDispatch } from 'app/redux/hooks'

interface IAlfAssistantBotListModalProps {
  handleCloseModal: () => void
  isOpen: boolean
}

const hover = {
  cursor: 'pointer',
}

export const AlfAssistantBotListModal: FC<IAlfAssistantBotListModalProps> = memo((props) => {
  const { handleCloseModal, isOpen } = props
  const botsMock = [
    { id: 0, title: 'Чат-бот' },
    // { id: 1, title: 'Художественный бот' },
  ]
  const botFilterMock = [
    { id: 0, title: 'Все' },
    { id: 1, title: 'Модели ИИ' },
    { id: 2, title: 'Социальные платформы' },
  ]

  const dispatch = useAppDispatch()
  const [botFilter, setBotFilter] = useState(botFilterMock[0])
  const [selectedBot, setSelectedBot] = useState(botsMock[0])
  const [searchBot, setSearchBot] = useState('')

  const handleChangeSearchBot = (e) => {
    setSearchBot(e.target.value)
  }
  const resetValue = () => {
    setSearchBot('')
  }
  const handleChangeTubBot = (bot) => {
    setSelectedBot(bot)
  }
  const handleChangeBotFilter = (botFilter) => {
    setBotFilter(botFilter)
  }
  const handleClickCreateAssistant = () => {
    dispatch(setIsOpenCreateAssistant(true))
  }

  return (
    <div className={clsx(styles.overlay, { [styles.overlay_active]: isOpen })} onClick={handleCloseModal}>
      <div className={clsx(styles.modal, { [styles.modal_active]: isOpen })} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <div className={styles.wrapper}>
            <h4 className={styles.title}>Все боты</h4>
          </div>
          <button className={styles.button} onClick={handleCloseModal}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.tabs_wrapper}>
          <div className={styles.tubs}>
            {botsMock.map((bot) => (
              <button
                key={bot.id}
                className={clsx(styles.tub, { [styles.tub_active]: selectedBot.id === bot.id })}
                onClick={() => handleChangeTubBot(bot)}
              >
                <p className={styles.tubText}>{bot.title}</p>
              </button>
            ))}
          </div>
        </div>
        <SearchInput value={searchBot} onChange={handleChangeSearchBot} resetValue={resetValue} />
        {/* <div className={styles.bot_filter_btn_wrapper}>
          {botFilterMock.map((filterItem) => (
            <button
              key={filterItem.id}
              type='button'
              onClick={() => handleChangeBotFilter(filterItem)}
              className={clsx(styles.bot_filter_btn, {
                [styles.bot_filter_btn_active]: botFilter.id === filterItem.id,
              })}
            >
              {filterItem.title}
            </button>
          ))}
        </div> */}
        <div className={styles.assistant_wrapper} id='wrapper'>
          <AlfBotAssistants searchFilter={searchBot} style={hover} isClickable handleCloseModal={handleCloseModal} />
        </div>
        <button type='button' className={styles.create_assistant_btn} onClick={handleClickCreateAssistant}>
          <PlusIcon />
          Создать ассистента
        </button>
      </div>
    </div>
  )
})
