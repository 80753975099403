import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'
import { IDocument } from '../types'

export const getDocuments: any = createAsyncThunk('document/getDocuments', (folderId: string) => {
  return api.get(`folders/${folderId}/document/`)
})

// export const getDocument: any = createAsyncThunk('document/getDocument', (documentId: string) => {
//   return api.get(`folders/documents/${documentId}/`)
// })

const recursiveUpdateDoc = (array: IDocument[], documentId: string, payload: { [key: string]: any }) => {
  return array.map((document: IDocument) => {
    if (Number(document.id) === Number(documentId)) {
      return { ...document, ...payload }
    } else {
      if (document.children) {
        return { ...document, children: recursiveUpdateDoc(document.children, documentId, payload) }
      }
    }
  })
}

const recursiveAddDoc = (document: IDocument, documentId: string, payload: IDocument) => {
  if (Number(document.id) === Number(documentId)) {
    return { ...document, children: [...document.children, payload] }
  } else {
    if (document.children) {
      return {
        ...document,
        children: document.children.map((childDocument) => recursiveAddDoc(childDocument, documentId, payload)),
      }
    }
  }
}

const recursiveDeleteDoc = (array: IDocument[], documentId: string) => {
  return array.filter((document: IDocument) => {
    if (document.children) {
      document.children = recursiveDeleteDoc(document.children, documentId)
    }
    return Number(document.id) !== Number(documentId)
  })
}

interface ReadConfirmation {
  id: number
  documentId: number
  userId: {
    id: string
    email: string
    firstName: string
    lastName: string
  }
}

interface IInitialState {
  status: string
  documents: IDocument[]
  rootDocument: IDocument | null
  selectElement: {
    type: 'document' | 'text'
    document: IDocument
    text?: string
  }
  historyLength: number
  isOpenSearch: boolean
  isAllowedToEdit: boolean
  isHeaderEditMode: boolean
  isEditMode: boolean
  isShowMobileToolbar: boolean
  isActiveToolbarButton: boolean
  loadAudio: string
  isReadDocumentMode: boolean
  isReadDocument: boolean
  isVoiceDocumentMode: boolean
  isRedacting: boolean
  isOpenSidePanel: boolean
  documentContent: string
  enableReadingConfirmation: boolean
  readConfirmation: ReadConfirmation[]
  documentLayoutType: string
  documentLayoutPosition: string
  isOpenPopupForAI: boolean
  isOpenPopupForAddComand: boolean
  isOpenPopupWithResultOptions: boolean
  isHandleAddComand: boolean
  isLoadingImage: boolean
}

export const documentSlice = createSlice({
  name: 'document',
  initialState: {
    status: '',
    documents: [],
    rootDocument: null,
    selectElement: null,
    historyLength: 0,
    isOpenSearch: false,
    isAllowedToEdit: false,
    isHeaderEditMode: false,
    isEditMode: false,
    isShowMobileToolbar: false,
    isActiveToolbarButton: false,
    isReadDocumentMode: false,
    isReadDocument: false,
    isVoiceDocumentMode: false,
    isRedacting: false,
    isOpenSidePanel: true,
    enableReadingConfirmation: false,
    readConfirmation: [],
    documentContent: '',
    documentLayoutType: 'standart',
    documentLayoutPosition: 'middle',
    isOpenPopupForAI: false,
    isOpenPopupForAddComand: false,
    isOpenPopupWithResultOptions: false,
    isHandleAddComand: false,
    isLoadingImage: false,
  } as IInitialState,
  reducers: {
    setLoadingImage(state, { payload }) {
      state.isLoadingImage = payload
    },
    setDocumentContent(state, { payload }) {
      state.documentContent = payload
    },
    setDocumentLayoutType(state, { payload }) {
      state.documentLayoutType = payload
    },
    setDocumentLayoutPosition(state, { payload }) {
      state.documentLayoutPosition = payload
    },
    setRedacting(state, { payload }) {
      state.isRedacting = payload.type
    },
    setRootDocument(state, { payload }) {
      state.rootDocument = { ...state.rootDocument, ...payload }
    },
    setVoiceDocumentMode(state, { payload }) {
      state.isVoiceDocumentMode = payload
    },
    setSelectElement(state, { payload }) {
      state.selectElement = payload
    },
    setHistoryLength(state, { payload }) {
      state.historyLength = payload
    },
    setIsOpenSearch(state, { payload }) {
      state.isOpenSearch = payload
    },
    setIsOpenSidePanel(state, { payload }) {
      state.isOpenSidePanel = payload
    },
    setIsAllowedToEdit(state, { payload }) {
      state.isAllowedToEdit = payload
    },
    setIsHeaderEditMode(state, { payload }) {
      state.isHeaderEditMode = payload
    },
    setIsEditMode(state, { payload }) {
      state.isEditMode = payload
    },
    setIsShowMobileToolbar(state, { payload }) {
      state.isShowMobileToolbar = payload
    },
    setIsActiveToolbarButton(state, { payload }) {
      state.isActiveToolbarButton = payload
    },
    updateNameDoc(state, { payload }) {
      const children = recursiveUpdateDoc(state.rootDocument.children, payload.documentId, { name: payload.name })
      state.rootDocument = { ...state.rootDocument, children }
    },
    updateDocOrder(state, { payload }) {
      const children = recursiveUpdateDoc(state.rootDocument.children, payload.documentId, {
        docOrder: payload.docOrder,
      })
      state.rootDocument = { ...state.rootDocument, children }
    },
    updateIsRenameDoc(state, { payload }) {
      const children = recursiveUpdateDoc(state.rootDocument.children, payload.documentId, {
        isRename: payload.isRename,
      })
      state.rootDocument = { ...state.rootDocument, children }
    },
    updateLogoDoc(state, { payload }) {
      const children = recursiveUpdateDoc(state.rootDocument.children, payload.documentId, {
        documentLogo: payload.documentLogo,
      })
      state.rootDocument = { ...state.rootDocument, children }
    },
    updateAddDoc(state, { payload }) {
      state.rootDocument = recursiveAddDoc(state.rootDocument, payload.documentId, payload.newDocument)
    },
    addDocToEnd(state, { payload }) {
      state.rootDocument.children = [...state.rootDocument.children, payload.newDocument]
    },
    updateDeleteDoc(state, { payload }) {
      const children = recursiveDeleteDoc(state.rootDocument.children, payload.documentId)
      state.rootDocument = { ...state.rootDocument, children }
    },
    setIsReadDocumentMode(state, { payload }) {
      state.isReadDocumentMode = payload
    },
    setIsReadDocument(state, { payload }) {
      state.isReadDocument = payload
    },
    setIsOpenPopupForAI(state, { payload }) {
      state.isOpenPopupForAI = payload
    },
    setIsOpenPopupForAddComand(state, { payload }) {
      state.isOpenPopupForAddComand = payload
    },
    setIsOpenPopupWithResultOptions(state, { payload }) {
      state.isOpenPopupWithResultOptions = payload
    },
    setIsHandleAddComand(state, { payload }) {
      state.isHandleAddComand = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocuments.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload.data.documents
      state.status = 'success'
    })

    builder.addCase(getDocuments.rejected, (state) => {
      state.status = 'error'
    })
    // builder.addCase(getDocument.pending, (state) => {
    //   state.status = 'loading'
    // })
    // builder.addCase(getDocument.fulfilled, (state, { payload }) => {
    //   state.rootDocument = payload.data
    //   state.status = 'success'
    // })
    // builder.addCase(getDocument.rejected, (state) => {
    //   state.status = 'error'
    // })
  },
})

export const {
  setLoadingImage,
  setRootDocument,
  setSelectElement,
  setHistoryLength,
  setIsOpenSearch,
  setIsAllowedToEdit,
  setIsHeaderEditMode,
  setIsEditMode,
  setIsShowMobileToolbar,
  setIsActiveToolbarButton,
  updateNameDoc,
  updateLogoDoc,
  updateAddDoc,
  updateDeleteDoc,
  setIsReadDocumentMode,
  setIsReadDocument,
  setVoiceDocumentMode,
  setRedacting,
  setDocumentContent,
  setIsOpenSidePanel,
  updateIsRenameDoc,
  updateDocOrder,
  setDocumentLayoutType,
  setDocumentLayoutPosition,
  setIsOpenPopupForAI,
  setIsOpenPopupForAddComand,
  setIsOpenPopupWithResultOptions,
  setIsHandleAddComand,
} = documentSlice.actions

export default documentSlice.reducer
