import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { BurgerMenu } from '../BurgerMenu/BurgerMenu'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { makeAnchorScroll } from '../../../../helpers/makeAnchorScroll'
import { LanguagePopup } from './LanguagePopup'
import { Logo } from '../../../../components/Logo'
import { HeaderNavBar } from './HeaderNavBar/HeaderNavBar'
import { HeaderButton } from './HeaderButton/HeaderButton'
import useWindowSize from 'lib/hooks/useWindowSizeHook'
import { HeaderBurgerMenuIcon } from './HeaderBurgerMenuIcon/HeaderBurgerMenuIcon'

export const Header = () => {
  const [isRegistryVisible, setisRegistryVisible] = useState(true)
  const [modal, setModal] = useState(false)
  const { t } = useTranslation()
  const { width } = useWindowSize()
  const isDesktop = width > 820
  const isMobile = width < 470

  useEffect(() => {
    if (localStorage.getItem('JWT')) {
      setisRegistryVisible(false)
    }
  }, [])

  const hanleBurgerMenuClick = () => {
    setModal(!modal)
  }
  const containerStyle = isRegistryVisible ? styles.link__container : styles.link__registred

  return (
    <header className={styles.header}>
      <div className={styles.navbar}>
        <Logo />
        {/* isDesktop && <HeaderNavBar /> */}
      </div>
      {isMobile ? (
        <button className={styles.burger} onClick={hanleBurgerMenuClick}>
          <HeaderBurgerMenuIcon />
        </button>
      ) : (
        <div className={styles.container}>
          <div className={containerStyle}>
            <NavLink to='/login' className={styles.link}>
              {t('header_btn')}
            </NavLink>
          </div>
          {isRegistryVisible && <HeaderButton />}
        </div>
      )}
      {modal && <BurgerMenu isRegistryVisible={isRegistryVisible} setModal={setModal} />}
    </header>
  )
}
