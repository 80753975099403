import React from 'react'

export const PinIconActive = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M19.3493 9.1637L14.7993 4.6137C13.9667 3.79543 12.632 3.79543 11.7993 4.6137L8.96934 7.4037C8.80839 7.56537 8.62273 7.70039 8.41934 7.8037L5.34934 9.3437C4.73248 9.65037 4.30097 10.2352 4.18997 10.9151C4.07897 11.595 4.30205 12.2868 4.78934 12.7737L7.45934 15.4437L4.21934 18.6837C3.92689 18.9765 3.92689 19.4509 4.21934 19.7437C4.36219 19.8809 4.55134 19.9594 4.74934 19.9637C4.94819 19.9635 5.13882 19.8844 5.27934 19.7437L8.51934 16.5037L11.1893 19.1737C11.6763 19.661 12.368 19.8841 13.0479 19.7731C13.7278 19.6621 14.3127 19.2306 14.6193 18.6137L16.1593 15.5237C16.2627 15.3203 16.3977 15.1346 16.5593 14.9737L19.3493 12.1837C20.1808 11.3487 20.1808 9.99868 19.3493 9.1637Z'
        fill='#95999E'
      />
    </svg>
  )
}
