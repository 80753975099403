import React, { useState } from 'react'
import styles from './styles.module.scss'
import Select from 'react-select'
import { useAppDispatch } from 'app/redux/hooks'
import {
  setIsOpenCreateAssistant,
  setTextSourceChecked,
  setLinkSourceChecked,
  setIsOpenSourceModal,
  createAssistant,
  getAssistantList,
  setIsOpenBotListModal,
  setAssistants,
  setIsOpenAssistantSettings,
  setCurrentAssistantId,
  patchAssistant,
  updateViewedBotAssistants,
  removeViewed,
  removeAssistant,
  deleteAssistant,
  removePinned,
  removeViewedAssistant,
  removePinning,
} from 'app/redux/alfAssistantSlice'
import { ChatAvatar } from '../../../UI-ALF/ChatAvatar/ChatAvatar'
import { AlfAssistantInputSection } from './components/AlfAssistantInputSection/AlfAssistantInputSection'
import { SwitchButton } from './components/SwitchButton/SwitchButton'
import {
  customStyles,
  customButtonStyles,
  customStylesAutoLanguage,
  customStylesModelAI,
} from './components/DropdownInfo/DropdownStyles'
import { language, model, source } from './components/DropdownInfo/DropdownValues'
import { Checkbox } from './components/Checkbox/Checkbox'
import { Slider } from './components/Slider/Slider'
import { ArrowRightIcon } from './images/ArrowRightIcon'
import { AddIcon } from './images/AddIcon'
import AlfAssistantCreateAssistBtns from './components/AlfAssistantCreateAssistBtns/AlfAssistantCreateAssistBtns'
import AlfAssistantSettingsAssistBtns from './components/AlfAssistantSettingsAssistBtns.tsx/AlfAssistantSettingsAssistBtns'
import clsx from 'clsx'
import { setChatHistoryUpdate } from 'app/redux/userSlice'

export interface AlfAssistantCreateAssistantProps {
  name?: string
  description?: string
  create?: boolean
  isAvatatar?: string
  id?: number
}

export const AlfAssistantCreateAssistant: React.FC<AlfAssistantCreateAssistantProps> = ({
  name,
  description,
  create,
  isAvatatar,
  id,
}) => {
  const [data, setData] = useState([])
  // Тк аватар пока сохраняется в локал, то после отправки data он не сбрасывается
  const [avatar, setAvatar] = useState(isAvatatar || null)
  const [isAssistantName, setAssistantName] = useState(name || '')
  const [isAssistantNameValid, setIsAssistantNameValid] = useState(!!name || false)
  const [isAssistantDescription, setAssistantDescription] = useState(description || '')
  const [modelTemperature, setModelTemperature] = useState(0)
  const [isAssistantPrompt, setAssistantPrompt] = useState('')
  const [isOpenDeleteAssistantModal, setIsOpenDeleteAssistantModal] = React.useState(false)
  // Чекбокс должен быть активен по умолчанию?
  const [checkedCheckbox, setCheckedCheckbox] = useState(false)
  const [linkAccess, setLinkAccess] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(language[0])
  const [selectedModel, setSelectedModel] = useState(model[0])
  const [selectedSource, setSelectedSource] = useState(source[0])
  const dispatch = useAppDispatch()
  const wrapper = document.getElementById('wrapper')

  const handleLoadAvatar = (newAvatar: any) => {
    setAvatar(newAvatar)
  }

  const handleSliderChange = (value: number) => {
    setModelTemperature(value)
  }

  const handleLinkAccessChange = () => {
    setLinkAccess(!linkAccess)
  }

  const handleCheckCheckbox = () => {
    setCheckedCheckbox(!checkedCheckbox)
  }

  const handleCloseCreateAssistant = () => {
    dispatch(setIsOpenCreateAssistant(false))
  }

  const handleSubmit = async () => {
    const newData = {
      avatar,
      name: isAssistantName,
      description: isAssistantDescription,
      temperature: modelTemperature,
      prompt: isAssistantPrompt,
      addSources: checkedCheckbox,
      linkAccess,
      language: selectedLanguage,
      model: selectedModel,
      source: selectedSource,
    }

    setData([...data, newData])
    create
      ? await dispatch(
          createAssistant({
            name: newData.name,
            description: newData.description,
            avatar: newData.avatar,
            prompt: newData.prompt,
            // engine: newData.
            temperature: newData.temperature / 10,
          }),
        )
      : await dispatch(
          patchAssistant({
            name: newData.name,
            description: newData.description,
            avatar: newData.avatar !== isAvatatar && newData.avatar,
            prompt: newData.prompt,
            // engine: newData.
            temperature: newData.temperature / 10,
            id,
          }),
        ).then((res) => {
          dispatch(setChatHistoryUpdate(res.payload.name))
          dispatch(updateViewedBotAssistants(res.payload))
        })

    setAvatar(null) // поле аватара не очищается после отправки запроса
    setAssistantName('')
    setIsAssistantNameValid(false)
    setAssistantDescription('')
    setModelTemperature(0)
    setAssistantPrompt('')
    setCheckedCheckbox(false)
    setLinkAccess(false)
    setSelectedLanguage(language[0])
    setSelectedModel(model[0])
    setSelectedSource(source[0])
    dispatch(getAssistantList())
      .then((response) => {
        dispatch(setAssistants(response.payload))
      })
      .then(() => wrapper.scrollTo({ left: 0, top: wrapper.scrollHeight, behavior: 'smooth' }))
    dispatch(setIsOpenBotListModal(create))
    dispatch(setIsOpenCreateAssistant(false))
    handleCloseAssistantSettings()
    dispatch(setIsOpenAssistantSettings(false))
  }

  const handleCloseAssistantSettings = () => {
    setIsOpenDeleteAssistantModal(false)
  }
  const handleRemoveAssistant = () => {
    dispatch(removeAssistant(id))
      .then(() => {
        dispatch(deleteAssistant(id))
        dispatch(removeViewed({ id }))
        dispatch(removePinned(id))
        dispatch(setCurrentAssistantId(1))
      })
      .then(() => {
        dispatch(removeViewedAssistant(id))
        dispatch(removePinning(id))
        dispatch(setIsOpenAssistantSettings(false))
      })
    dispatch(getAssistantList())
  }
  const handleOpenDeleteAssistantModal = () => {
    setIsOpenDeleteAssistantModal(true)
  }

  return (
    <section className={clsx(styles.section, { [styles.sectionCreate]: create })}>
      <div className={styles.header}>
        <h1 className={styles.title}>Ассистент</h1>
        <ChatAvatar icon={avatar} handleLoadAvatar={handleLoadAvatar} />
      </div>
      <AlfAssistantInputSection
        title='Название ассистента'
        placeholder='Введите название'
        rows={1}
        onChange={(e) => {
          const inputValue = e.target.value
          setAssistantName(inputValue)
          setIsAssistantNameValid(inputValue.trim() !== '')
        }}
        value={isAssistantName}
      />
      <AlfAssistantInputSection
        title='Описание ассистента'
        placeholder='Опишите, что Ваш ассистент может делать'
        rows={2}
        onChange={(e) => {
          const inputValue = e.target.value
          setAssistantDescription(inputValue)
        }}
        value={isAssistantDescription}
      />
      <label htmlFor='model' className={styles.labelModel}>
        <p className={styles.subtitle}>Модель</p>
        <Select
          name='model'
          options={model}
          styles={customStylesModelAI}
          value={selectedModel}
          onChange={setSelectedModel}
          isSearchable={false}
          menuIsOpen={false}
        />
      </label>
      <div className={styles.block}>
        <h2 className={styles.subtitle}>Температура модели</h2>
        <div className={styles.description}>
          <span>
            Temperature is a parameter of the model that governs the randomness and thus the creativity of the
            responses. A temperature of 0 means the responses will be very straightforward, almost deterministic
            (meaning you almost always get the same response to a given prompt) A temperature of 1 means the responses
            can vary wildly.
          </span>
        </div>
        <Slider min={0} max={10} step={1} onChange={handleSliderChange} value={modelTemperature} />
      </div>
      <AlfAssistantInputSection
        title='Prompt'
        placeholder="/As a customer support agent, please provide a helpful and professional response to the user's question or issue."
        rows={2}
        onChange={(e) => {
          const inputValue = e.target.value
          setAssistantPrompt(inputValue)
        }}
        value={isAssistantPrompt}
      />
      <div className={`${styles.block} ${!isAssistantNameValid ? styles.disabledBlock : ''}`}>
        <div className={styles.sourcesBlock}>
          <h3 className={styles.subtitle}>Источники данных</h3>
          <button className={styles.followSourcesButton}>
            Перейти в источники
            <ArrowRightIcon />
          </button>
        </div>
        <label htmlFor='source' className={styles.label}>
          <Select
            name='source'
            options={source}
            styles={{ ...customStyles, ...customButtonStyles }}
            value={selectedSource}
            onChange={(selectedOption) => {
              setSelectedSource(selectedOption)
              if (selectedOption.value === 'Текст') {
                dispatch(setIsOpenSourceModal(true))
                dispatch(setTextSourceChecked(true))
              } else if (selectedOption.value === 'Ссылка') {
                dispatch(setIsOpenSourceModal(true))
                dispatch(setLinkSourceChecked(true))
              }
            }}
            isSearchable={false}
            components={{
              SingleValue: () => (
                <div className={styles.addSourceButton}>
                  <AddIcon />
                  Добавить источник
                </div>
              ),
            }}
          />
        </label>
        <div className={styles.checkboxBlock}>
          <Checkbox onChange={handleCheckCheckbox} checked={checkedCheckbox} />
          <div className={styles.checkboxSpanBlock}>
            <span className={styles.spanThin}>Включить источники в ответы ассистента</span>
            <span className={styles.spanThin}>
              При активации ваш ассистент будет включать источники для генерации ответа
            </span>
          </div>
        </div>
      </div>
      <label htmlFor='language' className={styles.label}>
        <p className={styles.subtitle}>Язык ответа ассистента</p>
        <Select
          name='language'
          options={language}
          styles={customStylesAutoLanguage}
          value={selectedLanguage}
          onChange={setSelectedLanguage}
          isSearchable={false}
        />
      </label>
      <div className={styles.switchBlock}>
        <SwitchButton onChange={handleLinkAccessChange} checked={linkAccess} />
        <span>Доступ по ссылке для неавторизованных пользавателей</span>
      </div>
      {create ? (
        <AlfAssistantCreateAssistBtns
          isAssistantNameValid={isAssistantNameValid}
          handleSubmit={handleSubmit}
          handleCloseCreateAssistant={handleCloseCreateAssistant}
        />
      ) : (
        <AlfAssistantSettingsAssistBtns
          handleSubmit={handleSubmit}
          isAssistantNameValid={isAssistantNameValid}
          handleCloseAssistantSettings={handleCloseAssistantSettings}
          handleDelete={handleRemoveAssistant}
          handleOpenDeleteAssistantModal={handleOpenDeleteAssistantModal}
          isAssistantName={name}
          isOpenDeleteAssistantModal={isOpenDeleteAssistantModal}
        />
      )}
    </section>
  )
}
