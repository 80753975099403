import { createSlice } from '@reduxjs/toolkit'

export interface activeTabType {
  value: 0 | 1
}

export const activeTabSlice = createSlice({
  name: 'activeTab',
  initialState: {
    value: 0,
  } as activeTabType,
  reducers: {
    setActiveTab(state, action) {
      state.value = action.payload
    },
  },
})

export const { setActiveTab } = activeTabSlice.actions
export default activeTabSlice.reducer
