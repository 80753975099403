import React from 'react'
import styles from './styles.module.scss'
import { CurrentButton } from './components/CurrentButton/CurrentButton'
import { RenameChatHistoryIcon } from './components/RenameChatHistoryIcon'
import { DeleteChatFromHistoryIcon } from './components/DeleteChatFromHistoryIcon'
import { DeleteHistoryModal } from './components/DeleteHistoryModal/DeleteHistoryModal'
import { RenameChatHistoryModal } from './components/RenameChatHistoryModal/RenameChatHistoryModal'
import { alfHistoryFormatDate } from 'helpers/alfHistoryFormatDate'
interface MobileHistoryItemProps {
  chatId: string
  chatName: string
  answer: string
  changeDate: string
  isCurrent: boolean
  deleteHistory: (id: string) => void
  renameChatHistory: (data: { id: string; name: string }) => void
  selectChat: (id: string) => void
  closeModal: () => void
  waiting: boolean
}
export const MobileHistoryItem = (props: MobileHistoryItemProps) => {
  const {
    answer,
    changeDate,
    isCurrent,
    deleteHistory,
    renameChatHistory,
    chatId,
    chatName,
    selectChat,
    closeModal,
    waiting,
  } = props
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [openRenameModal, setOpenRenameModal] = React.useState(false)

  const callbacks = {
    handleDelete: React.useCallback(() => {
      deleteHistory(chatId)
    }, [chatId, deleteHistory]),
    handleRename: React.useCallback(
      (data) => {
        renameChatHistory(data)
      },
      [renameChatHistory],
    ),
    openDeleteModal: () => setOpenDeleteModal(true),
    closeDeleteModal: React.useCallback(() => setOpenDeleteModal(false), [setOpenDeleteModal]),
    openRenameModal: () => setOpenRenameModal(true),
    closeRenameModal: React.useCallback(() => setOpenRenameModal(false), [setOpenRenameModal]),
    handleSelectChat: () => {
      selectChat(chatId)
      closeModal()
    },
  }
  return (
    <div className={styles.item}>
      <div className={styles.titleWrapper}>
        <div className={styles.container}>
          {isCurrent && <CurrentButton />} <h4 className={styles.title}>{chatName}</h4>
          <button
            className={styles.button}
            onClick={callbacks.openRenameModal}
            onTouchStart={callbacks.openRenameModal}
          >
            <RenameChatHistoryIcon />
          </button>
        </div>
      </div>
      <div className={styles.answer} onClick={callbacks.handleSelectChat} onTouchStart={callbacks.handleSelectChat}>
        {answer}
      </div>
      <div className={styles.wrapper}>
        <span className={styles.changeDate}>Изменено: {alfHistoryFormatDate(changeDate)}</span>
        <button className={styles.button} onClick={callbacks.openDeleteModal} onTouchStart={callbacks.openDeleteModal}>
          <DeleteChatFromHistoryIcon />
        </button>
      </div>
      {openDeleteModal && (
        <DeleteHistoryModal
          handleDelete={callbacks.handleDelete}
          closeModal={callbacks.closeDeleteModal}
          waiting={waiting}
        />
      )}
      {openRenameModal && (
        <RenameChatHistoryModal
          renameChat={callbacks.handleRename}
          closeModal={callbacks.closeRenameModal}
          chatName={chatName}
          chatId={chatId}
          waiting={waiting}
        />
      )}
    </div>
  )
}
