import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setCurrentAssistantId } from 'app/redux/alfAssistantSlice'
import { createNewChat, selectChat } from 'app/redux/userSlice'
import AlfBotOneAssistant from './AlfBotOneAssistant'

interface IAlfBotAssistantsProps {
  style?: React.CSSProperties | typeof styles
  hover?: React.CSSProperties
  isClickable?: boolean
  handleCloseModal: () => void
  searchFilter: string
}

export const AlfBotAssistants: React.FC<IAlfBotAssistantsProps> = ({
  searchFilter,
  style,
  hover,
  isClickable,
  handleCloseModal,
}) => {
  const dispatch = useAppDispatch()
  const assistants = useAppSelector((state) => state.alfAssistant.assistants)
  const handleOpenChat = ({ id, chatUid }: { id: number; chatUid: string }) => {
    if (chatUid === null) {
      dispatch(setCurrentAssistantId(id))
      dispatch(createNewChat())
    } else {
      dispatch(setCurrentAssistantId(id))
      dispatch(selectChat(chatUid))
    }
  }

  const filteredAssistants = useMemo(
    () =>
      assistants.filter((assistant) => {
        return assistant.name?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
      }),
    [searchFilter, assistants],
  )

  return (
    <>
      {filteredAssistants.map(
        (assistant) =>
          assistant.id !== 1 && (
            <AlfBotOneAssistant
              key={assistant.id}
              assistant={assistant}
              style={style}
              hover={hover}
              isClickable={isClickable}
              handleOpenChat={handleOpenChat}
              handleCloseModal={handleCloseModal}
              fixed={false}
            />
          ),
      )}
    </>
  )
}
