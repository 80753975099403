import React from 'react'

import privacyPolicy from '../../../../assets/docs/RuPrivacyPolicy.pdf'
import userAgreement from '../../../../assets/docs/RuUserAgreement.pdf'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Telegram } from '../../../../assets/icons/telegramIcon.svg'
import { ReactComponent as YouTube } from '../../../../assets/icons/youTubeIcon.svg'
import { ReactComponent as Vk } from '../../../../assets/icons/vkIcon.svg'
import { ReactComponent as Instagram } from '../../../../assets/icons/InstagramIcon.svg'
import { ReactComponent as Facebook } from '../../../../assets/icons/facebookIcon.svg'
import { Aura } from '../Aura'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <Aura />
      <div className={styles.dots}> </div>

      <div className={styles.footer__container}>
        <div className={styles.footer__main}>
          <h2 className={styles.footer__header}>Следите за нами в соц.сетях</h2>
          <nav className={styles.footer__navSocial}>
            <a href='https://t.me/ALF_ai' className={styles.footer__link} target='_blank' rel='noreferrer'>
              <Telegram />
            </a>
            {/* <a href='https://www.youtube.com/@BrainCloudAI' className={styles.footer__link}>
              <YouTube />
  </a> */}
            <a href='https://vk.com/public216687712' className={styles.footer__link} target='_blank' rel='noreferrer'>
              <Vk />
            </a>
            {/* <a href='#' className={styles.footer__link}>
              <Instagram />
            </a>
            <a href='#' className={styles.footer__link}>
              <Facebook />
</a> */}
          </nav>
          {/* <p className={styles.footer__warning}>
            *Instagram и Facebook признаны экстремистскими организациями и запрещены на территории РФ
</p> */}
        </div>
        <div className={styles.footer__bottom}>
          <p className={styles.footer__title}>@ 2023 BrainCloud AI</p>
          <nav className={styles.footer__navDoc}>
            <a href={privacyPolicy} rel='noreferrer' target='_blank' className={styles.footer__linkDoc}>
              {t('final_link')}
            </a>
            <a href={userAgreement} rel='noreferrer' target='_blank' className={styles.footer__linkDoc}>
              Правила пользования
            </a>
          </nav>
        </div>
      </div>
    </footer>
  )
}
