import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as CopyIcon } from '../../../../assets/icons/CopyIcon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getChats } from 'app/redux/userSlice'
import { setOpenHistoryShareModal } from '../../../../app/redux/alfAssistantSlice'
import { toast } from 'react-toastify'
import { t } from 'i18next'
import { Portal } from 'components/UI-kit/Portal/Portal'

const { REACT_APP_HOST } = process.env

const AlfAssistantChatHistoryShareModal = () => {
  const dispatch = useAppDispatch()

  // получение айдишника текущего ассистента
  const { currentAssistantId } = useAppSelector((state) => state.alfAssistant)

  // значеине инпута со ссылкой на историю перепески
  const historyShareModalLinkValue = useAppSelector((store) => store.alfAssistant.historyShareModalLinkValue)

  // хендлер копирования значения инпута со ссылкой
  const handleCopy = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    navigator.clipboard.writeText(historyShareModalLinkValue)
    toast.info(t('linkshare_access_link_toast'))
  }

  // хендлер клика по кнопке модалки для редиректа на страницу с перепиской
  const handleBtnClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    dispatch(getChats(currentAssistantId)).then((res) => {
      /* создние ссылки на маршрут /history/:id с помощью window.location.origin
       c дополнительным путем /history/${res.payload.currentChat} */
      const url = `${REACT_APP_HOST}/history/${res.payload.currentChat}`
      window.open(url, '_blank')
    })
  }

  // хендлер закрытия модалки
  const handeleCLoseModal = (event) => {
    event.preventDefault()
    dispatch(setOpenHistoryShareModal(false))
  }

  // хендлер нажатия на клваишу 'Escape'
  const handleEscKeydownModal = (event: KeyboardEvent) => {
    event.key === 'Escape' && handeleCLoseModal(event)
  }

  // монитрование случателя нажатия клваиши 'Escape'
  useEffect(() => {
    document.addEventListener('keydown', handleEscKeydownModal)
    return () => {
      document.removeEventListener('keydown', handleEscKeydownModal)
    }
  })

  // рендер модалки вне корневого элеммента
  return (
    <Portal>
      <div className={styles.overlay} onClick={handeleCLoseModal}>
        <div className={styles.shareModal}>
          <button className={styles.shareModal__closeButton} onClick={handeleCLoseModal}>
            <CloseIcon />
          </button>
          <h1 className={styles.shareModal__title}>Поделиться перепиской</h1>
          <p className={styles.shareModal__text}>Любой, кто получит ссылку, сможет прочитать переписку</p>
          <div className={styles.shareModal__linkContainer}>
            <p className={styles.shareModal__link}>{historyShareModalLinkValue}</p>
            <button onClick={handleCopy}>
              <CopyIcon />
            </button>
          </div>
          <button className={styles.shareModal__button} onClick={handleBtnClick}>
            Перейти по ссылке
          </button>
        </div>
      </div>
    </Portal>
  )
}

export default AlfAssistantChatHistoryShareModal
