export const alfHistoryFormatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  const date = new Date(dateString)
  return date.toLocaleDateString('ru-RU', options).replace(' г.', '')
}
