import React from 'react'

export const DeleteIcon = () => {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2 4.5H3.33333H14' stroke='#AAABAD' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5.33203 4.49992V3.16659C5.33203 2.81296 5.47251 2.47382 5.72256 2.22378C5.9726 1.97373 6.31174 1.83325 6.66536 1.83325H9.33203C9.68565 1.83325 10.0248 1.97373 10.2748 2.22378C10.5249 2.47382 10.6654 2.81296 10.6654 3.16659V4.49992M12.6654 4.49992V13.8333C12.6654 14.1869 12.5249 14.526 12.2748 14.7761C12.0248 15.0261 11.6857 15.1666 11.332 15.1666H4.66536C4.31174 15.1666 3.9726 15.0261 3.72256 14.7761C3.47251 14.526 3.33203 14.1869 3.33203 13.8333V4.49992H12.6654Z'
        stroke='#AAABAD'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
