import React from 'react'
import styles from './styles.module.scss'
import { ALFAssistantMobileChat } from './components/ALFAssistantMobileChat/ALFAssistantMobileChat'
import useWindowSize from 'lib/hooks/useWindowSizeHook'
import { ALFAssistantMobileIcon } from './components/ALFAssistantMobileIcon'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setIsOpenAlfChat } from 'app/redux/alfAssistantSlice'
import { Drawer } from '@material-ui/core'
export const ALFAssistantMobile = () => {
  const { width } = useWindowSize()
  const dispatch = useAppDispatch()
  const { isOpenAlfChat } = useAppSelector((state) => state.alfAssistant)
  const handleAlfIconClick = () => {
    dispatch(setIsOpenAlfChat(true))
  }
  const handleClose = () => {
    dispatch(setIsOpenAlfChat(false))
  }

  return (
    <>
      {width < 768 && (
        <>
          <button className={styles.label} onClick={handleAlfIconClick}>
            <ALFAssistantMobileIcon />
          </button>
          <Drawer anchor='right' open={isOpenAlfChat} onClose={handleClose}>
            <ALFAssistantMobileChat />
          </Drawer>
        </>
      )}
    </>
  )
}
