import React from 'react'
import styles from './style.module.scss'
import { AddChatButtonPlusIcon } from './components/AddChatButtonPlusIcon'
import { HistoryIcon } from './components/HistoryIcon'
import Settings from './components/Settings'
import { ChevronIcon } from './components/ChevronIcon'
import Integration from './components/Integration'
import { useAppSelector } from 'app/redux/hooks'

interface ButtonBlockProps {
  handleOpenHistoryModal: () => void
  handleOpenSettingsModal: () => void
  handleCreateNewChat: () => void
  handleOpenHistoryShareModal: () => void
  currentChatLength: number
}

export const ButtonBlock = (props: ButtonBlockProps) => {
  const { chat } = useAppSelector((store) => store.user)
  const { handleOpenHistoryModal, handleCreateNewChat, handleOpenHistoryShareModal, handleOpenSettingsModal } = props
  const activeAssistant = useAppSelector((store) => store.alfAssistant.activeAssistant)
  return (
    <section className={styles.buttonBlock}>
      <div className={styles.wrapper}>
        <button className={styles.addChatButton} onClick={handleCreateNewChat}>
          <AddChatButtonPlusIcon />
          <span>Новый чат</span>
        </button>
        <button className={styles.btnIcons} onClick={handleOpenHistoryModal}>
          <HistoryIcon />
          <span className={styles.textTooltip}>История</span>
        </button>
        {activeAssistant?.isEditable && (
          <button className={styles.btnIcons} onClick={handleOpenSettingsModal}>
            <Settings />
            <span className={styles.textTooltip}>Настройки</span>
          </button>
        )}
        {chat.length > 0 && (
          <button className={styles.btnIcons} onClick={handleOpenHistoryShareModal}>
            <Integration />
            <span className={styles.textTooltip}>Поделиться</span>
          </button>
        )}
        <button className={styles.btnIcons}>
          <ChevronIcon />
          <span className={styles.textTooltip}>Интеграция</span>
        </button>
      </div>
    </section>
  )
}
