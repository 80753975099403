import React from 'react'
import styles from './styles.module.scss'
import { ChatAvatar } from 'components/UI-ALF/ChatAvatar/ChatAvatar'
import { ArrowRightIcon } from '../icons/ArrowRightIcon'
import { PinIcon } from '../icons/PinIcon'
import { PinIconActive } from '../icons/PinIconActive'
import { changeIsPinned, removePinning, pinAssistant, viewedAssistant, addViewed } from 'app/redux/alfAssistantSlice'
import { useAppDispatch } from 'app/redux/hooks'
import { AlfAssistantIconModal } from 'components/AlfAssistantModal/components/AlfAssistantIconModal/AlfAssistantIconModal'

type TAlfAssistant = {
  isViewed: any
  id: number
  avatar: string
  chatUid: string
  name: string
  description: string
  isPinned: boolean
  isEditable: boolean
}

interface IAlfBotOneAssistantProps {
  assistant: TAlfAssistant
  style: React.CSSProperties
  hover: React.CSSProperties
  isClickable: boolean
  handleOpenChat: ({ id, chatUid }: { id: number; chatUid: string }) => void
  handleCloseModal?: () => void
  fixed: boolean
}

const avatarStyle = {
  width: '48px',
  height: '48px',
  margin: '0 16px 0 0',
  background: '#7B61FF',
}

const AlfBotOneAssistant: React.FC<IAlfBotOneAssistantProps> = (props) => {
  const dispatch = useAppDispatch()
  const { assistant, style, hover, isClickable, handleOpenChat, handleCloseModal, fixed } = props

  const togglePin = (assistant: TAlfAssistant) => {
    dispatch(changeIsPinned({ id: assistant.id, isPinned: !assistant.isPinned }))
    if (assistant.isPinned === true) {
      dispatch(removePinning(assistant.id))
    } else {
      dispatch(pinAssistant(assistant.id))
    }
  }
  const addViewedAssistent = () => {
    dispatch(addViewed({ id: assistant.id }))
    if (!assistant.isViewed) {
      dispatch(viewedAssistant(assistant.id))
    }
  }
  return (
    <div
      key={assistant.id}
      className={styles.assistant}
      style={style}
      onClick={() => {
        if (isClickable) {
          handleCloseModal()
          handleOpenChat({ id: assistant.id, chatUid: assistant.chatUid })
        }
        addViewedAssistent()
      }}
    >
      <div className={styles.nonClickable}>
        {assistant.id === 1 ? (
          <AlfAssistantIconModal style={{ width: '48px', height: '48px', margin: '0 16px 0 0' }} />
        ) : (
          <ChatAvatar style={avatarStyle} icon={assistant.avatar} />
        )}
      </div>
      <div className={styles.assistant__inner}>
        <div className={styles.assistant__titleWrapper}>
          <div className={styles.assistant__title_wrapper}>
            <div className={styles.assistant__title} style={hover}>
              <h4>{assistant.name}</h4>
            </div>
            <div className={styles.assistant__title_btns}>
              <button type='button' className={styles.share__btn}>
                <ArrowRightIcon />
                <span className={styles.text__tooltip}>Поделиться</span>
              </button>
              <button
                type='button'
                className={styles.pin__btn}
                onClick={(e) => {
                  e.stopPropagation()
                  togglePin(assistant)
                }}
              >
                {assistant.isPinned ? <PinIconActive /> : <PinIcon />}
                <span className={styles.text__tooltip}>{assistant.isPinned ? 'Открепить' : 'Закрепить'}</span>
              </button>
            </div>
          </div>
          <div className={styles.assistant__description} style={hover}>
            <p>{assistant.description}</p>
          </div>
          {!fixed && (
            <span className={styles.assistant__tags}>{assistant.isEditable ? 'Созданный' : 'Системный'} </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default AlfBotOneAssistant
