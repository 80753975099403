import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { AlfAssistantMessageItem } from '../AlfAssistantMessageItem/AlfAssistantMessageItem'
import { AlfFixedAssistant } from '../AlfAssistantFooter/components/AlfFixedAssistant/AlfFixedAssistant'
import ExtensionLinkBtn from '../ExtensionLinkBtn/ExtensionLinkBtn'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { createNewCommand } from 'components/Editor/redux/commandEditorSlice'
import { setIsEditedCommand, setIsOpenAlfChat } from 'app/redux/alfAssistantSlice'
import { shallowEqual } from 'react-redux'
import { setChatHistoryUpdate } from 'app/redux/userSlice'

export const AlfAssistantChat = () => {
  const dispatch = useAppDispatch()
  const { chatHistory, chat } = useAppSelector((state) => state.user, shallowEqual)
  const { currentAssistantId } = useAppSelector((state) => state.alfAssistant)
  const { assistants } = useAppSelector((state) => state.alfAssistant)
  const currentChat = [...chatHistory, ...chat]

  // меняет имя ассистента в первом сообщении
  useEffect(() => {
    const nameAssistent = assistants.find((assistant) => Number(assistant.id) === currentAssistantId)
    dispatch(setChatHistoryUpdate(nameAssistent?.name))
  }, [dispatch, currentAssistantId])

  const callbacks = {
    handleCreateCommandFromMessage: React.useCallback(
      (content) => {
        dispatch(createNewCommand(content))
        dispatch(setIsOpenAlfChat(false))
        dispatch(setIsEditedCommand(true))
      },
      [dispatch],
    ),
  }

  return (
    <div className={styles.scroll}>
      {currentAssistantId !== 1 && (
        <div className={styles.botAssistantsWrapper}>
          <AlfFixedAssistant />
        </div>
      )}
      <section className={styles.section}>
        {currentChat.map((message, index) => {
          return (
            <AlfAssistantMessageItem
              key={message.id}
              message={message.text}
              answer={message.answer}
              id={message.id}
              index={index}
              handleCreateCommandFromMessage={callbacks.handleCreateCommandFromMessage}
            />
          )
        })}
      </section>
      {currentAssistantId === 1 && <ExtensionLinkBtn />}
    </div>
  )
}
