import {
  AllObjectsFolderType,
  AllObjectsType,
  FileType,
  FolderType,
  LinkType,
  ObjectType,
  PollType,
  DocumentType,
  SortByKeys,
  SortByType,
} from '../folderTypes'
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { api } from 'app/api'
import { InviteUserPayloadType, UserHasAccessType } from '../types/accessTypes'

export const getFoldersTree = createAsyncThunk('folders/getFoldersTree', async () => {
  const response = await api.get<any>(`folders/tree/`)
  return response.data
})
export const getAllObjects = createAsyncThunk('folders/getAllObjects', async () => {
  const response = await api.get<any>(`folders/tree_fast/`)
  return response.data
})
export const getAllSharedObjects = createAsyncThunk('folders/getAllSharedObjects', async () => {
  const response = await api.get<any>(`folders/shared/`)
  return response.data
})
export const getFolders = createAsyncThunk('folders/getFolders', async () => {
  const response = await api.get<any>(`folders/`)
  return response.data
})
export const createFolder = createAsyncThunk('folders/createFolder', async (data: any) => {
  const response = await api.post<any>(`folders/`, data)
  return response.data
})
export const deleteFolder = createAsyncThunk('folders/deleteFolder', async (id: any) => {
  const response = await api.delete<any>(`folders/${id}/`)
  return response.data
})
export const patchFolder = createAsyncThunk('folders/patchFolder', async ({ id, data }: any) => {
  const response = await api.patch<any>(`folders/${id}/`, data)
  return response.data
})
export const getFoldersInvites = createAsyncThunk('folders/getFoldersInvites', async (id: number) => {
  const response = await api.get<UserHasAccessType[]>(`folders/${id}/who_has_access/`)
  return response.data
})

export const inviteUser = createAsyncThunk('folders/inviteUser', async (payload: InviteUserPayloadType) => {
  try {
    const response = await api.post<UserHasAccessType>(`folders/grant_access/`, payload)
    return response.data
  } catch (error) {
    throw new Error(error.response.data.detail)
  }
})

export const createFile = createAsyncThunk(
  'folders/createFile',
  async ({ data, onUploadProgress, cancelToken }: any) => {
    const response = await api.sendFile<any>(`file/`, data, onUploadProgress, cancelToken)
    return response.data
  },
)
export const patchFile = createAsyncThunk('folders/patchFile', async ({ id, data }: any) => {
  const response = await api.patch<any>(`file/${id}/`, data)
  return response.data
})
export const deleteFile = createAsyncThunk('folders/deleteFile', async (id: any) => {
  const response = await api.delete<any>(`file/${id}/`)
  return response.data
})

type InitialStateType = {
  checkedItems: (LinkType | FileType | PollType | DocumentType | AllObjectsFolderType)[]
  newItemId: number
  parentsChain: AllObjectsFolderType[]
  folders: any[]
  status: string
  allFolders: any[]
  allObjects: AllObjectsType
  creationStatus: string
  deleteStatus: string
  downloadStatus: string
  downloads: any
  sorting: {
    type: SortByType
    key: SortByKeys
    direction: 'up' | 'down'
  }
  isModalAccessOpen: boolean
  accessItem: FolderType | ObjectType
  modalParentsChain: AllObjectsFolderType[]
  isMoveModalOpen: boolean
}

export const diskSlice = createSlice({
  name: 'diskSlice',
  initialState: {
    checkedItems: [],
    newItemId: null,
    parentsChain: [],
    folders: [],
    status: null,
    allFolders: [],
    allObjects: {},
    creationStatus: 'idle',
    deleteStatus: 'idle',
    downloadStatus: 'idle',
    downloads: {},
    sorting: {
      type: 'model',
      key: 'model',
      direction: 'up',
    },
    isModalAccessOpen: false,
    accessItem: null,
    modalParentsChain: [],
    isMoveModalOpen: false,
  } as InitialStateType,
  reducers: {
    setIsMoveModalOpen(state, { payload }) {
      state.isMoveModalOpen = payload
    },
    setIsModalAccessOpen(state, { payload }) {
      state.isModalAccessOpen = payload
    },
    setAccessItem(state, { payload }) {
      state.accessItem = payload
    },
    setStatus(state, { payload }) {
      state.status = payload
    },
    addCheckedItem(state, { payload }) {
      state.checkedItems = [...state.checkedItems, payload]
    },
    removeCheckedItem(state, { payload }) {
      state.checkedItems = [...state.checkedItems].filter((item) => item.id !== payload.id)
    },
    setCheckedItems(state, { payload }) {
      state.checkedItems = payload
    },
    setNewItemId(state, { payload }) {
      state.newItemId = payload
    },
    addParentsChain(state, { payload }) {
      state.parentsChain = [...state.parentsChain, payload]
    },
    goBackParentsChain(state, { payload }) {
      if (payload) {
        const lastChainItemIdx = state.parentsChain.findIndex((item) => item.id === payload)
        state.parentsChain = [...state.parentsChain].slice(0, lastChainItemIdx + 1)
      } else {
        state.parentsChain = []
      }
    },
    setModalParentsChain(state, { payload }) {
      state.modalParentsChain = payload
    },
    addModalParentsChain(state, { payload }) {
      state.modalParentsChain = [...state.modalParentsChain, payload]
    },
    goBackModalParentsChain(state, { payload }) {
      if (payload) {
        const lastChainItemIdx = state.modalParentsChain.findIndex((item) => item.id === payload)
        state.modalParentsChain = [...state.modalParentsChain].slice(0, lastChainItemIdx + 1)
      } else {
        state.modalParentsChain = []
      }
    },
    setDownload(state, { payload }) {
      const { ID, progress, filename, source, requestStatus } = payload
      state.downloads = { ...current(state.downloads), [ID]: { filename, progress, source, requestStatus } }
    },
    setSortBy: (state, action) => {
      state.sorting = action.payload
    },
    setSortingDirection: (state, action) => {
      state.sorting.direction = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFoldersTree.fulfilled, (state, { payload }) => {
      state.folders = payload
      state.status = 'success'
    })
    builder.addCase(getFoldersTree.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getFoldersTree.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getAllObjects.fulfilled, (state, { payload }) => {
      state.allObjects = payload
      state.status = 'success'
    })
    builder.addCase(getAllObjects.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getAllObjects.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getAllSharedObjects.fulfilled, (state, { payload }) => {
      state.allObjects = payload
      state.status = 'success'
    })
    builder.addCase(getAllSharedObjects.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getAllSharedObjects.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getFolders.fulfilled, (state, { payload }) => {
      state.allFolders = payload
    })
    builder.addCase(createFolder.pending, (state) => {
      state.creationStatus = 'pending'
    })
    builder.addCase(createFolder.rejected, (state) => {
      state.creationStatus = 'rejected'
    })
    builder.addCase(createFolder.fulfilled, (state) => {
      state.creationStatus = 'fulfilled'
    })
    builder.addCase(createFile.pending, (state) => {
      state.downloadStatus = 'pending'
    })
    builder.addCase(createFile.fulfilled, (state, action) => {
      state.downloadStatus = 'fulfilled'
      const ID = action.meta.arg.id
      const requestStatus = 'fulfilled'
      state.downloads = { ...current(state.downloads), [ID]: { ...current(state.downloads)[ID], requestStatus } }
    })
    builder.addCase(createFile.rejected, (state, action) => {
      state.downloadStatus = 'rejected'
      const ID = action.meta.arg.id
      const requestStatus = 'canceled'
      state.downloads = { ...current(state.downloads), [ID]: { ...current(state.downloads)[ID], requestStatus } }
    })
  },
})

export const {
  addCheckedItem,
  setStatus,
  removeCheckedItem,
  setNewItemId,
  setCheckedItems,
  addParentsChain,
  goBackParentsChain,
  setModalParentsChain,
  addModalParentsChain,
  goBackModalParentsChain,
  setDownload,
  setSortBy,
  setSortingDirection,
  setIsModalAccessOpen,
  setAccessItem,
  setIsMoveModalOpen,
} = diskSlice.actions
export default diskSlice.reducer
