import translationDocummentSettingsEN from './DocumentSettings/en.json'
import translationDocummentSettingsRU from './DocumentSettings/ru.json'
import translationDocummentHeadIconEN from './DocumentHeadIcon/en.json'
import translationDocummentHeadIconRU from './DocumentHeadIcon/ru.json'
import translationBoardBodyEN from './BoardBody/en.json'
import translationBoardBodyRU from './BoardBody/ru.json'
import translationModalDeleteEN from './ModalDelete/en.json'
import translationModalDeleteRU from './ModalDelete/ru.json'
import translationLinkShareEN from './LinkShare/en.json'
import translationLinkShareRU from './LinkShare/ru.json'
import translationUIkitEN from './UI-kit/en.json'
import translationUIkitRU from './UI-kit/ru.json'
import { translationTablesEN, translationTablesRU } from './Tables'

export const translationComponentsEN = {
  ...translationBoardBodyEN,
  ...translationTablesEN,
  ...translationModalDeleteEN,
  ...translationLinkShareEN,
  ...translationDocummentSettingsEN,
  ...translationUIkitEN,
  ...translationDocummentHeadIconEN,
}
export const translationComponentsRU = {
  ...translationBoardBodyRU,
  ...translationTablesRU,
  ...translationModalDeleteRU,
  ...translationLinkShareRU,
  ...translationDocummentSettingsRU,
  ...translationUIkitRU,
  ...translationDocummentHeadIconRU,
}
