import React from 'react'
import styles from './styles.module.scss'
import { Spinner } from 'components/Spinner'
interface DeleteHistoryModalProps {
  title: string
  description: string
  handleDelete: () => void
  waiting: boolean
  closeModal: () => void
  style?: React.CSSProperties
}
export const DeleteHistoryModal = (props: DeleteHistoryModalProps) => {
  const { title, description, handleDelete, waiting, closeModal, style } = props
  const [isWaiting, setIsWaiting] = React.useState(false)
  React.useEffect(() => {
    if (isWaiting) {
      if (!waiting) {
        closeModal()
        setIsWaiting(false)
      }
    }
  }, [closeModal, isWaiting, waiting])

  const callbacks = {
    handleDelete: () => {
      handleDelete()
      setIsWaiting(true)
    },
    handleCancel: () => {
      closeModal()
    },
  }
  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.modal} style={style}>
        {waiting ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            <h4 className={styles.title}>{title}</h4>
            <h5 className={styles.subtitle}>{description}</h5>
            <div className={styles.wrapper}>
              <button className={styles.cancelButton} onClick={callbacks.handleCancel}>
                Отмена
              </button>
              <button className={styles.deleteButton} onClick={callbacks.handleDelete}>
                Удалить
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}
