import { useEvent } from '@dnd-kit/utilities'
import clsx from 'clsx'
import { EyeIcon } from 'components/Editor/icons/EyeIcon'
import { StarIcon } from 'components/Editor/plugins/AIRequestPanelPlugin/icons/StarIcon'
import { StarIconFilled } from 'components/Editor/plugins/AIRequestPanelPlugin/icons/StarIconFilled'
import { StarIconHovered } from 'components/Editor/plugins/AIRequestPanelPlugin/icons/StarIconHovered'
import React, { useCallback, useState } from 'react'
import { useAppSelector } from 'app/redux/hooks'
import { switchFavorite } from '../../model'
import classes from './CommandItem.module.scss'
import { useIsMobile } from 'lib/hooks/useIsMobile'

export interface SelectedItemProps {
  id: number
  favourite: boolean
  title: string
  templateText: string
}

interface AICommandItemProps {
  id: number
  favourite: boolean
  title: string
  templateText: string
  onEyeClick?: (id: number) => void
  onItemSelect?: (item: SelectedItemProps) => void
  disabled?: boolean
  blocked?: boolean
}
export const AICommandItem: React.FC<AICommandItemProps> = ({
  id,
  title,
  favourite,
  templateText,
  onEyeClick,
  onItemSelect,
  disabled = false,
  blocked = false,
}) => {
  const [curFav, setCurFav] = useState(favourite)
  const [starHovered, setStarHovered] = useState(false)
  const [itemHovered, setItemHovered] = useState(false)
  const handleSwitchFavoriteState = useEvent(switchFavorite)
  const isMobile = useIsMobile()
  const handleSwitchFavorite = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      const fav = !curFav
      setCurFav(fav)
      handleSwitchFavoriteState({ id, favourite: fav })
    },
    [handleSwitchFavoriteState, curFav],
  )

  const handleStarEnter = () => {
    setStarHovered(true)
  }

  const handleStarLeave = () => {
    setStarHovered(false)
  }

  const handleItemEnter = () => {
    setItemHovered(true)
  }

  const handleItemLeave = () => {
    setItemHovered(false)
  }

  const handleEyeClick = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      if (onEyeClick) {
        onEyeClick(id)
      }
    },
    [onEyeClick, id],
  )

  const handleItemSelect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      if (onItemSelect) {
        onItemSelect({ id, title, favourite, templateText })
      }
    },
    [onItemSelect, id, favourite, title, templateText],
  )

  return (
    <button
      className={classes.ai_command_item}
      disabled={disabled}
      onMouseEnter={handleItemEnter}
      onMouseLeave={handleItemLeave}
      onClick={handleItemSelect}
    >
      <span onClick={handleSwitchFavorite} onMouseEnter={handleStarEnter} onMouseLeave={handleStarLeave}>
        {starHovered ? <StarIconHovered /> : <>{curFav ? <StarIconFilled /> : <StarIcon />}</>}
      </span>

      <p className={clsx(classes.ai_command_item_title, blocked && classes.ai_command_item_title_blocked)}>{title}</p>

      {(itemHovered && !isMobile) || isMobile ? (
        <span onClick={handleEyeClick} role='button'>
          <EyeIcon />
        </span>
      ) : null}
    </button>
  )
}
