import React from 'react'
import styles from './styles.module.scss'

export const ChevronIcon = () => {
  return (
    <svg
      className={styles.svgFill}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.8 19.3003L15.0833 17.9712L13.1125 16.0003L15.0833 14.0295L13.8 12.7003L10.5 16.0003L13.8 19.3003ZM18.2 19.3003L21.5 16.0003L18.2 12.7003L16.9167 14.0295L18.8875 16.0003L16.9167 17.9712L18.2 19.3003ZM9.58333 24.2503C9.07917 24.2503 8.64742 24.071 8.28808 23.7122C7.92936 23.3529 7.75 22.9212 7.75 22.417V9.58366C7.75 9.07949 7.92936 8.64774 8.28808 8.28841C8.64742 7.92969 9.07917 7.75033 9.58333 7.75033H13.4333C13.6319 7.20033 13.9644 6.75727 14.4307 6.42116C14.8963 6.08505 15.4194 5.91699 16 5.91699C16.5806 5.91699 17.104 6.08505 17.5703 6.42116C18.0359 6.75727 18.3681 7.20033 18.5667 7.75033H22.4167C22.9208 7.75033 23.3526 7.92969 23.7119 8.28841C24.0706 8.64774 24.25 9.07949 24.25 9.58366V22.417C24.25 22.9212 24.0706 23.3529 23.7119 23.7122C23.3526 24.071 22.9208 24.2503 22.4167 24.2503H9.58333ZM9.58333 22.417H22.4167V9.58366H9.58333V22.417ZM16 8.89616C16.1986 8.89616 16.363 8.83108 16.4932 8.70091C16.6227 8.57135 16.6875 8.40727 16.6875 8.20866C16.6875 8.01005 16.6227 7.84566 16.4932 7.71549C16.363 7.58594 16.1986 7.52116 16 7.52116C15.8014 7.52116 15.6373 7.58594 15.5078 7.71549C15.3776 7.84566 15.3125 8.01005 15.3125 8.20866C15.3125 8.40727 15.3776 8.57135 15.5078 8.70091C15.6373 8.83108 15.8014 8.89616 16 8.89616Z'
        fill='#95999E'
      />
    </svg>
  )
}
