import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export const Soon = ({ isBtn = false, isText = false }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <span className={clsx(styles.wrapper, isBtn && styles.isBtn, isText && styles.isText)}>
        {t('subscription_coming_soon')}
      </span>
    </div>
  )
}
