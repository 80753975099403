import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { Portal } from '../../UI-kit/Portal/Portal'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'app/redux/hooks'

export const Drawer = ({
  onClose,
  children,
  width = '1120px',
  noBackground = false,
  isOpen = false,
  order = 0,
  overlay = false,
  btnForVariables = false,
  btnPaddingTop = false,
}) => {
  const openTable = useAppSelector((state) => state.user.openTable)
  const test = useAppSelector((state) => state.allProjects.animationClosed)
  const { t } = useTranslation()
  const style = order === 1 ? { top: `${30 * (openTable.length - 1) * 2}px` } : {}
  return (
    <Portal>
      <div
        className={clsx(
          styles.drawer,
          noBackground && styles.noBackground,
          isOpen && styles.active,
          test && styles.test,
          order === 2 && styles.one,
          overlay && styles.overlay,
        )}
        onClick={onClose}
      />
      <div
        className={`${styles.slider__drawer} ${isOpen && styles.active} ${order === 2 && styles.one}`}
        style={{ width }}
      >
        <div
          style={style}
          className={`${styles.close}  ${btnForVariables && styles.btn__variables} ${
            btnPaddingTop && styles.btn__padding__top
          }`}
        >
          <button className={styles.closeIcon} onClick={onClose}>
            <p className={styles.close_text}>{t('subscription_close')}</p>
          </button>
        </div>
        <div className={styles.children}>{children}</div>
      </div>
    </Portal>
  )
}
