import React from 'react'
import styles from './style.module.scss'
import { ChatIcon } from './ChatIcon'
import { useIsMobile } from 'lib/hooks/useIsMobile'

export const SuggestionLink = () => {
  const isMobile = useIsMobile()
  return (
    <a href='https://alf.canny.io/feature-requests' target='_blank' rel='noreferrer' className={styles.link}>
      <ChatIcon />
      {isMobile ? '' : 'Предложить идею'}
    </a>
  )
}
