import React, { FC } from 'react'
import styles from './styles.module.scss'

type TModalHelpItem = {
  icon: JSX.Element
  title: string
  text1: string
  text2: string
  textInfo: string
  keysItem: string
}

const ModalInfoHelpItem: FC<TModalHelpItem> = ({ icon, title, text1, text2, textInfo, keysItem }) => {
  return (
    <li className={styles.itemList}>
      <div className={styles.titleContainer}>
        {icon}
        <h4 className={styles.title}>{title}</h4>
      </div>
      <p className={styles.text}>
        {text1}
        <span className={styles.span}>{keysItem}</span>
        {text2}
      </p>
      {textInfo && <p className={`${styles.text} ${styles.textInfo}`}>{textInfo}</p>}
    </li>
  )
}

export default ModalInfoHelpItem
