import translationHomeHeaderEN from './HomeHeader/en.json'
import translationHomeHeaderRU from './HomeHeader/ru.json'
import translationHomeProjectsListEN from './ProjectsList/en.json'
import translationHomeProjectsListRU from './ProjectsList/ru.json'
import translationProjectModalEN from './ProjectModal/en.json'
import translationProjectModalRU from './ProjectModal/ru.json'

export const translationHomeMergeEN = {
  ...translationHomeHeaderEN,
  ...translationHomeProjectsListEN,
  ...translationProjectModalEN,
}
export const translationHomeMergeRU = {
  ...translationHomeHeaderRU,
  ...translationHomeProjectsListRU,
  ...translationProjectModalRU,
}
