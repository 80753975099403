import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { api } from 'app/api'
import { IDeletePayment, ICreatePayment, IPostPayment, IPostPromocode } from '../helpers/apiTypes'
import { ICurrencie, ICurrentTariff, IGetSubs, IPaymentHistoryItem, IPromocode, ITariffs } from './../helpers/types'

export const getSubs = createAsyncThunk('payment/getSubs', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get<IGetSubs>(`auth/profile/subs/`)
    return response.data
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const createPayment = createAsyncThunk(
  'payment/createPayment',
  async ({ paymentId, summ, promocode, monthQty }: IPostPayment, { rejectWithValue }) => {
    try {
      const response = await api.post<ICreatePayment>(`card_payments/`, { paymentId, summ, promocode, monthQty })
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const deletePayment = createAsyncThunk(
  'payment/deletePayment',
  async (id: IDeletePayment, { rejectWithValue }) => {
    try {
      await api.delete(`card_payments/${id}/`)
      return id
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const postPromocode = createAsyncThunk(
  'payment/postPromocode',
  async ({ code }: IPostPromocode, { rejectWithValue }) => {
    try {
      const response = await api.post<IPromocode>(`promocodes/`, { code })
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const postCert = createAsyncThunk('payment/postCert', async (promocode: string, { rejectWithValue }) => {
  try {
    const response = await api.post<IPromocode>(`sertificate/`, { promocode })
    return response.data
  } catch (error) {
    return rejectWithValue(error.response.data.error)
  }
})

export const cancelSubscription = createAsyncThunk('payment/cancelSubscription', async (_, { rejectWithValue }) => {
  try {
    const response = await api.post<IGetSubs>(`auth/profile/subs/cancel/`, {})
    return response.data
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    paymentTypes: [
      {
        id: 1,
        value: 'card',
        title: 'Банковская карта',
        disabled: false,
      },
      {
        id: 2,
        value: 'cert',
        title: 'Сертификат',
        disabled: false,
      },
      {
        id: 3,
        value: 'invoice',
        title: 'Счет',
        disabled: true,
      },
    ],
    currencies: [] as ICurrencie[],
    tariffs: {} as ITariffs,
    currentTariff: {} as ICurrentTariff,
    invoices: [] as IPaymentHistoryItem[],
    promocodeDiscount: 1,
  },
  reducers: {
    setPromocodeDiscount(state, { payload }) {
      state.promocodeDiscount = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postPromocode.fulfilled, (state, { payload }) => {
      if (payload.isValid) {
        state.promocodeDiscount = 1 - payload.discount
      } else {
        state.promocodeDiscount = 1
      }
    })
    builder.addCase(postPromocode.rejected, (state, error: any) => {
      throw new Error(error.payload)
    })
    builder.addCase(postCert.rejected, (state, error: any) => {
      throw new Error(error.payload)
    })
    builder.addCase(createPayment.rejected, (state, error: any) => {
      throw new Error(error.payload)
    })
    builder.addCase(deletePayment.fulfilled, (state, { payload }: any) => {
      state.invoices = state.invoices.filter((invoice) => invoice.id !== payload)
    })
    builder.addCase(deletePayment.rejected, (state, error: any) => {
      throw new Error(error.payload)
    })
    builder.addMatcher(isAnyOf(getSubs.fulfilled, cancelSubscription.fulfilled), (state, { payload }) => {
      state.currencies = payload.paymentOptions.currencies
      state.tariffs = payload.paymentOptions.tariffs
      state.currentTariff = payload.currentTariff
      const cardPayments = payload.paymentHistory.cardPayments
        .map((payment) => {
          return { ...payment, paymentType: 'card' }
        })
        .sort((a, b) => b.createdAt - a.createdAt)
      const billPayments = payload.paymentHistory.bankTransfers
        .map((payment) => {
          return { ...payment, paymentType: 'invoice' }
        })
        .sort((a, b) => b.createdAt - a.createdAt)
      const certPayments = payload.paymentHistory.sertificatePayments
        .map((payment) => {
          return {
            id: payment.id,
            monthQty: payment.tariffPeriod,
            summ: 0,
            paymentId: null,
            paidAt: payment.createdAt,
            isPaid: true,
            createdAt: payment.createdAt,
            paymentType: 'cert',
            cert: payment.promocode,
          }
        })
        .sort((a, b) => b.createdAt - a.createdAt)
      state.invoices = [...cardPayments, ...billPayments, ...certPayments]
    })
    builder.addMatcher(isAnyOf(getSubs.rejected, cancelSubscription.rejected), (state, error: any) => {
      throw new Error(error.payload)
    })
  },
})

export const { setPromocodeDiscount } = paymentSlice.actions

export default paymentSlice.reducer
