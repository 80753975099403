import React, { useCallback } from 'react'
import { StarIcon } from 'components/Editor/icons/StarIcon'
import { StarFilledIcon } from 'components/Editor/icons/StarFilledIcon'
import styles from './Favorites.module.scss'

interface FavoritesProps {
  favorite: boolean
  onChangeFavorite: (favorite: boolean) => void
}

export const Favorites: React.FC<FavoritesProps> = ({ favorite, onChangeFavorite }) => {
  const handleToggleeFavorites = useCallback(() => {
    onChangeFavorite(!favorite)
  }, [favorite, onChangeFavorite])

  return (
    <div className={styles.container} onClick={handleToggleeFavorites}>
      {!favorite ? (
        <>
          <StarIcon /> <p className={styles.text}>Добавить в избранное</p>
        </>
      ) : (
        <>
          <StarFilledIcon /> <p className={styles.text}>В избранном</p>
        </>
      )}
    </div>
  )
}
