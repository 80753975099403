import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import styles from './style.module.scss'
import { getPublicChatById } from 'app/redux/userSlice'
import DialogItem from './components/DialogItem/DialogItem'
import DownloadHeaderLink from './components/DownloadHeaderLink/DownloadHeaderLink'
import { Dialog } from './types/chatTypes'
import { useParams } from 'react-router'
import { Spinner } from 'components/Spinner'

const ChatHistoryPage = () => {
  // состояния для активного индекса элемента списка
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const dispatch = useAppDispatch()

  // получение параметров query строки
  const params = useParams<any>()

  // получение состояния загрузки из store
  const waiting = useAppSelector((store) => store.user.waiting)

  // все чаты с ассистентом
  const [chats, setChats] = useState([])

  /* при инициализации страницы получаем айди текущего чата (который сделали публичным при клике на "поделиться")
  из query строки и по этому айдишнику получаем нужный чата */
  useEffect(() => {
    dispatch(getPublicChatById(params.id))
      .then((res: { payload: any }) => {
        if (res.payload) {
          setChats(res.payload.data.payload.messages)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  // получаем из объекта текущего чата массив диалогов, с контентом сообщения и ролью отправителя (ассистент или пользователь)
  const dialogs = chats?.map((dialog: Dialog) => {
    return dialog
  })
  const firstMessage = {
    role: 'assistant',
    content:
      'Я - ALF AI, готов помочь вам в поиске ответов на любые вопросы. Чтобы начать чат, просто задайте мне вопрос.',
  }
  // рендерим страницу истории переписки
  return (
    <div className={styles.chatHistoryPage}>
      {!waiting ? (
        <>
          <ul className={styles.chatHistoryPage__dialogList}>
            <DialogItem
              dialog={firstMessage}
              index={0}
              activeIndex={activeIndex}
              key={0}
              setActiveIndex={setActiveIndex}
            />
            {dialogs?.map((dialog: Dialog, index: number) => {
              return (
                <DialogItem
                  dialog={dialog}
                  index={index + 1}
                  activeIndex={activeIndex}
                  key={index + 1}
                  setActiveIndex={setActiveIndex}
                />
              )
            })}
          </ul>
          <DownloadHeaderLink />
        </>
      ) : (
        <div className={styles.chatHistoryPage__spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default ChatHistoryPage
