import React from 'react'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IQuestion {
  id: string
  text: string
  videoAnswer: boolean
  textAnswer: boolean
  questionType: string
  required: boolean
  description: boolean
  photo?: string
  video?: string
  videoAnswerDuration?: number
  videoAnswerResult?: Blob
}

export const questionSlice = createSlice({
  name: 'question',
  initialState: [],
  reducers: {
    addQuestion(state, { payload }: PayloadAction<IQuestion>) {
      state.splice(0, 1, payload)
    },
    changedTextQuestion(state, { payload }: PayloadAction<{ value: string }>) {
      if (state.length > 0) {
        state[0].text = payload.value
      }
    },
    changedTypeQuestion(state, { payload }: PayloadAction<{ value: string }>) {
      if (state.length > 0) {
        state[0].questionType = payload.value
      }
    },
    toggleRequiredChanged(state, { payload }: PayloadAction<{ value: boolean }>) {
      state[0].required = payload.value
    },
    toggleDescriptionChanged(state, { payload }: PayloadAction<{ value: boolean }>) {
      state[0].description = payload.value
    },
  },
})

export const {
  addQuestion,
  changedTextQuestion,
  changedTypeQuestion,
  toggleRequiredChanged,
  toggleDescriptionChanged,
} = questionSlice.actions

export default questionSlice.reducer
