import { IHeader, CellTypes } from 'pages/tablePage/helpers/types'
import clearIcon from '../components/Table/components/Menu/images/clearIcon.svg'
import changeIcon from '../components/Table/components/Menu/images/changeIcon.svg'
import deleteIcon from '../components/Table/components/Menu/images/deleteIcon.svg'
import textIcon from '../components/Table/components/Menu/images/textIcon.svg'
import mailIcon from '../components/Table/components/Menu/images/mail.svg'
import peopleIcon from '../components/Table/components/Menu/images/people.svg'
import telephoneIcon from '../components/Table/components/Menu/images/telephone.svg'
import imageIcon from '../components/Table/components/Menu/images/imageIcon.svg'
import listIcon from '../../../assets/icons/listIcon.svg'
import timeIcon from '../../../assets/icons/time.svg'
import selectIcon from '../../../assets/icons/selectIcon.svg'

export const FRAME_ID = '0'

export const getIconViaText = (text: string) => {
  switch (text) {
    case 'Текст':
      return textIcon
    case 'Изображение':
      return imageIcon
    case 'Выбрать вариант':
      return listIcon
    case 'Участники':
      return peopleIcon
    case 'Телефон':
      return telephoneIcon
    case 'Электронная почта':
      return mailIcon
    case 'Удалить':
      return deleteIcon
    case 'Очистить':
      return clearIcon
    case 'Изменить':
      return changeIcon
    default:
      return textIcon
  }
}

export const getIconViaType = (type) => {
  switch (type) {
    case 'IMAGE':
      return imageIcon
    case 'EMAIL':
      return mailIcon
    case 'PHONE':
      return telephoneIcon
    case 'TIME':
      return timeIcon
    case 'TEXT':
      return textIcon
    case 'CONTACT':
      return peopleIcon
    case 'SELECT':
      return selectIcon
    default:
      return listIcon
  }
}

export const getTextViaType = (type: CellTypes) => {
  switch (type) {
    case 'IMAGE':
      return 'Изображение'
    case 'SELECT':
      return 'Одиночный выбор'
    case 'MULTISELECT':
      return 'Множественный выбор'
    case 'TEXT':
      return 'Текст'
    case 'contact':
      return 'Участники'
    case 'email':
      return 'Email'
    case 'phone':
      return 'Телефон'
  }
}

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const multiReorder = <T>(
  list: T[],
  draggedRowIndex: number,
  targetRowIndex: number,
  selectedRowsIndexes: number[],
): T[] => {
  const newData = [...list]
  const rowsToDrag = [...selectedRowsIndexes, draggedRowIndex]
  const newDataWithMarkedRows: Array<T & { isToDelete?: boolean }> = newData.map((item, index) => {
    if (rowsToDrag.includes(index)) {
      return { ...item, isToDelete: true }
    } else {
      return item
    }
  })
  const slip = targetRowIndex > draggedRowIndex ? 1 : 0
  const draggedRows = newData.filter((row, index) => rowsToDrag.includes(index))
  newDataWithMarkedRows.splice(targetRowIndex + slip, 0, ...draggedRows)
  const newDataWithoutDuplicates: T[] = newDataWithMarkedRows.filter((row) => !row.isToDelete)
  return newDataWithoutDuplicates
}

export const generateId = () => {
  return Number(Math.random())
}

export const getHeaders = (columns: any): IHeader[] => {
  if (columns.length > 0) {
    return columns.map((column) => {
      return {
        columnId: column.columnId,
        columnType: column.columnType,
        columnName: column.columnName,
        choices: column?.choices || null,
      }
    })
  }
}
export const framesOrder = (frames: any[], order: number[]) => {
  return [...frames].sort((a, b) => {
    if (order.indexOf(a.id) < 0) {
      return 1
    } else {
      return order.indexOf(a.id) - order.indexOf(b.id)
    }
  })
}
