import React, { useCallback } from 'react'
import { ArrowDownBig } from 'components/Editor/icons/ArrowDownBig'
import { ArrowTop } from 'components/Editor/icons/ArrowTop'
import styles from './ModalCommandCardItem.module.scss'

interface ModalCommandCardItemProps {
  title: string
  children: React.ReactNode
  opened?: boolean
}

export const ModalCommandCardItem: React.FC<ModalCommandCardItemProps> = ({ title, children, opened = false }) => {
  const [select, setSelect] = React.useState(opened)
  const handleToggleCollapse = useCallback(() => {
    setSelect((prev) => !prev)
  }, [setSelect])
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title} onClick={handleToggleCollapse}>
          {title}
        </h2>
        <div className={styles.btn__box} onClick={handleToggleCollapse}>
          {select ? <ArrowDownBig /> : <ArrowTop />}
        </div>
      </div>
      {select && <div className={styles.body}>{children}</div>}
    </>
  )
}
