import { gql } from '@apollo/client'
// import { gql } from '__generated__/index'

// export const GET_VARIABLES = gql(`query GetVariables {
//   allVars {
//     varId
//     name
//     value
//     type
//     description
//     owner {
//       email
//       firstName
//       lastName
//     }
//     lastModifiedDate
//   }
// }`)

export const GET_VARIABLES = gql`
  query GetVariables {
    allVars {
      varId
      orderId
      name
      value
      type
      description
      createdAt
      owner {
        email
        firstName
        lastName
      }
      lastModifiedUser
      lastModifiedDate
    }
  }
`
export const GET_SHOT_VARIABLES = gql`
  query GetShortVariables {
    allVars {
      varId
      name
      value
    }
  }
`
