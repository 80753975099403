import React from 'react'
import styles from './styles.module.scss'

export const AlfAssistantFooterVoiceIcon = () => {
  return (
    <svg
      className={styles.svg}
      width='20'
      height='20 '
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='9'
        y='3'
        width='6'
        height='11'
        rx='3'
        stroke='#FFFFFF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 11C19 14.866 15.866 18 12 18C8.13401 18 5 14.866 5 11'
        stroke='#FFFFFF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 18V21' stroke='#FFFFFF' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
