import React from 'react'
import styles from './style.module.scss'
import { useHistory } from 'react-router'
import { t } from 'i18next'

export const HeaderButton = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/registry')
  }
  return (
    <button className={styles.button} onClick={handleClick}>
      {t('header_btn_reg')}
    </button>
  )
}
