import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import Styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { makeAnchorScroll } from '../../../../helpers/makeAnchorScroll'

export const BurgerMenu = (props) => {
  const { t } = useTranslation()
  return (
    <div className={Styles.burger__menu}>
      {/* <nav className={Styles.bm__nav}>
        <div
          onClick={async () => {
            makeAnchorScroll('#opportunities')
            props.setModal(false)
          }}
          className={Styles.header__link}
        >
          {t('header_link_1')}
        </div>
        <div
          onClick={async () => {
            makeAnchorScroll('#eco')
            props.setModal(false)
          }}
          className={Styles.header__link}
        >
          {t('header_link_2')}
        </div>
        <div
          onClick={async () => {
            makeAnchorScroll('#teamwork')
            props.setModal(false)
          }}
          className={Styles.header__link}
        >
          {t('header_link_3')}
        </div>
        <div
          onClick={async () => {
            makeAnchorScroll('#tariff')
            props.setModal(false)
          }}
          className={Styles.header__link}
        >
          {t('header_link_4')}
        </div>
        <LanguagePopup />
      </nav> */}
      <div className={Styles.header__btn_container}>
        <Link to='/login' className={Styles.header__btn}>
          {t('header_btn')}
        </Link>
        {props.isRegistryVisible ? (
          <NavLink to='/registry' className={Styles.header__btn_reg}>
            {t('header_btn_reg')}
          </NavLink>
        ) : null}
      </div>
    </div>
  )
}
