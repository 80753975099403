import React, { ChangeEvent, FC } from 'react'
import AlfAssistantFooterInputVoiceInput from './AlfAssistantFooterInputVoiceInput/AlfAssistantFooterInputVoiceInput'
import styles from './styles.module.scss'
import AlfAssistantFooterInputVoiceHelp from './AlfAssistantFooterInputVoiceHelp/AlfAssistantFooterInputVoiceHelp'

export type TInputVoice = {
  handleInputChange: (event: ChangeEvent) => void
  onKeyDown?: (event: KeyboardEvent) => void
  setVoiceActive: (e: boolean) => void
  handleInputEvent: (cursorPosition: number) => void
  setInputValue: (e: string) => void
  handleSendMessage: (value: string) => void
}

const AlfAssistantFooterInputVoice: FC<TInputVoice> = ({
  handleInputChange,
  handleInputEvent,
  setInputValue,
  setVoiceActive,
  handleSendMessage,
}) => {
  return (
    <div className={styles.voice}>
      <AlfAssistantFooterInputVoiceHelp setVoiceActive={setVoiceActive} />
      <AlfAssistantFooterInputVoiceInput
        setVoiceActive={setVoiceActive}
        handleInputChange={handleInputChange}
        handleInputEvent={handleInputEvent}
        setInputValue={setInputValue}
        handleSendMessage={handleSendMessage}
      />
    </div>
  )
}

export default AlfAssistantFooterInputVoice
