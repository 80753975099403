import React, { FC } from 'react'
import styles from './styles.module.scss'
import { DeleteAssistantModal } from 'components/AlfAssistantModal/components/AlfAssistantFooter/components/AlfAssistantSettings/DeleteAssistantModal/DeleteAssistantModal'
import { AlfAssistantAddButton } from '../AlfAssistantAddButton/AlfAssistantAddButton'

type TAlfAssistantSettingsAssistBtns = {
  handleCloseAssistantSettings: () => void
  handleOpenDeleteAssistantModal: () => void
  isAssistantName: string
  isOpenDeleteAssistantModal: boolean
  isAssistantNameValid: boolean
  handleSubmit: () => void
  handleDelete: () => void
}

const AlfAssistantSettingsAssistBtns: FC<TAlfAssistantSettingsAssistBtns> = ({
  handleCloseAssistantSettings,
  handleOpenDeleteAssistantModal,
  isAssistantName,
  isOpenDeleteAssistantModal,
  isAssistantNameValid,
  handleSubmit,
  handleDelete,
}) => {
  return (
    <>
      <div className={styles.buttons}>
        <AlfAssistantAddButton title='Сохранить' isButtonValid={isAssistantNameValid} onClick={handleSubmit} />
        <button className={styles.deleteButton} onClick={handleOpenDeleteAssistantModal}>
          Удалить
        </button>
      </div>
      {isOpenDeleteAssistantModal && (
        <DeleteAssistantModal
          title={`Уверены, что хотите удалить ассистент “${isAssistantName}”?`}
          description='После удаления переписки, ее невозможно будет востановить'
          handleDelete={handleDelete}
          closeModal={handleCloseAssistantSettings}
        />
      )}
    </>
  )
}

export default AlfAssistantSettingsAssistBtns
