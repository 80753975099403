import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'

export const getTheProject = createAsyncThunk('project/getProject', async (id: number) => {
  const response = await api.get<any>(`projects/${id}/`)
  return response.data
})

export const deleteProject = createAsyncThunk('project/deleteProject', async (id: string) => {
  const response = await api.delete<any>(`projects/${id}/`)
  return response.data
})

export const updateProject = createAsyncThunk('project/updateProject', async (payload: any) => {
  const response = await api.updateFile<any>(`projects/${payload.projectId}/`, payload.body)
  return response.data
})

export const updateProjectIcon = createAsyncThunk('project/updateProject', async (payload: any) => {
  const response = await api.patch<any>(`projects/${payload.projectId}/`, { documentLogoUrl: payload.url })
  return response.data
})

export const postNewProject = createAsyncThunk('project/postNewProject', async (payload: any) => {
  const response = await api.sendFile<any>(`projects/`, payload.body)
  return response.data
})

export const postNewContact = createAsyncThunk('project/postNewContact', async (payload: any) => {
  const response = await api.post<any>(`projects/${payload.projectId}/contacts/`, payload.contact)
  return response.data
})

export const postProjectNewContact = createAsyncThunk('project/postProjectNewContact', async (payload: any) => {
  const response = await api.put<any>(
    `projects/${payload.projectId}/contacts/${payload.contactId}/`,
    payload.contactData,
  )
  return response.data
})

export const deleteProjectContact = createAsyncThunk('project/deleteProjectContact', async (payload: any) => {
  const response = await api.delete<any>(`projects/${payload.projectId}/contacts/${payload.contactId}`)
  return response.data
})
export const deleteMultipleProjectContact = createAsyncThunk(
  'project/deleteMultipleProjectContact',
  async (payload: any) => {
    const response = await api.post<any>(`contact/delete_list/`, payload)
    return response.data
  },
)

export const projectSlice = createSlice({
  name: 'projectSlice',
  initialState: {
    aboutProject: {
      id: null,
      name: '',
      description: '',
      logo: null,
      contacts: [],
      invites: undefined,
    },
    projectImage: null,
    contact: { name: '', phone: '', email: '', position: '', department: '' },
    contactsInNonExistingProject: [],
    isValidContact: true,
    status: null,
    error: null,
  },
  reducers: {
    setContact(state, { payload }) {
      state.contact = payload
    },
    setContactsInNonExistingProject(state, { payload }) {
      state.contactsInNonExistingProject = payload
    },
    setIsValidContact(state, { payload }) {
      state.isValidContact = payload
    },
    setDefaultValues(state) {
      state.aboutProject = {
        id: null,
        name: '',
        description: '',
        logo: null,
        contacts: [],
        invites: undefined,
      }
    },
    onChangeProjectName(state, { payload }) {
      state.aboutProject.name = payload
    },
    onChangeProjectDescription(state, { payload }) {
      state.aboutProject.description = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTheProject.fulfilled, (state, { payload }) => {
      state.aboutProject = payload
    })

    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.aboutProject = payload
    })

    builder.addCase(deleteProject.fulfilled, (state) => {
      state.aboutProject = {
        id: null,
        name: '',
        description: '',
        logo: null,
        contacts: [],
        invites: undefined,
      }
      state.status = 'success'
    })

    builder.addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state) => {
        state.status = 'success'
        state.error = null
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.status = 'loading'
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state) => {
        state.status = 'rejected'
      },
    )
  },
})

export const {
  setContact,
  setContactsInNonExistingProject,
  setIsValidContact,
  setDefaultValues,
  onChangeProjectName,
  onChangeProjectDescription,
} = projectSlice.actions
export default projectSlice.reducer
