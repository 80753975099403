import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type itemNode = { key: string; type: string }

const initialState = []
export const listNodesSlice = createSlice({
  name: 'listNodesSlice',
  initialState,
  reducers: {
    addItemNode(state, { payload }: PayloadAction<itemNode>) {
      state.push(payload)
    },
    deleteItemNode(state, { payload }: PayloadAction<string>) {
      state.splice(state.indexOf(payload), 1)
    },
  },
})
export const { addItemNode, deleteItemNode } = listNodesSlice.actions

export default listNodesSlice.reducer
