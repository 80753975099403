import React from 'react'
import styles from './styles.module.scss'

const WriteIcon = () => {
  return (
    <svg
      className={styles.svg}
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2236_17790)'>
        <path
          d='M11.2593 13.7544H11.2787M13.7516 13.7544H13.771M18.7167 13.7544H18.7362M16.2439 13.7544H16.2633M12.5054 16.2467H12.5249M14.9977 16.2467H15.0172M17.49 16.2467H17.5095M10.0131 19.1544H19.9823C20.9 19.1544 21.6439 18.4105 21.6439 17.4928V12.5082C21.6439 11.5906 20.9 10.8467 19.9823 10.8467H10.0131C9.09546 10.8467 8.35156 11.5906 8.35156 12.5082V17.4928C8.35156 18.4105 9.09546 19.1544 10.0131 19.1544Z'
          strokeWidth='1.38462'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.346154'
        y='0.346154'
        stroke='#4BBDEA'
        width='29.3077'
        height='29.3077'
        rx='14.6538'
        strokeWidth='0.692308'
      />
      <defs>
        <clipPath id='clip0_2236_17790'>
          <rect width='30' height='30' rx='15' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WriteIcon
