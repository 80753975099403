import React from 'react'
import styles from './styles.module.scss'

interface AlfAssistantMockElementProps {
  canselRequest: () => void
}

export const AlfAssistantMockElement = ({ canselRequest }: AlfAssistantMockElementProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.description}>ALF печатает ...</p>
      <div className={styles.wrapper}>
        <p className={styles.command} id='stop' onClick={canselRequest}>
          Стоп
        </p>
        <p className={styles.key}>ESC</p>
      </div>
    </div>
  )
}
