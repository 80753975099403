import React from 'react'
import styles from './styles.module.scss'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { MessageMobileMenu } from './components/MessageMobileMenu/MessageMobileMenu'
import { toast } from 'react-toastify'
interface ALFAssistantMobileMessageItemProps {
  message: string
  answer: boolean
  id?: string
  index?: number
  handleCreateCommandFromMessage: (content: string) => void
}
export const ALFAssistantMobileMessageItem = (props: ALFAssistantMobileMessageItemProps) => {
  const [isOpenMenu, setIsOpenMenu] = React.useState(false)
  const { message, answer, index, handleCreateCommandFromMessage } = props
  const callbacks = {
    handleCreateTemplate: () => handleCreateCommandFromMessage(message),
    handleCopyMessage: async () => {
      await navigator?.clipboard?.writeText(message)
      toast.info('Сообщение скопировано')
    },
    handleOpenMessageMenu: () => {
      if (index > 0) {
        setIsOpenMenu(!isOpenMenu)
      }
    },
  }
  return (
    <div
      className={answer ? styles.answer : styles.request}
      onClick={callbacks.handleOpenMessageMenu}
      onTouchStart={callbacks.handleOpenMessageMenu}
    >
      {message !== '' ? (
        <ReactMarkdown remarkPlugins={[gfm]}>{message}</ReactMarkdown>
      ) : (
        <span style={{ color: 'gray' }}>Думаю...</span>
      )}
      {isOpenMenu && (
        <MessageMobileMenu
          isAnswerMessage={answer}
          handleCopyMessage={callbacks.handleCopyMessage}
          handleCreateTemplate={callbacks.handleCreateTemplate}
        />
      )}
    </div>
  )
}
