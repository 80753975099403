import React from 'react'
import styles from './style.module.scss'

export const CheklistNoAcces = () => {
  return (
    <div className={styles.page}>
      <div className={styles.page__container}>
        <div className={styles.page__img} />
        <h2 className={styles.page__title}>Доступ закрыт</h2>
        <p className={styles.page__description}>
          Извините, но в данный момент у вас нет доступа к этой странице. Пожалуйста, проверьте свои учётные данные и
          попробуйте снова. Если вы считаете, что это ошибка, пожалуйста, свяжитесь с администратором сайта для
          получения дополнительной помощи.<span className={styles.page__description_thank}>Спасибо за понимание.</span>
        </p>
      </div>
    </div>
  )
}
