import translationGoogleAuthEN from './GoogleAuth/en.json'
import translationGoogleAuthRU from './GoogleAuth/ru.json'
import translationLoginFormEN from './LoginForm/en.json'
import translationLoginFormRU from './LoginForm/ru.json'
import translationResetFormEN from './ResetForm/en.json'
import translationResetFormRU from './ResetForm/ru.json'
import translationConfirmEmailEN from './ConfirmEmail/en.json'
import translationConfirmEmailRU from './ConfirmEmail/ru.json'

export const translationIsAuthMergeEN = {
  ...translationGoogleAuthEN,
  ...translationLoginFormEN,
  ...translationResetFormEN,
  ...translationConfirmEmailEN,
}
export const translationIsAuthMergeRU = {
  ...translationGoogleAuthRU,
  ...translationLoginFormRU,
  ...translationResetFormRU,
  ...translationConfirmEmailRU,
}
