import { IContact } from './../apiTypes'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../api'

export const getContactsData: any = createAsyncThunk('contacts/get', async function async() {
  const response = await api.get('contacts/')
  return response.data
})

export const postContactsData: any = createAsyncThunk('contact/post', async function async(data: any) {
  const response = await api.post('contacts/', data)
  return response.data
})

export const updateContact: any = createAsyncThunk('contact/update', async function async(param: any) {
  const { id, data } = param
  const response = await api.patch(`contacts/${id}/`, data)
  return response.data
})

export const deleteContact: any = createAsyncThunk('contact/delete', async function async(id: any) {
  const response = await api.delete(`contacts/${id}/`)
  return response.data
})

export const getDepartments: any = createAsyncThunk('contact/department/get', async function async(id: any) {
  const response = await api.get(`contacts/${id}/departments/`)
  return response.data
})

export const postDepartment: any = createAsyncThunk('contact/department/post', async function async(param: any) {
  const { id, data } = param
  const response = await api.post(`contacts/${id}/departments/`, data)
  return response.data
})

export const updateDepartment: any = createAsyncThunk('contact/department/update', async function async(param: any) {
  const { id, data } = param
  const response = await api.patch(`contacts/0/departments/${id}/`, data)
  return response.data
})

export const getDepartment: any = createAsyncThunk('contact/getDepartment', async function async(param: any) {
  const { id } = param
  const response = await api.get(`contacts/0/departments/${id}/`)
  return response.data
})

export const getPositions: any = createAsyncThunk('contact/positions/get', async function async(id: any) {
  const response = await api.get(`contacts/${id}/positions/`)
  return response.data
})

export const postPosition: any = createAsyncThunk('contact/positions/post', async function async(param: any) {
  const { id, data } = param
  const response = await api.post(`contacts/${id}/positions/`, data)
  return response.data
})

export const updatePosition: any = createAsyncThunk('contact/position/update', async function async(param: any) {
  const { id, data } = param
  const response = await api.patch(`contacts/0/positions/${id}/`, data)
  return response.data
})

export const getPosition: any = createAsyncThunk('contact/getPosition', async function async(param: any) {
  const { id } = param
  const response = await api.get(`contacts/0/positions/${id}/`)
  return response.data
})

export const deletePosition: any = createAsyncThunk('contact/position/delete', async function async(id) {
  const response = await api.delete(`contacts/0/positions/${id}/`)
  return response.data
})

export const deleteDepartment: any = createAsyncThunk('contact/department/delete', async function async(id) {
  const response = await api.delete(`contacts/0/departments/${id}/`)
  return response.data
})

export const getOneContact: any = createAsyncThunk('contact/get', async function async(id: any) {
  const response = await api.get(`contacts/${id}/`)
  return response.data
})

export const postUserfields: any = createAsyncThunk('contacts/userfields/post', async function async(param: any) {
  const { id, data } = param
  const response = await api.post(`contacts/${id}/userfields/`, data)
  return response.data
})

export const setOrderContacts = createAsyncThunk(
  'deleteDoc/deleteInvitedUser',
  async (data: { projectId: number; email: string }) => {
    const response = await api.patch<any>(`auth/profile/`, { contacts_order: data })
    return response.data
  },
)

export const contactSlice = createSlice({
  name: 'contacts',
  initialState: {
    contactsData: [] as IContact[],
    contactsOrder: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContactsData.fulfilled, (state, { payload }) => {
      state.contactsData = payload.contacts
      state.contactsOrder = payload.contactsOrder
    })
    builder.addCase(getContactsData.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postContactsData.rejected, () => {
      console.error('Error')
    })
    builder.addCase(updateContact.rejected, () => {
      console.error('Error')
    })

    builder.addCase(deleteContact.rejected, () => {
      console.error('Error')
    })
    builder.addCase(getDepartments.rejected, () => {
      console.error('Error')
    })
    builder.addCase(getPositions.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postPosition.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postDepartment.rejected, () => {
      console.error('Error')
    })
    builder.addCase(getOneContact.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postUserfields.rejected, () => {
      console.error('Error')
    })
  },
})

export default contactSlice.reducer
