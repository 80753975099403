import React from 'react'

export const WheelIcon = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12.0254' r='2' stroke='#AAABAD' strokeWidth='1.5' />
      <path
        d='M5.88314 6.20611C5.065 7.11123 4.47916 8.19301 4.14841 9.35787L5.43083 10.1318C6.73427 10.9184 6.71589 12.815 5.39744 13.5762L4.2416 14.2435C4.42836 14.8265 4.68033 15.3999 5 15.9536C5.31966 16.5073 5.69026 17.0122 6.10173 17.4654L7.25761 16.798C8.57606 16.0368 10.2277 16.9692 10.2572 18.4913L10.2863 19.989C11.4604 20.285 12.6902 20.3185 13.8831 20.0625L13.8586 18.7994C13.8281 17.2255 15.5438 16.2349 16.8917 17.0483L17.9733 17.7011C18.7914 16.796 19.3772 15.7142 19.708 14.5493L18.4255 13.7754C17.1221 12.9887 17.1405 11.0922 18.4589 10.331L19.6148 9.66362C19.428 9.08067 19.1761 8.50725 18.8564 7.95359C18.5367 7.39992 18.1661 6.89499 17.7546 6.44176L16.5988 7.10909C15.2803 7.8703 13.6287 6.93794 13.5992 5.41581L13.5701 3.91823C12.396 3.62224 11.1662 3.58871 9.97326 3.84468L9.99775 5.10774C10.0283 6.6817 8.31257 7.67226 6.96474 6.85885L5.88314 6.20611Z'
        stroke='#AAABAD'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
    </svg>
  )
}
