import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'

export const getEventsForThePeriod = createAsyncThunk('calendarSlice/getEventsForThePeriod', async (data: any) => {
  const response = await api.get<any>(`calendar/${data.startDate}/${data.endDate}/`)
  return response.data
})

export const getEventsForTheDay = createAsyncThunk('calendarSlice/getEventsForTheDay', async (date: string) => {
  const response = await api.get<any>(`calendar/${date}/${date}/`)
  return response.data
})

export const calendarSlice = createSlice({
  name: 'calendarSlice',
  initialState: {
    data: null,
    currentData: null,
    status: null,
  } as any,
  reducers: {
    resetCurrentData(state) {
      state.currentData = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEventsForThePeriod.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getEventsForThePeriod.fulfilled, (state, { payload }) => {
      state.data = payload
      state.status = 'success'
    })

    builder.addCase(getEventsForTheDay.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getEventsForTheDay.fulfilled, (state, { payload }) => {
      state.currentData = payload
      state.status = 'success'
    })
  },
})

export const { resetCurrentData } = calendarSlice.actions
export default calendarSlice.reducer
