const { REACT_APP_API } = process.env

export const getHttpUrl = () => {
  return `${REACT_APP_API}/graphql/`
}

export const getWsUrl = () => {
  const regex = /\/api\/v1/gi
  if (REACT_APP_API) {
    const replacedUrl = REACT_APP_API.replace('https', 'wss').replace(regex, '')
    return `${replacedUrl}/ws/graphql/`
  }
}
