import React from 'react'
import styles from './styles.module.scss'
import { PlusIcon } from './images/PlusIcon'

interface IAlfAssistantAddButtonProps {
  title: string
  showIcon?: boolean
  isButtonValid?: boolean
  onClick?: () => void
  style?: React.CSSProperties
}

export const AlfAssistantAddButton: React.FC<IAlfAssistantAddButtonProps> = ({
  title,
  showIcon,
  isButtonValid,
  onClick,
  style,
}) => {
  return (
    <button className={`${styles.saveButton} ${isButtonValid ? styles.active : ''}`} style={style} onClick={onClick}>
      {showIcon && <PlusIcon />}
      {title}
    </button>
  )
}
