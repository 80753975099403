import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Styles from './style.module.scss'
import toastStyle from './toastStyle.module.scss'
import { ErrorModal } from 'components/ErrorModal'
import { Spinner } from 'components/Spinner'
import { Header } from '../Header/Header'

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import PageLanding from 'pages/landing'
import { UIKit } from 'components/UI-kit/UIKit'
import { CheklistNoAcces } from 'pages/checkList/components/PageChecklistNoAccess'
import ChatHistoryPage from 'pages/chatHistoryPage'

const CheckList = React.lazy(() => import('../../pages/checkList'))
const TablePage = React.lazy(() => import('../../pages/tablePage'))
const AuthPage = React.lazy(() => import('../../pages/isAuth/index'))
const ConfirmEmail = React.lazy(() => import('../../pages/isAuth/components/ConfirmEmail'))
const PageNotFount = React.lazy(() => import('../../pages/not-found'))
const DevelopingPage = React.lazy(() => import('../../pages/developing'))
const AccessDeniedPage = React.lazy(() => import('../../pages/access-denied'))
const DocumentPage = React.lazy(() => import('../../pages/document'))
const TestPage = React.lazy(() => import('../../pages/test'))
const DiskPage = React.lazy(() => import('../../pages/disk'))
const Plan = React.lazy(() => import('../../pages/plan'))
const CLReportPage = React.lazy(() => import('../../pages/checkListReportPage'))
const ProtectedRoute = React.lazy(() => import('components/Router/protected-route'))
const PublicRoute = React.lazy(() => import('components/Router/public-route'))

const App = () => {
  const { pathname } = useLocation()
  const [isConnectionError, setIsConnectionError] = useState(false)

  useEffect(() => {
    window.addEventListener('offline', () => {
      setIsConnectionError(true)
    })
    window.addEventListener('online', () => {
      setIsConnectionError(false)
    })

    return () => {
      window.removeEventListener('offline', () => {
        setIsConnectionError(true)
      })
      window.removeEventListener('online', () => {
        setIsConnectionError(false)
      })
    }
  }, [])

  return (
    <Suspense
      fallback={
        <div className={Styles.spinner_container}>
          <Spinner />
        </div>
      }
    >
      <div className={Styles.app_container}>
        <section className={Styles.section} style={pathname !== '/' ? { minWidth: '800px' } : undefined}>
          <ToastContainer
            style={{ zIndex: 999999999999999 }}
            bodyClassName={() => `${toastStyle.customBody}`}
            toastClassName={() => `${toastStyle.customToast}`}
            theme='dark'
          />
          <Switch>
            <Route path='/' exact component={PageLanding} />
            <ProtectedRoute allowedUsersTypes='user' path='/disk' layout={Header} exact component={DiskPage} />
            <ProtectedRoute allowedUsersTypes='user' path='/plan' layout={Header} exact component={Plan} />
            <Route path='/history/:id' exact component={ChatHistoryPage} />
            <Route path='/registry' exact component={AuthPage} />
            <Route path='/login' exact component={AuthPage} />
            <Route path='/reset' exact component={AuthPage} />
            <Route path='/test' exact component={TestPage} />
            <ProtectedRoute
              allowedUsersTypes='user'
              path='/checklist/:id/:pk'
              exact
              component={CheckList}
              layout={Header}
            />
            <Route path='/checklist/report-page/:id/:reportId' exact component={CLReportPage} />

            <Route path='/cheklist-no-access' exact component={CheklistNoAcces} />
            <ProtectedRoute
              allowedUsersTypes='user'
              path='/document/:folderId/:rootDocumentId/:documentId'
              exact
              layout={Header}
              component={DocumentPage}
            />
            <PublicRoute
              allowedUsersTypes='anonymous'
              path='/public/document/:folderId/:rootDocumentId/:documentId/:uuid'
              exact
              layout={Header}
              component={DocumentPage}
            />
            <ProtectedRoute
              allowedUsersTypes='user'
              path='/table/:name/:pk/:id'
              exact
              component={TablePage}
              layout={null}
            />
            <PublicRoute
              allowedUsersTypes='anonymous'
              path='/public/table/:name/:pk/:id/:uuid'
              exact
              component={TablePage}
              layout={null}
            />
            <Route path='/confirm' exact component={ConfirmEmail} />
            <Route path='/agreements' exact component={DevelopingPage} />
            <Route path='/access-denied' exact component={AccessDeniedPage} />
            <Route path='/ui-playground' exact component={UIKit} />
            <Route path={['/*', '/404']} exact component={PageNotFount} />
          </Switch>
        </section>
        <ErrorModal
          isOpened={isConnectionError}
          title='Соединение с сервером потеряно'
          text='Мы приносим свои извинения
          и уже на пути к решению.'
        />
      </div>
    </Suspense>
  )
}

export default App
