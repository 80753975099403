import React, { useCallback } from 'react'

import classes from './CommandSearch.module.scss'
import { useEvent, useGate, useStore } from 'effector-react'
import { $search, searchChanged, SearchCommandGate } from '../../model'
import { SearchIcon } from 'components/Editor/icons/SearchIcon'

export const CommandSearch = () => {
  const search = useStore($search)
  const handleSearchChanged = useEvent(searchChanged)

  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e
      handleSearchChanged(value)
    },
    [handleSearchChanged],
  )

  return (
    <div className={classes.ai_command_search}>
      <SearchCommandGate />
      <input placeholder='Найти команду' value={search} onChange={onSearch} />
      <SearchIcon />
    </div>
  )
}
