import React from 'react'
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IQuestion {
  id: string
  text: string
  videoAnswer: boolean
  textAnswer: boolean
  questionType: string
  required: boolean
  description: boolean
  media: {
    size: 'cover' | 'contain'
    photo?: string
    video?: string
  }

  videoAnswerDuration?: number
  videoAnswerResult?: Blob
}

export const example: IQuestion = {
  id: '1',
  text: 'Вопрос 1',
  videoAnswer: false,
  textAnswer: true,
  questionType: 'Общий',
  required: true,
  description: false,
  media: {
    size: 'cover',
    photo: '',
    video: '',
  },
  videoAnswerDuration: 0,
  videoAnswerResult: new Blob(),
}

export const testSlice = createSlice({
  name: 'test',
  initialState: [
    {
      id: '1',
      text: '',
      videoAnswer: false,
      textAnswer: true,
      questionType: 'Общий',
      required: true,
      description: false,
      media: {
        size: 'cover',
        photo: '',
        video: '',
      },
      videoAnswerDuration: 0,
      videoAnswerResult: new Blob(),
    },
  ] as Array<IQuestion>,
  reducers: {
    optionRequiredChanged(state, { payload }: PayloadAction<{ id: string; value: boolean }>) {
      state[payload.id].required = payload.value
    },
    optionDescriptionChanged(state, { payload }: PayloadAction<{ id: string; value: boolean }>) {
      state[payload.id].description = payload.value
    },
    questionTypeChanged(state, { payload }: PayloadAction<{ id: string; value: string }>) {
      state[payload.id].questionType = payload.value
    },
    questionTextChanged(state, { payload }: PayloadAction<{ index: string; value: string }>) {
      state[payload.index].text = payload.value
    },
    addQuestionInList(state, { payload }: PayloadAction<{ id: string; text: string; type: string }>) {
      state.push({
        id: payload.id,
        text: payload.text,
        videoAnswer: false,
        textAnswer: true,
        questionType: payload.type,
        required: true,
        description: false,
        media: {
          size: 'cover',
          photo: '',
          video: '',
        },
        videoAnswerDuration: 0,
        videoAnswerResult: new Blob(),
      })
    },

    changeMediaSize(state, { payload }: PayloadAction<{ id: string; value: 'cover' | 'contain' }>) {
      state[payload.id].media.size = payload.value
    },

    addPhoto(state, { payload }: PayloadAction<{ id: string; file: string }>) {
      if (!state[payload.id]) {
        return
      }
      state[payload.id].media.video = ''
      state[payload.id].media.photo = payload.file
    },

    addVideo(state, { payload }: PayloadAction<{ id: string; value: string }>) {
      if (!state[payload.id]) {
        return
      }
      state[payload.id].media.photo = ''
      state[payload.id].media.video = payload.value
    },

    addVideoAnswerResult(state, { payload }: PayloadAction<{ index: number; value: Blob }>) {
      state[payload.index].videoAnswerResult = payload.value
    },

    removeCurrentQuestion(state, { payload }: PayloadAction<{ idQuestion: string }>) {
      return state.filter((question) => question.id !== payload.idQuestion)
    },

    deleteMedia(state, { payload }: PayloadAction<{ id: string }>) {
      state[payload.id].media.video = ''
      state[payload.id].media.photo = ''
    },
  },
})

export const {
  optionRequiredChanged,
  optionDescriptionChanged,
  questionTypeChanged,
  questionTextChanged,
  addQuestionInList,
  changeMediaSize,
  addPhoto,
  addVideo,
  addVideoAnswerResult,
  removeCurrentQuestion,
  deleteMedia,
} = testSlice.actions

export default testSlice.reducer
