import React, { ChangeEvent } from 'react'
import styles from './styles.module.scss'
import { CloseIcon } from './components/CloseIcon'
import { SearchIcon } from './components/SearchIcon'
import { HistoryItem } from './components/HistoryItem/HistoryItem'
import { Chat } from 'app/redux/userSlice'
interface AlfAssistantHistoryModalProps {
  handleCloseHistoryModal: () => void
  chats: Chat[]
  chatId: string
  deleteHistory: (id: string) => void
  renameChatHistory: (data: { id: string; name: string }) => void
  selectChat: (id: string) => void
  waiting: boolean
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
  searchTerm: string
}
export const AlfAssistantHistoryModal = (props: AlfAssistantHistoryModalProps) => {
  const {
    handleCloseHistoryModal,
    chats,
    chatId,
    deleteHistory,
    renameChatHistory,
    selectChat,
    waiting,
    handleSearch,
    searchTerm,
  } = props

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.modal}>
        <div className={styles.wrapper}>
          <h4 className={styles.title}>История переписки</h4>
          <button className={styles.button} onClick={handleCloseHistoryModal}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.search}>
          <SearchIcon />
          <input
            type='text'
            className={styles.input}
            placeholder='Найти в истории...'
            onChange={handleSearch}
            value={searchTerm}
            name='search'
          />
        </div>
        {chats.length === 0 ? (
          <p className={styles.info}>Нет истории переписки</p>
        ) : (
          <div className={styles.history}>
            {chats.map((chat) => {
              return (
                <HistoryItem
                  key={chat.chatUuid}
                  chatId={chat.chatUuid}
                  chatName={chat.chatName}
                  isCurrent={chatId === chat.chatUuid}
                  answer={chat.chatHistory[chat.chatHistory.length - 1].content}
                  deleteHistory={deleteHistory}
                  renameChatHistory={renameChatHistory}
                  changeDate={chat.updatedAt}
                  selectChat={selectChat}
                  closeModal={handleCloseHistoryModal}
                  waiting={waiting}
                />
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}
