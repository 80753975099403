import React from 'react'
import styles from './styles.module.scss'
import { CreateTemplateIcon } from './components/CreateTemplateIcon'
import { CopyMessageIcon } from './components/CopyMessageIcon'

interface MessageMenuProps {
  handleCopyMessage: () => void
  isAnswerMessage: boolean
  message: string
  handleCreateCommandFromMessage: (content: string) => void
}

export const MessageMenu = (props: MessageMenuProps) => {
  const { message, handleCopyMessage, isAnswerMessage, handleCreateCommandFromMessage } = props

  const callbacks = {
    handleCreateCommand: () => handleCreateCommandFromMessage(message),
  }
  return (
    <div className={styles.menu}>
      {!isAnswerMessage && (
        <button className={styles.button} onClick={callbacks.handleCreateCommand}>
          <CreateTemplateIcon />
        </button>
      )}
      <button className={styles.button} onClick={handleCopyMessage}>
        <CopyMessageIcon />
      </button>
    </div>
  )
}
