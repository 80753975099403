import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { Drawer } from 'components/UI/Drawer/Drawer'
import { CloseIcon } from './components/CloseIcon/CloseIcon'
import { AflAssistantHeader } from './components/AlfAssistantHeader/AlfAssistantHeader'
import { AlfAssistantFooter } from './components/AlfAssistantFooter/AlfAssistantFooter'
import { AlfAssistantCreateAssistant } from './components/AlfAssistantCreateAssistant/AlfAssistantCreateAssistant'
import { AlfAssistantAddSourceModal } from './components/AlfAssistantAddSourceModal/AlfAssistantAddSourceModal'
import { AlfAssistantSourcesTable } from './components/AlfAssistantAddSourceModal/components/AlfAssistantSourcesTable/AlfAssistantSourcesTable'
import { AlfAssistantChat } from './components/AlfAssistantChat/AlfAssistantChat'
import { getAssistantList, setAssistants, setIsOpenBotListModal } from 'app/redux/alfAssistantSlice'
import { AlfAssistantBotListModal } from './components/AlfAssistantBotListModal/AlfAssistantBotListModal'
import { createPortal } from 'react-dom'

export interface AlfAssistantModalProps {
  handleClose: () => void
  isModalOpen: boolean
}

export const AlfAssistantModal: React.FC<AlfAssistantModalProps> = ({ handleClose, isModalOpen }) => {
  const { isOpenCreateAssistant, isOpenSourceModal, isOpenSourcesTable } = useAppSelector((state) => state.alfAssistant)

  const dispatch = useAppDispatch()
  // const modalBotRef = useRef(null)
  const handleCloseAssistent = () => dispatch(setIsOpenBotListModal(false))
  const { isOpenBotListModal } = useAppSelector((state) => state.alfAssistant)
  const section = document.getElementById('section')

  useEffect(() => {
    dispatch(getAssistantList()).then((response) => {
      dispatch(setAssistants(response.payload))
    })
  }, [dispatch])

  return (
    <Drawer onClose={handleClose} isOpen={isModalOpen} width='auto' order={1}>
      <section className={styles.container} id='section'>
        <button className={styles.closeButton} onClick={handleClose}>
          <CloseIcon />
          {t('document_settings_close_text')}
        </button>
        <div className={styles.modal} style={{ width: isOpenSourcesTable ? '1076px' : '' }}>
          <AflAssistantHeader />
          <AlfAssistantChat />

          {isModalOpen && <AlfAssistantFooter />}
          {isOpenCreateAssistant && <AlfAssistantCreateAssistant create />}
          {isOpenSourceModal && <AlfAssistantAddSourceModal />}
          {isOpenSourcesTable && <AlfAssistantSourcesTable />}
        </div>
      </section>
      {isModalOpen &&
        createPortal(
          <AlfAssistantBotListModal
            // ref={modalBotRef}
            handleCloseModal={handleCloseAssistent}
            isOpen={isOpenBotListModal}
          />,
          section,
        )}
    </Drawer>
  )
}
