import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { CameraIcon } from '../../AlfAssistantModal/components/AlfAssistantCreateAssistant/images/CameraIcon'

interface IChatAvatarProps {
  handleLoadAvatar?: (avatar: File | null) => void
  avatar?: any
  style?: React.CSSProperties
  icon?: string
  cameraSize?: React.CSSProperties
}

export const ChatAvatar: React.FC<IChatAvatarProps> = ({ handleLoadAvatar, avatar, style, icon, cameraSize }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setIsLoading(true)
      const reader = new FileReader()
      reader.onload = async () => {
        const newAvatarUrl = reader.result as string
        setSelectedAvatar(newAvatarUrl)
        setIsLoading(false)
        handleLoadAvatar(file)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleRemoveAvatar = () => {
    setIsLoading(true)
    setSelectedAvatar(null)
    setIsLoading(false)
    handleLoadAvatar(null)
  }
  useEffect(() => {
    if (avatar === null) {
      setIsLoading(true)
      setSelectedAvatar(null)
      setIsLoading(false)
    }
  }, [avatar])

  return (
    <div className={styles.avatar}>
      <div className={styles.projectImg} style={style}>
        <label className={styles.label}>
          <input
            type='file'
            accept='image/*'
            className={styles.input}
            onChange={handleFileChange}
            disabled={isLoading}
          />
          {selectedAvatar ? (
            <img src={selectedAvatar} className={styles.chatImg} alt='Аватар' />
          ) : icon ? (
            <img src={icon} className={styles.chatImg} alt='Аватар' />
          ) : (
            <CameraIcon cameraSize={cameraSize} />
          )}
        </label>
      </div>
      {selectedAvatar && (
        <button className={styles.button} onClick={handleRemoveAvatar} disabled={isLoading}>
          Удалить фото
        </button>
      )}
    </div>
  )
}
