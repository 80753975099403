import React, { ChangeEvent, useState } from 'react'
import AlfAssistantFooterInputWrite from './components/AlfAssistantFooterInputWrite/AlfAssistantFooterInputWrite'
import AlfAssistantFooterInputVoice from './components/AlfAssistantFooterInputVoice/components/AlfAssistantFooterInputVoice'
import { AlfAssistantFooterSendIcon } from '../AlfAssistantFooterSendIcon/AlfAssistantFooterSendIcon'
interface AlfAssistantFooterInputProps {
  handleInputChange: (event: ChangeEvent) => void
  inputValue: string
  onKeyDown?: (event: KeyboardEvent) => void
  handleInputEvent: (cursorPosition: number) => void
  height?: number
  setInputValue: (e: string) => void
  handleSendMessage: (value: string) => void
}

export const AlfAssistantFooterInput = ({
  handleInputChange,
  inputValue,
  handleInputEvent,
  setInputValue,
  handleSendMessage,
}: AlfAssistantFooterInputProps) => {
  const [voiceActive, setVoiceActive] = useState<boolean>(false)

  return voiceActive ? (
    <AlfAssistantFooterInputVoice
      setVoiceActive={setVoiceActive}
      handleInputChange={handleInputChange}
      handleInputEvent={handleInputEvent}
      setInputValue={setInputValue}
      handleSendMessage={handleSendMessage}
    />
  ) : (
    <>
      <AlfAssistantFooterInputWrite
        setVoiceActive={setVoiceActive}
        handleInputChange={handleInputChange}
        inputValue={inputValue}
        handleInputEvent={handleInputEvent}
        handleSendMessage={handleSendMessage}
      />
      <button onClick={() => handleSendMessage(inputValue)}>
        <AlfAssistantFooterSendIcon />
      </button>
    </>
  )
}
