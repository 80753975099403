import translationHeaderEN from './Header/en.json'
import translationHeaderRU from './Header/ru.json'
import translationStartScreenEN from './StartScreen/en.json'
import translationStartScreenRU from './StartScreen/ru.json'
import translationCreateScreenEN from './CreateScreen/en.json'
import translationCreateScreenRU from './CreateScreen/ru.json'
import translationEconomyScreenEN from './EconomyScreen/en.json'
import translationEconomyScreenRU from './EconomyScreen/ru.json'
import translationTeamWorkScreenEN from './TeamWorkScreen/en.json'
import translationTeamWorkScreenRU from './TeamWorkScreen/ru.json'
import translationTariffEN from './Tariff/en.json'
import translationTariffRU from './Tariff/ru.json'
import translationFinalScreenEN from './FinalScreen/en.json'
import translationFinalScreenRU from './FinalScreen/ru.json'
import translationShareAccessEN from './ShareAccess/en.json'
import translationShareAccessRU from './ShareAccess/ru.json'
import translationSelectServerEN from './SelectServer/en.json'
import translationSelectServerRU from './SelectServer/ru.json'
import translationBasketEN from './Basket/en.json'
import translationBasketRU from './Basket/ru.json'

export const translationLandingMergeEN = {
  ...translationHeaderEN,
  ...translationStartScreenEN,
  ...translationCreateScreenEN,
  ...translationEconomyScreenEN,
  ...translationTeamWorkScreenEN,
  ...translationTariffEN,
  ...translationFinalScreenEN,
  ...translationShareAccessEN,
  ...translationSelectServerEN,
  ...translationBasketEN,
}
export const translationLandingMergeRU = {
  ...translationHeaderRU,
  ...translationStartScreenRU,
  ...translationCreateScreenRU,
  ...translationEconomyScreenRU,
  ...translationTeamWorkScreenRU,
  ...translationTariffRU,
  ...translationFinalScreenRU,
  ...translationShareAccessRU,
  ...translationSelectServerRU,
  ...translationBasketRU,
}
