import React from 'react'

export const HeaderBurgerMenuIcon = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.66699 9.33331H25.3337' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <path d='M6.66699 16H25.3337' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <path d='M6.66699 22.6667H25.3337' stroke='white' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}
