import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'

export const getRemovedList = createAsyncThunk('basket/getRemovedList', async () => {
  const response = await api.get<any>('trash/')
  return response.data
})

export const setRestore = createAsyncThunk('basket/setRestore', async (id: string) => {
  const response = await api.delete<any>(`trash/${id}/`)
  return response.data
})

export const basketSlice = createSlice({
  name: 'basketSlice',
  initialState: {
    list: [],
    deleteElem: [],
    tableComponents: [
      { name: 'project', checked: false },
      { name: 'nickname', checked: false },
      { name: 'date', checked: true },
      { name: 'type', checked: true },
      { name: 'size', checked: true },
    ],
    sort: { name: 'name', sig: true },
    status: null,
  },
  reducers: {
    addDeleteElem(state, action) {
      state.deleteElem = action.payload
    },
    changeDeleteElem(state, action) {
      const changeCompleted = state.deleteElem.find((item) => item.id === action.payload)
      changeCompleted.checked = !changeCompleted.checked
    },
    changeTableComponents(state, action) {
      const changeCompleted = state.tableComponents.find((item) => item.name === action.payload)
      changeCompleted.checked = !changeCompleted.checked
    },
    changeSort(state, action) {
      state.sort = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRemovedList.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getRemovedList.fulfilled, (state, { payload }) => {
      state.list = payload
      state.status = 'success'
    })
    builder.addCase(getRemovedList.rejected, (state) => {
      state.status = 'rejected'
    })

    builder.addCase(setRestore.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(setRestore.fulfilled, (state) => {
      state.status = 'success'
    })
    builder.addCase(setRestore.rejected, (state) => {
      state.status = 'rejected'
    })
  },
})

export const { addDeleteElem, changeDeleteElem, changeTableComponents, changeSort } = basketSlice.actions
export default basketSlice.reducer
