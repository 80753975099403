import React from 'react'
import styles from './style.module.scss'
import cn from 'classnames'

interface Prop {
  text?: string
  small?: boolean
  middleSmall?: boolean
  verySmall?: boolean
}

export const Spinner: React.FC<Prop> = ({ text, small, middleSmall, verySmall }) => {
  return (
    <div className={styles.spinner}>
      <div
        className={cn(
          styles.ring,
          small ? styles.small : null,
          verySmall ? styles.verySmall : null,
          middleSmall ? styles.middleSmall : null,
        )}
      />
      <p style={{ color: '#808080' }}>{text}</p>
    </div>
  )
}
