import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalCommandCardItem } from './ModalCommandCardItem/ModalCommandCardItem'
import { nanoid } from '@reduxjs/toolkit'
import classes from './ModalCommandCard.module.scss'
import { CategoryItem } from './CategoryItem/CategoryItem'
import clsx from 'clsx'
import { Favorites } from './Favorites/Favorites'
import { Drawer } from '../../../UI/Drawer/Drawer'
import { CloseIcon } from 'components/DocumentSettingsModal/components/CloseIcon/CloseIcon'
import { CommandInfo } from 'components/Editor/interfaces/commands'
import { Button } from 'components/Button'
import { api } from 'app/api'
import { Spinner } from 'components/Spinner'
import { setIsOpenAlfChat, setIsEditedCommand } from 'app/redux/alfAssistantSlice'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import useWindowSize from 'lib/hooks/useWindowSizeHook'
import { performSearch } from '../CommandCatalog/model'
import { useEvent } from 'effector-react'

interface ModalCommandCardProps {
  open: boolean
  onCloseHandler: () => void
  isNew: boolean
  isLoading: boolean
  command?: CommandInfo
  onSaveEditingCommand?: (info: Partial<CommandInfo>) => Promise<void>
  onDeleteCommand?: (id: number) => Promise<void>
}

export const ModalCommandCard: React.FC<ModalCommandCardProps> = ({
  open,
  onCloseHandler,
  isNew,
  isLoading,
  command,
  onSaveEditingCommand,
  onDeleteCommand,
}) => {
  // если system=true то карточка считается системной и поля, доступные для редактирования будут неактиными
  const { width } = useWindowSize()
  console.log('iisNew', isNew, command)
  const dispatch = useAppDispatch()
  const isEditedCommand = useAppSelector((state) => state.alfAssistant.isEditedCommand)
  // const [loading, setLoading] = useState(false)
  const [edited, setEdited] = useState(false)
  const [saving, setSaving] = useState(false)
  const [state, setState] = React.useState<CommandInfo>(command)

  const filledFields = useMemo(() => state.name.length > 0 && state.templateText.length > 0, [state])
  const handlePerformSearch = useEvent(performSearch)
  // const [valueCategory, setValueCategory] = React.useState('')
  // const [selectedCategory, setSelectedCategory] = React.useState([])
  // const [openVariant, setOpenVariant] = React.useState(false)
  // const inputRef = React.useRef(null)
  // const [lastElementNumber, setLastElementNumber] = React.useState(null)
  // const [categoryName, setCategoryName] = React.useState([{ name: 'HR' }, { name: 'Продажи' }, { name: 'Обучение' }])
  // const filteredCategory = categoryName.filter((item) => item.name.toLowerCase().includes(valueCategory.toLowerCase()))

  useEffect(() => {
    setState(command)
  }, [command])

  const handleChangeForm = (e) => {
    const field = e.target.getAttribute('data-name')
    setEdited(true)
    setState({
      ...state,
      [field]: e.target.value,
    })
  }

  // const handleBackspacePress = (e) => {
  //   if (!valueCategory && e.code === 'Backspace') {
  //     if (selectedCategory.length >= 1) {
  //       setLastElementNumber(selectedCategory.length - 1)
  //       const timerId = setTimeout(() => {
  //         setLastElementNumber(null)
  //       }, 2000)
  //       if (lastElementNumber !== null && e.code === 'Backspace') {
  //         removeCategoryItem(selectedCategory[lastElementNumber].id)
  //         setLastElementNumber(null)
  //         clearTimeout(timerId)
  //       }
  //     }

  //     // removeCategoryItem(selectedCategory[lastElementNumber].id)
  //   }
  // }

  // const removeCategoryItem = (id) => {
  //   setSelectedCategory(selectedCategory.filter((item) => item.id !== id))
  //   inputRef.current.focus()
  // }

  // const addCategoryItem = (name) => {
  //   setSelectedCategory([...selectedCategory, { id: nanoid(), name }])
  //   setValueCategory('')
  //   inputRef.current.focus()
  // }
  const handleChangeFavourite = useCallback(
    (favourite: boolean) => {
      setState({ ...state, favourite })
      setEdited(true)
    },
    [state, setState],
  )

  const handleSaveNewCommand = useCallback(async () => {
    setSaving(true)
    const { name, templateText, result, favourite, categories: categoriesInput } = state
    const res = await api.post('catalog_template/', {
      name,
      templateText,
      result,
      favourite,
      // categoriesInput,
    })
    if (res.status === 201) {
      onCloseHandler()
    }
    setSaving(false)
    // openAlfAssistantChat()
    handlePerformSearch('')
  }, [state, setSaving, state, onCloseHandler])
  const openAlfAssistantChat = () => {
    if (isEditedCommand) {
      dispatch(setIsOpenAlfChat(true))
      dispatch(setIsEditedCommand(false))
    }
  }
  const handleSaveEditingCommandInfo = useCallback(async () => {
    if (onSaveEditingCommand) {
      await onSaveEditingCommand(state)
      setEdited(false)
    }
    handlePerformSearch('')
    openAlfAssistantChat()
  }, [onSaveEditingCommand, state])

  const handleDeleteCommand = useCallback(async () => {
    if (onDeleteCommand) {
      const { id } = command
      await onDeleteCommand(id)
    }
    openAlfAssistantChat()
    handlePerformSearch('')
  }, [onDeleteCommand, command])
  const hanldeCloseButtonClick = () => {
    onCloseHandler()
    openAlfAssistantChat()
  }
  return (
    <Drawer isOpen={open} onClose={onCloseHandler} width='auto' order={width > 786 ? 1 : 0}>
      <div className={classes.container}>
        {width > 786 && (
          <div>
            <button onClick={hanldeCloseButtonClick} className={classes.btn}>
              <CloseIcon />
              Закрыть
            </button>
          </div>
        )}
        {(isLoading || saving) && (
          <div className={classes.splash}>
            <Spinner />
          </div>
        )}
        <div className={classes.wrapper}>
          <h1 className={classes.title}>Карточка команды</h1>
          <h2 className={classes.title__team}>Название команды</h2>
          <input
            placeholder='Введите название'
            className={classes.input}
            data-name='name'
            onChange={handleChangeForm}
            value={state.name}
            disabled={command.isCommon}
          />
          {/* <ModalCommandCardItem title='Категория'>
            <>
              {isNew && (
                <div className={clsx(classes.input, classes.category__box, openVariant && classes.active__box)}>
                  {selectedCategory.map((item) => (
                    <CategoryItem
                      key={item.id}
                      title={item.name}
                      system={false}
                      removeCategoryItem={removeCategoryItem}
                      id={item.id}
                      removeId={selectedCategory[lastElementNumber]?.id}
                    />
                  ))}
                  <input
                    className={classes.category__input}
                    placeholder={!selectedCategory.length ? 'Добавьте одну или несколько подходящих категорий' : null}
                    onChange={(e) => {
                      setValueCategory(e.target.value)
                    }}
                    onClick={() => setOpenVariant(true)}
                    value={valueCategory}
                    onKeyDown={handleBackspacePress}
                    ref={inputRef}
                  />
                </div>
              )}

              <div>
                {!valueCategory.length && !selectedCategory.length && openVariant && (
                  <div className={classes.category__info}>
                    <p className={classes.category__info__title}>варианты</p>
                    <div className={classes.category__info__box}>
                      {categoryName.map((item, id) => (
                        <span key={id} className={classes.span}>
                          <CategoryItem
                            title={item.name}
                            addCategoryItem={addCategoryItem}
                            system
                            removeId={selectedCategory[lastElementNumber]?.id}
                            id={item.name}
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {valueCategory && filteredCategory.length >= 1 && (
                  <div className={classes.category__info}>
                    {filteredCategory.map((item, id) => (
                      <p key={id} onClick={() => addCategoryItem(item.name)} className={classes.category__info__text}>
                        {item.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </>
          </ModalCommandCardItem>
          <ModalCommandCardItem title='Загруженное'>
            <span>
              Загружено: <CategoryItem title='0 файлов' system />
            </span>
          </ModalCommandCardItem> */}
          <ModalCommandCardItem title='Текст команды' opened>
            <textarea
              onChange={handleChangeForm}
              data-name='templateText'
              className={clsx(classes.input, classes.textarea)}
              value={state.templateText}
              disabled={command.isCommon}
            />
          </ModalCommandCardItem>
          <h2 className={classes.title__team}>Описание команды</h2>
          <textarea
            onChange={handleChangeForm}
            data-name='result'
            className={clsx(classes.input, classes.textarea)}
            value={state.result}
            disabled={command.isCommon}
          />

          <Favorites favorite={state.favourite} onChangeFavorite={handleChangeFavourite} />
          <div className={classes.button__box}>
            {isNew ? (
              <Button
                type='yellow'
                isLoading={saving}
                disabled={!filledFields}
                onClick={handleSaveNewCommand}
                fullWidth={false}
              >
                Создать
              </Button>
            ) : (
              <>
                <Button
                  type='yellow'
                  isLoading={isLoading}
                  onClick={handleSaveEditingCommandInfo}
                  disabled={!filledFields || !edited || command.isCommon}
                  fullWidth={false}
                >
                  Редактировать
                </Button>
                {!command.isCommon && (
                  <button
                    className={clsx(classes.btn, classes.btn__gray, command.isCommon && classes.disabled)}
                    disabled={command.isCommon}
                    onClick={handleDeleteCommand}
                  >
                    Удалить шаблон
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}
