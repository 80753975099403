import React, { useState } from 'react'
import styles from './styles.module.scss'
import ALfaAvatar from '../AlfaAvatar/AlfaAvatar'
import { ReactComponent as LinkIcon } from '../../../../assets/icons/linkIcon.svg'
import { ReactComponent as LinkIconBlue } from '../../../../assets/icons/copy-icon-blue.svg'

// Плашка со ссылкой для скачивания ассистента
const DownloadHeaderLink = () => {
  // состояние для определения того, скопирована ссылка или нет
  const [isCopied, setIsCopied] = useState(false)

  // состояние для определения того, наведена мышка на кнопку "Скопировать" или нет
  const [isHovered, setIsHovered] = useState(false)

  // хендлер наведения мыши на кнопку "Скопировать"
  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  // хендлер ухода мыши с кнопки "Скопировать"
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  // хендлер копирвоания ссылки
  const copyLinkHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    navigator.clipboard.writeText('https://clck.ru/35Bb9W')
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return (
    <div className={styles.downloadHeaderLink}>
      <ALfaAvatar />
      <p>
        <span className={styles.downloadHeaderLink__title_type_bold}>ALF Al</span>
        <span className={styles.downloadHeaderLink__title}>, Ваш персональный ассистент</span>
      </p>
      <button
        className={styles.downloadHeaderLink__copyLinkButton}
        onClick={copyLinkHandler}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered ? <LinkIconBlue /> : <LinkIcon />}
        {isCopied ? 'Скопировано' : 'Копировать'}
      </button>
      <a href='https://clck.ru/35Bb9W' target='blank'>
        <button className={styles.downloadHeaderLink__redirectButton}>Установить сейчас</button>
      </a>
    </div>
  )
}

export default DownloadHeaderLink
