import { Checkbox } from './Checkbox/Checkbox'
import React, { useState } from 'react'
import { PrimaryButton } from './PrimaryButton/PrimaryButton'
import styles from './style.module.scss'
import { Switch } from './Switch/Switch'

export const UIKit = () => {
  const [checkboxActive, setCheckboxActive] = useState(false)
  const buttonDisabled = checkboxActive
  return (
    <div className={styles.container}>
      <div className={styles.element}>
        <h3 className={styles.element__title}>Primary Button</h3>
        <PrimaryButton title='Сохранить' onClick={() => null} disabled={buttonDisabled} />
      </div>
      <div className={styles.element}>
        <h3 className={styles.element__title}>Checkbox</h3>
        <Checkbox onClick={() => setCheckboxActive((prev) => !prev)} checked={checkboxActive} />
      </div>
      <div className={styles.element}>
        <h3 className={styles.element__title}>Switch</h3>
        <Switch onChange={() => setCheckboxActive((prev) => !prev)} checked={checkboxActive} />
      </div>
    </div>
  )
}
