import translationErrorMergeEN from './Error/en.json'
import translationErrorMergeRU from './Error/ru.json'
import translationSuccessMergeEN from './Success/en.json'
import translationSuccessMergeRU from './Success/ru.json'
import translationValidateErrorMergeEN from './ValidateError/en.json'
import translationValidateErrorMergeRU from './ValidateError/ru.json'

export const translationMessageMergeEN = {
  ...translationErrorMergeEN,
  ...translationSuccessMergeEN,
  ...translationValidateErrorMergeEN,
}
export const translationMessageMergeRU = {
  ...translationErrorMergeRU,
  ...translationSuccessMergeRU,
  ...translationValidateErrorMergeRU,
}
