import React from 'react'
import { AlfAssistantIcon } from './components/images/AlfAssistantIcon'
import styles from './styles.module.scss'
import { AlfAssistantModal } from 'components/AlfAssistantModal/AlfAssistantModal'
import {
  setIsOpenAlfChat,
  setIsOpenBotListModal,
  setIsOpenCreateAssistant,
  setIsOpenSourceModal,
  setIsOpenSourcesTable,
  setTextSourceChecked,
  setLinkSourceChecked,
  setIsOpenAssistantSettings,
} from 'app/redux/alfAssistantSlice'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
export const AlfAssistant = () => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    // dispatch(getChats())
  }, [dispatch])
  const { isOpenAlfChat } = useAppSelector((state) => state.alfAssistant)
  const isOpenCreateAssistant = useAppSelector((state) => state.alfAssistant.isOpenCreateAssistant)
  const isOpenAssistantSettings = useAppSelector((state) => state.alfAssistant.isOpenAssistantSettings)
  const handleAlfIconClick = () => {
    dispatch(setIsOpenAlfChat(true))
  }
  const handleClose = () => {
    if (isOpenCreateAssistant) {
      return dispatch(setIsOpenCreateAssistant(false))
    }
    if (isOpenAssistantSettings) {
      return dispatch(setIsOpenAssistantSettings(false))
    }
    dispatch(setIsOpenAlfChat(false))
    dispatch(setIsOpenBotListModal(false))
    dispatch(setIsOpenSourceModal(false))
    dispatch(setIsOpenSourcesTable(false))
    dispatch(setTextSourceChecked(false))
    dispatch(setLinkSourceChecked(false))
  }

  return (
    <>
      <button className={styles.button} onClick={handleAlfIconClick}>
        <AlfAssistantIcon />
      </button>
      <AlfAssistantModal handleClose={handleClose} isModalOpen={isOpenAlfChat} />
    </>
  )
}
