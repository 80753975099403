import React, { useState } from 'react'
import styles from './styles.module.scss'
import { Dialog } from '../../types/chatTypes'
import { ReactComponent as UserAvatar } from '../../../../assets/icons/avatarIcon.svg'
import { ReactComponent as CopyIcon } from '../../../../assets/icons/CopyIcon.svg'
import { ReactComponent as CopiedIcon } from '../../../../assets/icons/done-blue.svg'
import ALfaAvatar from '../AlfaAvatar/AlfaAvatar'

interface DialogItemProps {
  dialog: Dialog
  index?: number
  activeIndex?: number
  setActiveIndex?: (index: number | null) => void
}

const DialogItem = ({ dialog, index, activeIndex, setActiveIndex }: DialogItemProps) => {
  // состояние для определения того, отображается иконка копирования или нет
  const showIcon = index === activeIndex
  console.log(index)
  console.log(activeIndex)
  // состояние для определения того, скопирована ссылка или нет
  const [isCopied, setIsCopied] = useState(false)

  // хендлер копирования выбранного сообщения
  const handleCopy = (e: { preventDefault: () => void }, message: string) => {
    e.preventDefault()
    navigator.clipboard.writeText(message)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }
  // функция изменения класса (цвета) элемента диалога
  const ChangeDialogItemClass = (dialog) => {
    return dialog.role === 'assistant' ? styles.chatHistoryPage__dialogItem : styles.chatHistoryPage__dialogItemUser
  }

  return (
    <li
      className={ChangeDialogItemClass(dialog)}
      key={index}
      onMouseEnter={() => setActiveIndex(index)} // при наведении на элемент списка меняется стейт активного индекса => (showIcon)
      onMouseLeave={() => setActiveIndex(null)}
    >
      <div className={styles.chatHistoryPage__dialogItemContent}>
        {dialog.role === 'assistant' ? <ALfaAvatar /> : <UserAvatar />}
        <p className={styles.chatHistoryPage__dialogItemText}>{dialog.content}</p>
      </div>
      {showIcon ? (
        <button
          className={styles.chatHistoryPage__dialogItemCopyBtn}
          title='копировать'
          onClick={(e) => handleCopy(e, dialog.content)}
        >
          {isCopied ? <CopiedIcon /> : <CopyIcon />}
        </button>
      ) : (
        <button
          className={styles.chatHistoryPage__dialogItemCopyBtn_type_unvisible}
          onClick={(e) => handleCopy(e, dialog.content)}
        />
      )}
    </li>
  )
}

export default DialogItem
