import React from 'react'
import styles from './style.module.scss'

interface ICheckboxProps {
  checked?: boolean
  disabled?: boolean
  onClick?: (any) => void
  minus?: boolean
}

const CheckboxInner: React.FC<ICheckboxProps> = ({ checked, disabled, onClick, minus }) => {
  return (
    <label>
      <input
        type='checkbox'
        className={styles.invisibleCheckbox}
        checked={checked}
        disabled={disabled}
        onChange={onClick}
      />
      <span className={styles.visibleCheckbox}>
        {minus ? (
          <span className={styles.visibleCheckbox__minus} />
        ) : (
          <div className={styles.visibleCheckbox__switch} />
        )}
      </span>
    </label>
  )
}

export const Checkbox = React.memo(CheckboxInner)
