import React, { useEffect, useRef } from 'react'
import { useEvent, useList, useStore } from 'effector-react'
import { useAppSelector } from 'app/redux/hooks'

import classes from './CommandCatalog.module.scss'
import { CommandSearch } from './components/CommandSearch'

import './model/init'
import { $commandsLoading, $findedCommands, $findedCommandsCount, tryLoadNextPage } from './model'
import { AICommandItem } from './components/CommandItem'
import { useElementInView } from 'lib/hooks/useElementInView'
import { SelectedItemProps } from './components/CommandItem/CommandItem'
import { ReactComponent as WhitePlusIcon } from '../../../../../src/assets/icons/white-plus.svg'
import { ModalCommandCard } from '../ModalCommandCard/ModalCommandCard'
import { Spinner } from 'components/Spinner'

interface CommandCatalogProps {
  additionCommands?: React.ReactNode
  onEyeClick?: (id: number) => void
  onItemSelect?: (item: SelectedItemProps) => void
  onNewCommand?: () => void
  isMiniTitleVisible?: boolean
  haveBtnCreateCommand?: boolean
}
interface CommandsListProps {
  onEyeClick?: (id: number) => void
  onItemSelect?: (item: SelectedItemProps) => void
}

const CommandsList = ({ onEyeClick, onItemSelect }: CommandsListProps) =>
  useList($findedCommands, {
    fn: (item) => (
      <AICommandItem
        id={item.id}
        title={item.name}
        templateText={item.templateText}
        favourite={item.favourite}
        onEyeClick={onEyeClick}
        onItemSelect={onItemSelect}
      />
    ),
  })

export const CommandCatalog: React.FC<CommandCatalogProps> = ({
  onEyeClick,
  onItemSelect,
  onNewCommand,
  additionCommands,
  isMiniTitleVisible = true,
  haveBtnCreateCommand = false,
}) => {
  const commandListInfo = useStore($findedCommands)
  const commandsLoading = useStore($commandsLoading)
  const { isVisible, containerRef } = useElementInView()
  const ref = useRef<HTMLDivElement>()
  const findedCommandsCount = useStore($findedCommandsCount)
  const handleTryLoadNextPage = useEvent(tryLoadNextPage)

  //   const commandsList = useList({

  //   })

  useEffect(() => {
    if (isVisible /* && findedCommandsCount > 4 */) {
      handleTryLoadNextPage()
    }
  }, [isVisible, handleTryLoadNextPage])

  return (
    <div className={classes.ai_command_catalog} ref={ref}>
      {isMiniTitleVisible && <div className={classes.ai_command_title}>Каталог команд</div>}

      <CommandSearch />
      {additionCommands}
      <div className={classes.ai_command_container}>
        {commandListInfo.length ? (
          <CommandsList onEyeClick={onEyeClick} onItemSelect={onItemSelect} />
        ) : (
          !commandsLoading && <p className={classes.ai_command_text}>Список команд пуст</p>
        )}
        <div style={{ height: '0px' }} ref={containerRef} />
      </div>

      {commandsLoading && (
        <div className={classes.ai_command_load}>
          <Spinner middleSmall />
        </div>
      )}
      {haveBtnCreateCommand && (
        <>
          <hr className={classes.ai_command_hr} />
          <button className={classes.ai_command_button} onClick={onNewCommand}>
            <WhitePlusIcon />
            <p className={classes.ai_command_button_text}>Создать команду</p>
          </button>
        </>
      )}
    </div>
  )
}
