import React, { useState } from 'react'
import style from './style.module.scss'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { ProfilePopover } from './components/ProfilePopover/index'
import { setIsPaymentModal } from 'pages/disk/redux/allProjects'
import avatarIcon from '../../assets/icons/avatarIcon.svg'
import { useTranslation } from 'react-i18next'
import useWindowSize from 'lib/hooks/useWindowSizeHook'

export const Profile = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userData = useAppSelector((state) => state.user.userData)
  const { width } = useWindowSize()

  const [isProfilePopoverOpen, setIsProfilePopoverOpen] = useState(false)
  const currentRate = useAppSelector((state) => state.payment.currentTariff)

  return (
    <>
      <div className={style.avatar}>
        {width > 630 ? (
          <div
            className={style.subsDetailsContainer}
            onClick={() => {
              dispatch(setIsPaymentModal(true))
              // dispatch(addNumberTable(1))
            }}
          >
            {/* currentRate.isStudent && <p className={style.subsDetailsName}>{t('subscription_tariff_student')}</p> */}
            {/* currentRate.isTrial && <p className={style.subsDetailsName}>{t('subscription_tariff_trial')}</p> */}
            {/* currentRate.recurrent && <p className={style.subsDetailsName}>{t('subscription_tariff_recurrent')}</p> */}
            {/* <p className={style.subsDetailsDuration}>
              {t('Profile_subsDetailsDuration')} {getDateToLocale(userData.subscriptionEnd * 1000)}
            </p> */}
          </div>
        ) : null}
        <div className={`${style.circle} ${isProfilePopoverOpen && style.circle_active}`}>
          <button className={style.userbtn} onClick={() => setIsProfilePopoverOpen(true)}>
            <img src={userData.avatar || avatarIcon} />
          </button>
          {isProfilePopoverOpen && (
            <ProfilePopover
              isProfilePopoverOpen={isProfilePopoverOpen}
              setIsProfilePopoverOpen={setIsProfilePopoverOpen}
              name={`${userData.firstName} ${userData.lastName}`}
              email={userData.email}
            />
          )}
        </div>
      </div>
    </>
  )
}
