import React from 'react'
import styles from './styles.module.scss'
import { WhiteDotIcon } from './components/images/WhiteDotIcon'
import { AlfAssistantMenuItem } from './components/AlfAssistantMenuItem/AlfAssistantMenuItem'
import { AlfAssistantLoadedItemsModal } from 'components/AlfAssistantLoadedItemsModal/AlfAssistantLoadedItemsModal'
import { CommandCatalog } from 'components/Editor/ui/CommandCatalog'
import { applyCommand } from 'components/Editor/models/requests'
import { useEvent } from 'effector-react'
import { SelectedItemProps } from 'components/Editor/ui/CommandCatalog/components/CommandItem/CommandItem'
import { useAppDispatch } from 'app/redux/hooks'
import { createNewCommand, loadCommandInfo } from 'components/Editor/redux/commandEditorSlice'
import { setIsEditedCommand, setIsOpenAssistantChat } from 'app/redux/assistantSlice'
import { AIMenuItem } from 'components/Editor/ui/AIGenPanel/AIMenuItem'
import clsx from 'clsx'

interface AlfAssistantCommandMenuProps {
  handleApplyCommand: (text: string) => void
  handleCreateCommandFromText: () => void
  inputValue: string
  isVisible: boolean
}

export const AlfAssistantCommandMenu = React.forwardRef(
  (props: AlfAssistantCommandMenuProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const { handleApplyCommand, handleCreateCommandFromText, inputValue, isVisible } = props
    const dispatch = useAppDispatch()
    const commandRef = React.useRef(null)

    const handleSelectCommand = React.useCallback(
      (item: SelectedItemProps) => {
        handleApplyCommand(item.templateText)
      },
      [handleApplyCommand],
    )
    const handleOpenCommand = React.useCallback(
      (id: number) => {
        dispatch(loadCommandInfo(id)).then(() => {
          dispatch(setIsEditedCommand(true))
        })
      },
      [dispatch, loadCommandInfo],
    )

    return (
      <>
        <div
          className={clsx(styles.menu, {
            [styles.menu__active]: isVisible,
          })}
        >
          <AIMenuItem
            icon={<WhiteDotIcon />}
            title='Создать шаблон на основе команды'
            onClick={handleCreateCommandFromText}
            blocked={inputValue.length === 0}
          />
          {/* <AlfAssistantMenuItem icon={<WhiteDotIcon />} onClick={openPopupHandler} title='Загруженное' count='0' /> */}
          <div className={styles.catalog}>
            <CommandCatalog onItemSelect={handleSelectCommand} onEyeClick={handleOpenCommand} />
          </div>
        </div>
        {/* <AlfAssistantLoadedItemsModal isModalOpen={isModalOpen} handleClose={handleClose} /> */}
      </>
    )
  },
)
