import clsx from 'clsx'
import React from 'react'
import { useAppSelector } from 'app/redux/hooks'
import classes from './AIGenPanel.module.scss'

interface AIMenuItemProps {
  icon: JSX.Element
  title: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
  blocked?: boolean
}
export const AIMenuItem: React.FC<AIMenuItemProps> = ({ icon, title, onClick, disabled = false, blocked = false }) => {
  const { isOpenPopupWithResultOptions } = useAppSelector((state) => state.document)

  return (
    <button
      className={isOpenPopupWithResultOptions ? classes.ai_menu_itemForPopup : classes.ai_menu_item}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <span
        className={clsx(
          isOpenPopupWithResultOptions ? classes.ai_menu_item_titleForPopup : classes.ai_menu_item_title,
          blocked && classes.ai_menu_item_title_blocked,
        )}
      >
        {title}
      </span>
    </button>
  )
}
