import React, { useEffect, useRef } from 'react'
import styles from './style.module.scss'
import gsap from 'gsap'

export const Aura = () => {
  const follower = useRef(null)
  const follower1 = useRef(null)
  const follower2 = useRef(null)

  const mouseMove = (e) => {
    const x = e.pageX
    const y = e.pageY
    gsap.to(follower?.current, 0.5, {
      repeat: 0,
      css: {
        left: x + follower?.current?.getBoundingClientRect()?.width / 5,
        top: y - follower?.current?.getBoundingClientRect()?.width / 1.5,
        visibility: 'visible',
      },
    })
    gsap.to(follower1?.current, 0.65, {
      repeat: 0,
      css: {
        left: x + follower?.current?.getBoundingClientRect()?.width / 5,
        top: y - follower?.current?.getBoundingClientRect()?.width / 1.5,
        visibility: 'visible',
      },
    })
    gsap.to(follower2?.current, 0.75, {
      repeat: 0,
      css: {
        left: x + follower?.current?.getBoundingClientRect()?.width / 5,
        top: y - follower?.current?.getBoundingClientRect()?.width / 1.5,
        visibility: 'visible',
      },
    })
  }
  useEffect(() => {
    window.addEventListener('mousemove', mouseMove)
    return () => window.removeEventListener('mousemove', mouseMove)
  }, [mouseMove])
  return (
    <div>
      <div ref={follower} className={styles.aura} />
      <div ref={follower1} className={styles.aura} />
      <div ref={follower2} className={styles.aura} />
    </div>
  )
}
