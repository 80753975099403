import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const enum AssistantStateType {
  ALF = 'ALF',
  STABLE_DIFFUSION = 'Stable Diffusion',
}

export const enum StableDiffusionRegimes {
  TEXT_BASED_GENERATION = 'Генерация по тексту',
  TEXT_AND_IMAGE_MIX = 'Смешивание картинки и текста',
  IMAGE_VARIATIONS = 'Вариации картинки',
}

interface IAssistantState {
  availableAssistants: [AssistantStateType.ALF, AssistantStateType.STABLE_DIFFUSION]
  isOpenAssistantChat: boolean
  isEditedCommand: boolean
  isSettingMenuOpen: boolean
  type: AssistantStateType
  isOpenHistoryShareModal: boolean
  historyShareModalLinkValue: string
}

const initialState: IAssistantState = {
  availableAssistants: [AssistantStateType.ALF, AssistantStateType.STABLE_DIFFUSION],
  isOpenAssistantChat: false,
  isEditedCommand: false,
  isSettingMenuOpen: false,
  type: AssistantStateType.ALF,
  isOpenHistoryShareModal: false,
  historyShareModalLinkValue: '',
}

export const assistantSlice = createSlice({
  name: 'assistantAI',
  initialState,
  reducers: {
    setIsOpenAssistantChat: (state, action: PayloadAction<boolean>) => {
      state.isOpenAssistantChat = action.payload
    },

    setIsEditedCommand: (state, action: PayloadAction<boolean>) => {
      state.isEditedCommand = action.payload
    },

    setAssistantType: (state, action: PayloadAction<AssistantStateType>) => {
      state.type = action.payload
    },

    setSettingsMenuStatus: (state, action: PayloadAction<boolean>) => {
      state.isSettingMenuOpen = action.payload
    },
    // состояние открытия и закрытия модалки "поделиться перепиской"
    setOpenHistoryShareModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenHistoryShareModal = action.payload
    },
    // значение для копирования ссылки в модалке "поделиться перепиской"
    setHistoryShareModalLinkValue: (state, action: PayloadAction<string>) => {
      state.historyShareModalLinkValue = action.payload
    },
  },
})

export const {
  setIsOpenAssistantChat,
  setIsEditedCommand,
  setAssistantType,
  setSettingsMenuStatus,
  setOpenHistoryShareModal,
  setHistoryShareModalLinkValue,
} = assistantSlice.actions

export const assistantAI = assistantSlice.reducer
