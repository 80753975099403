import React from 'react'
import styles from './styles.module.scss'

interface IAlfAssistantInputSectionProps {
  title: string
  placeholder: string
  rows?: number
  onChange?: (e) => void
  value?: string
}

export const AlfAssistantInputSection: React.FC<IAlfAssistantInputSectionProps> = ({
  title,
  placeholder,
  rows,
  onChange,
  value,
}) => {
  return (
    <label className={styles.label}>
      <h1 className={styles.title}>{title}</h1>
      <textarea className={styles.input} placeholder={placeholder} rows={rows} onChange={onChange} value={value} />
    </label>
  )
}
