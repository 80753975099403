import React, { useEffect, useRef } from 'react'
import Styles from './style.module.scss'
import { setIsUserProfile, setIsOpenProjectModal } from 'pages/disk/redux/allProjects'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setRole } from 'app/redux/userSlice'
import { useTranslation } from 'react-i18next'

import avatarIcon from '../../../../assets/icons/avatarIcon.svg'
import starIcon from '../../../../assets/icons/Star1.svg'
import letterIcon from '../../../../assets/icons/letter.svg'
import cloudIcon from '../../../../assets/icons/cloud.svg'
import plusIcon from '../../../../assets/icons/Plus1.svg'
import usersIcon from '../../../../assets/icons/Users.svg'
import questionIcon from '../../../../assets/icons/Question.svg'
import dialogIcon from '../../../../assets/icons/Dialog.svg'
import exitIcon from '../../../../assets/icons/Exit.svg'
import { ReturnButtonIcon } from 'components/ALFAssistantMobile/components/ALFAssistantMobileChat/components/ALFAssistantMobileChatHeader/components/ReturnButtonIcon'

export const ProfilePopover = ({ isProfilePopoverOpen, setIsProfilePopoverOpen, name, email }) => {
  const { t } = useTranslation()
  const ref: any = useRef()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const logout = () => {
    localStorage.removeItem('JWT')
    history.push('/login')
    dispatch(setRole('guest'))
  }

  const scaleCalc = (val, max) => ((100 * val) / max).toFixed(2) + '%'

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isProfilePopoverOpen && ref.current && !ref.current.contains(e.target)) {
        setIsProfilePopoverOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isProfilePopoverOpen])

  const userData = useAppSelector((state) => state.user.userData)

  return (
    <div className={Styles.container} ref={ref}>
      <div className={`${Styles.item} ${Styles.itemRow}`}>
        <img src={userData.avatar || avatarIcon} className={Styles.itemRowIcon} />
        <div className={Styles.itemRowContent}>
          <p className={Styles.itemTitle}>{name}</p>
          <p className={Styles.itemSubtitle}>Пользователь</p>
        </div>
        <button
          onClick={() => {
            dispatch(setIsUserProfile(true))
            setIsProfilePopoverOpen(false)
          }}
          className={Styles.itemButton}
        >
          Профиль
        </button>
      </div>
      {/* <div className={`${Styles.item} ${Styles.itemRow}`}>
        <img src={starIcon} className={`${Styles.itemRowIcon}`} />
        <div className={`${Styles.itemRowContent}`}>
          <p className={`${Styles.itemTitle}`}>Стандарт</p>
          <p className={`${Styles.itemSubtitle}`}>До 19.01.203</p>
        </div>
        <button className={`${Styles.itemButton}`}>Продлить</button>
      </div> */}
      {/* <div className={`${Styles.itemColWrapper}`}>
        <div className={`${Styles.item} ${Styles.itemCol}`}>
          <img className={`${Styles.itemColIcon}`} src={letterIcon} />
          <p className={`${Styles.itemTitleThin}`}>Символы</p>
          <div className={`${Styles.itemScale}`}>
            <div style={{ width: `${scaleCalc(201789, 300000)}` }} className={`${Styles.itemScaleFill}`}>
              {}
            </div>
          </div>
          <p className={`${Styles.itemSubtitle}`}>
            Использовано:
            <br />
            201 189 из 300 000
          </p>
        </div>
        <div className={`${Styles.item} ${Styles.itemCol}`}>
          <img className={`${Styles.itemColIcon}`} src={cloudIcon} />
          <p className={`${Styles.itemTitleThin}`}>Хранилище</p>
          <div className={`${Styles.itemScale}`}>
            <div style={{ width: `${scaleCalc(1.2, 5)}` }} className={`${Styles.itemScaleFill}`}>
              {}
            </div>
          </div>
          <p className={`${Styles.itemSubtitle}`}>
            Использовано:
            <br />
            1,2 ГБ из 5 ГБ
          </p>
        </div>
      </div> */}
      <div className={`${Styles.item} ${Styles.itemRow}`}>
        <div className={Styles.itemRowContent}>
          <p className={Styles.itemTitle}>Ваша компания</p>
          <p className={Styles.itemSubtitle}>auditor.v2.dev.iamrobot.xyz/hjl7kjps88</p>
        </div>
        <button
          className={Styles.itemButton}
          onClick={() => {
            dispatch(setIsOpenProjectModal(true))
            setIsProfilePopoverOpen(false)
          }}
        >
          Порталы
        </button>
      </div>
      <div className={`${Styles.item} ${Styles.itemRow}`}>
        <div className={Styles.itemRowContent}>
          <p className={Styles.itemTitle}>Замечания и предложения</p>
        </div>
        <a className={Styles.itemButton} href='https://alf.canny.io/feature-requests' target='_blank' rel='noreferrer'>
          <span className={Styles.itemButton_span}>Перейти</span>
          <ReturnButtonIcon className={Styles.right} />
        </a>
      </div>
      {/* <div className={`${Styles.item} ${Styles.itemRow}`}>
        <img src={usersIcon} className={`${Styles.itemRowIcon}`} />
        <div className={`${Styles.itemRowContent}`}>
          <p className={`${Styles.itemTitle} ${Styles.itemTitleDouble}`}>
            Пригласить
            <br />
            сотрудников
          </p>
        </div>
        <button className={`${Styles.itemButton}`}>
          <img src={plusIcon} className={`${Styles.buttonIcon}`} />
          Пригласить
        </button>
      </div> */}
      {/* <div className={`${Styles.item} ${Styles.itemRow}`}>
        <img src={questionIcon} className={`${Styles.itemRowIcon}`} />
        <div className={`${Styles.itemNotification}`}>
          <p className={`${Styles.itemNotificationCount}`}>12</p>
        </div>
        <div className={`${Styles.itemRowContent}`}>
          <p className={`${Styles.itemTitle}`}>Помощь</p>
          <p className={`${Styles.itemSubtitle}`}>Обновления: 19.04.2024</p>
        </div>
        <button className={`${Styles.itemButton}`}>Смотреть</button>
      </div> */}
      <div className={Styles.itemColWrapper}>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://alf.bitrix24site.ru/lichnayavizitka/'
          className={`${Styles.item} ${Styles.itemCol}`}
        >
          <img src={dialogIcon} className={Styles.itemRowIcon} />
          <div className={Styles.itemRowContent}>
            <p className={Styles.itemTitle}>Поддержка</p>
          </div>
        </a>
        <button className={`${Styles.item} ${Styles.itemCol}`} onClick={logout}>
          <img src={exitIcon} className={Styles.itemRowIcon} />
          <div className={Styles.itemRowContent}>
            <p className={Styles.itemTitle}>Выйти</p>
          </div>
        </button>
      </div>
    </div>
  )
}
