import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { useHistory } from 'react-router'
import ReactTypingEffect from 'react-typing-effect'

export const StartScreen = () => {
  const history = useHistory()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleClick = () => {
    history.push('/login')
  }

  const typingWords = ['предприятием', 'отелем', 'рестораном']

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <section className={styles.start}>
      <div className={styles.container}>
        <p className={styles.start__greet}>ВСТРЕЧАЙТЕ BRAINCLOUD</p>
        <h1 className={styles.start__header}>
          Управляйте вашим
          {windowWidth < 800 ? <br /> : ' '}
          <ReactTypingEffect
            text={typingWords}
            cursorClassName={styles.line}
            speed={100}
            typingDelay={1000}
            eraseSpeed={100}
            eraseDelay={1000}
          />
          <br />с помощью искусственного интеллекта{' '}
          <span className={styles.start__selectedText}>В 10 РАЗ БЫСТРЕЕ И ЭФФЕКТИВНЕЕ</span>
        </h1>
        <button className={styles.start__btn} onClick={handleClick}>
          Начать бесплатно
        </button>
      </div>
    </section>
  )
}
