import React from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

export const Logo = () => {
  return (
    <Link to='/'>
      <div className={styles.logo}>BrainCloud Al</div>
    </Link>
  )
}
